<script>
/**
 * Collection conte implementation ( layout's ) for sin2:/v:<view_guid>
 * master-detail supporting
 * collection action`s & editing
 */
import Vue from 'vue';

import JetCollection from '@/components/JetCollection';
import JetEditConte from '@/components/JetEditConte';
import JetSvg from '@/components/JetSvg';

const COL_STATES = {
  none: 0,
  active: 1,
  inactive: 2
};

const COL_MODES = {
  def: 0,
  add: 1,
  edit: 2
};

const JetConteSearch = {
    name: 'JetConteSearch',
    data(){
        return {
            s: null
        };
    },
    render(h){
        return h('v-text-field', {
            props: {
                    dense: true,
                    'hide-details': true,
                    placeholder: 'поиск',
                    flat: true,
                    clearable: true,
                    'clear-icon': 'fas fa-times',
                    value: this.s
        },  class: {'jet-col-search': true},
            on: {
                input: (val)=>{
                    this.s = val;
                },
                keydown: (e)=>{
                    if (13 === e.keyCode){
                        e.preventDefault();
                        this.$emit('search', this.s);
                    }
                },
                blur: (e)=>{
                    this.$emit('focus', false);
                },
                focus: (e)=>{
                    this.$emit('focus', true);
                },
                'click:clear': ()=>{
                    this.s = null;
                    this.$emit('search', null);
                }
            }
        });
    }
};  //JetConteSearch

const JetConteToolbar = {
  props: {
    title: {
      type: String,
      required: true
    },
    hasToolbar: {
        type: Boolean,
        required: true,
        default: true
    },
    favoritted: [Boolean]
  },
  components: {
    JetSvg,
    JetConteSearch
  },
  data: () => ({
    actions: [],
    user_rights: 0,
    focused: false      //always
  }),
  methods: {
    use_actions(a){
        this.actions = a;
    },
    setup_for_editing(e) {
      if ($utils.isEmpty(e)) {
        this.user_rights = 0;
      } else {
        this.user_rights = 1 + 2 + 4;
      }
    }
  },

  render(h) {
    if (this.hasToolbar) {
    return h('v-toolbar', {
      props: {
          dense: true,
          elevation: 1
      }
    }, [
      h('v-menu', {
          props: {bottom: true, left: true},
          scopedSlots: {
            activator: ({on}) => {
              return h('v-btn', {
                  on: on,
                  class: {'jet-col__actions': true},
                  props: {icon: true, disabled: !(this.actions?.length > 0)}
                },
                [h('jet-svg', {props: {xref: '#ico-kra', width: 18, height: 18}})]
              );
            }
          }
        },
        [
            h('v-list', {props: {dense: true}, class:{"jet-menu-actions":true}}, [
                this.actions?.map(a => {
                    return h('v-list-item', {
                        on: {
                                click: ()=>{
                                  this.$emit('action', a);
                                }
                        }
                }, a.name);
            })
        ])]
      ),
      h('v-divider', {props: {vertical: true, inset: true}}),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('edit', true);
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__add': true},
                props: {icon: true, disabled: !(this.user_rights & 1)}
              },
              [h('jet-svg', {props: {xref: '#ico-plus'}})]
            );
          }
        }
      }, [h('span', 'Добавить новую запись...')]),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('edit', false);
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__edit': true},
                props: {icon: true, disabled: !(this.user_rights & 2)}
              },
              [h('jet-svg', {props: {xref: '#ico-edit'}})]
            );
          }
        }
      }, [h('span', 'Редактировать выбранную запись...')]),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('delete');
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__del': true},
                props: {icon: true, disabled: !(this.user_rights & 4)}
              },
              [h('jet-svg', {props: {xref: '#ico-minus'}})]
            );
          }
        }
      }, [h('span', 'Удалить выбранную запись...')]),
      h('v-divider', {props: {vertical: true, inset: true}}),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('refresh');
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__refresh': true},
                props: {icon: true}
              },
              [h('jet-svg', {props: {xref: '#ico-refresh'}})]
            );
          }
        }
      }, [h('span', 'Обновить данные')]),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('exportdata');
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__export': true},
                props: {icon: true}
              },
              [h('jet-svg', {props: {xref: '#ico-file-excel'}})]
            );
          }
        }
      }, [h('span', 'Экспорт данных')]),
      h('v-divider', {props: {vertical: true, inset: true}}),
      h('v-tooltip', {
        props: {bottom: true},
        scopedSlots: {
          activator: ({on}) => {
            on['click'] = () => {
              this.$emit('audit');
            };
            return h('v-btn', {
                on: on,
                class: {'jet-col__audit': true},
                props: {icon: true}
              },
              [h('jet-svg', {props: {xref: '#ico-clipboard-check'}})]
            );
          }
        }
      }, [h('span', 'Открыть аудит')]),
      h('v-divider', {props: {vertical: true, inset: true}}),
      h('jet-conte-search', {
          ref: 'search',
          on: {
              search: (s)=>{this.$emit('search', s);},
              focus: (focus)=>{this.focused = focus;}
          }
      }),
      h('v-spacer'),
      h('v-toolbar-title', {class: {'jet-col-title': true}}, [
        h('v-tooltip', {
            props: {bottom: true},
            scopedSlots: {
                activator: ({on}) => {
                    on.click = ()=>{
                        this.$emit('favoritte');
                    };
                    return h('v-icon', {
                                class: 'mr-2', 
                                on: on,
                                style: {color: this.favoritted ? 'orange' : 'grey'}
                            }, 
                            this.favoritted ? 'mdi-star' : 'mdi-star-outline');
                }
            }
        }, [h('span', this.favoritted ? 'Убрать из избранного' : 'В избранное')]),
        this.title
      ])
    ]); /*v-toolbar*/
    } 
  }   //render
};      //JetConteToolbar

class ApiCollection {
  constructor(o) {
    this._own = o;
  }

  get jet() {
    return window.jet;
  }

  get id() {
    return this._own.collectionInfo.id;
  }

  get title() {
    return this._own.collectionInfo.name;
  }

  set title(s) {
    this._own.collectionInfo.name = s;
  }

  get master() {
    return this.table();
  }

  table(s) {
    if ( $utils.isEmpty(s)|| ('master'===s) ) {
      return this._own.$refs.master.api;
    }
    for (const ref in this._own.$refs) {
      if (
        (!!ref.collectionInfo)
        && ((s === ref.collectionInfo.id) || (s === ref.collectionInfo.name))
      ) {
        return ref.api;
      }
    }
    return null;
  }

  set comp(c) {
    this._own.comp = c;
  }

  get owner(){
      return this._own;
  }
  
  set(name, val){
      this._own.set(name, val);
  }
  
  refresh() {
    this._own.refresh();
  }
}


export default {
  name: 'JetConte',
  props: {
    /**
     * collection Info {id,name,master*,uri}
     * master set in render-func for M-D supporting
     */
    collectionInfo: {
      type: Object,
      required: true
    },
    hasToolbar: [Boolean]
  },
  components: {
    JetConteToolbar,
    JetCollection,
    JetEditConte
  },
  data: function() {
    return {
      api: null,
      master_id: null,      /* id from master collection (see md_change, render) */
      details: null,        /* from master-meta (see md_setup) */
      detail_active: null,  /* index of active detail (see change_active_detail) */
      focused: null,        /* who is focused master/detail_n */
      editorUri: null,      /* editor view uri for master (TODO: for details) */
      mode: COL_MODES.def,  /* for show-data/editing */
      comp: null,           /* additional UI-component for scripting */
      onlyRead: false,      /* to block save buttons*/
      maxRowCode: 1,        /* max rows code (using in ProductionCalendar) */
      hideToolbar: false    /* show/hide deefault toolbar */
    };
  },
  computed: {
    isActive: {
      get() {
        const ci = this.$store.state.colls.active;
        return ((!!ci) && (this.collectionInfo.id === ci.id)) ? COL_STATES.active : COL_STATES.inactive;
      },
      set(val) {
        // console.log('isActive(set):', val);
      }
    },   //isActive
    favoritted: {
        get(){
            return this.$store.getters["app/favoritted"](this.collectionInfo?.id);
        },
        async set(val){
            let fav = await this.$store.dispatch("app/setFav", this.collectionInfo);
        }
    }
  },
  created() {
    this.isActive = COL_STATES.none;
    this.api = new ApiCollection(this);
    const _closer = (e)=>{
        var code = (e.keyCode ? e.keyCode : e.which);
        if ((this.isActive===COL_STATES.active) && (code === 27)) {
            this.mode = COL_MODES.def;
        }
    };
    document.addEventListener("keyup", _closer);
    console.log('conte', this);
  },
  mounted() {
      this.$nextTick(()=>{
          this.focus("master");
      });
      this.$store.dispatch("app/getFavs", this.collectionInfo.id).then( fav => {
          this.collectionInfo.favoritted = !!fav;
      });
  },
  methods: {
    /**
     * setup detail's collection from master collection (sin2 view model-project)
     * @param {Object} model - sin2-model
     *
     */
    md_setup: function(model) {
      console.log('md_setup', model);
      const tb = this.$refs.toolbar;
      
      if ( !tb ) {
          console.log('No toolbar available');
          return;
      }
      tb.use_actions(model.guiActionHtml);
      tb.setup_for_editing(null);
      
      //child`s (details) setting
      model.get_meta().then( meta => {
        if ( 
                (!!meta) 
             && (!!meta.attributes)
           ) {
          this.details = meta.attributes.links;
          this.editorUri = meta.attributes.editorViewUri; //TODO: for childs need
          tb.setup_for_editing(this.editorUri);
          console.log('md_setup (2)', meta);
        }
      }).catch( e=>{
          console.log('ERR (on model meta)', e);
      });
    },
    /**
     * change master collection id
     * @param {type} id
     * }
     */
    md_change: function(id) {
      this.master_id = id;
    },
    /**
     * change index on detail's tabs and send curr master_id to active detail
     * @param {type} n
     *
     */
    change_active_detail: function(n) {
      this.detail_active = n;
      this.focus('detail_' + n);
    },
    refresh() {
        const coll = this.focused || this.$refs['master'];
        coll.refresh();
    },  //refresh
    do_action: async function(a) {
      console.log('do_action:', a);
      
      if (!this.api) {
        this.api = new ApiCollection(this);
      }

      if (typeof a.call === 'function') {
        return a.call();
      }


      try {
        const s = await $.ajax('/rpc?d=file&uri=' + a.resource.ref, {
          dataType: 'text',
          cache: false
        });
        
        let func = '';
        try {
          console.log("loading script", s);
          const actionParams = JSON.parse(s);

          if(actionParams?.name == 'ToMap') {
            const col = {
              id: "4a409336-70d8-4e77-9ab3-c144c8e1f253",
              name: "Карта",
              uri: "catrem:/?mode=evac&f:vcTypes=e30d37ea-7f3f-4b51-9b4d-840b702a9ec4",              
            };

            jet.collections.open(col);
            return;
          }

          Vue.component(
            // Название компонента, который должен вызываться
            actionParams.name,
            // Путь до компонента в файловой структуре проекта
            () => import('' + actionParams.path)
          );
          func = '(' + actionScript.toString() + ')(collection, \'' + actionParams.name + '\')';
        } catch (e) {
          func = s;
        }
        (new Function('collection', func))(this.api);
        
      } catch (e) {
        console.log(e.message);
        jet.msg({
          text: 'ВНИМАНИЕ! Ошибка при выполнении "' + a.name
            + '"<br /><small>Информация для технической поддержки: '
            + e.message + '</small>',
          color: 'warning'
        });
      }
    },
    /*
     * Set a edit/append mode
     * @param {type} fAdd - attr
     */
    edit: function(fAdd) {
      try {
        if (!fAdd) {
          const markD = this.focused.$refs.table.selected._ssc_crud_detail;
          if (markD === false || !markD) {
            jet.msg({
              text: 'Просмотр запрещен',
              color: 'warning'
            });
            return;
          }

          this.onlyRead = false;
          const markU = this.focused.$refs.table.selected._ssc_crud_update;
          if (markU === false || !markU) {
            this.onlyRead = true;
          }
        } else {
          const mark = this.focused.model._model.tags.securityExpressionCreate;
          if (mark === false) {
            jet.msg({
              text: 'Добавление запрещено',
              color: 'warning'
            });
            return;
          }
        }
      } catch (e) {
        console.log("error on edit", e);
      }
      
      this.mode = (fAdd) ? COL_MODES.add : COL_MODES.edit;
    },
    delete: function() {
        const tb = this.focused;
        try {
            const mark = tb.$refs.table.selected._ssc_crud_delete;
            if (mark === false || !mark) {
                jet.msg({
                  text: 'Удаление запрещено',
                  color: 'warning'
                });
                return;
            }
        } catch (e) {
            console.log("error on delete", e);
        }
        
        if ((!!tb)&&(tb.delete)){
            tb.delete();
        }
    },
    audit: function() {
        const tb = this.focused;
        if ((!!tb)&&(tb.audit)){
            tb.audit();
        }
    },
    exportdata: function() {
        const tb = this.focused;
        if ((!!tb)&&(tb.exportdata)){
            tb.exportdata();
        }
    },
    setMaxRowCode: function(code) {
      this.maxRowCode = code;
    },
    /**
     * Callback for append/edit Collection data (see JetEditConte.save)
     * @param {Object{id:uuid, add:boolean}} q
     * @returns {undefined}
     */
    change: function(q) {
        const tb = this.focused;
        tb.refresh(q.id);
    },
    search(s){
        const tb = this.focused;
        return ( (!!tb)&&(!!tb.search) ) ? tb.search(s) : false;
    },
    set(name, val){
        switch(name){
            case "toolbar":
                this.hideToolbar = !val;
                break;
            default:
                const tb = this.focused;
                return ( tb?.set ) ? tb.set(name, val) : false;
        }
    },
    /*
     * set a focus on comp
     * @param {String} c - name of child-comp
     * @returns {focused comp || null}
     */
    focus(c){
        var col = null;
        Object.keys(this.$refs).map((ref)=>{
            var focusing = (ref === c);
            this.$refs[ref].focused = focusing;
            if (focusing){
                col = this.$refs[ref];
                this.focused = col;
            }
        });
        if (!!col){
            if (!!this.focused && this.hasToolbar){
                this.$refs["toolbar"].$refs["search"].s = col.s;
            }
        }
        return col;
    }   //focus
  },
  render: function(h) {
    const panels = [],
      top_size = (!!this.details) ? 60 : 100;
    /* master */
    
    this.collectionInfo.master = true;
    panels.push(h('split-area', {
      key: 'sa-' + this.collectionInfo.id,
      props: {size: top_size, minSize: 200}
    }, [
      h('jet-conte-toolbar', {
          props: {
            title: this.collectionInfo.name || '...',
            hasToolbar: this.hasToolbar && !this.hideToolbar,
            favoritted: this.favoritted
          },
          ref: 'toolbar',
          on: {
            action: this.do_action,
            refresh: this.refresh,
            edit: this.edit,
            delete: this.delete,
            audit: this.audit,
            exportdata: this.exportdata,
            search: this.search,
            favoritte: e => { this.favoritted = e; }
          }
        }
      ),
      h('jet-collection', {
        props: {
          collectionInfo: this.collectionInfo,
          active: true    //always for master
        },
        ref: 'master',
        on: {
          "on-model": this.md_setup,
          "on-focus": ()=>{ this.focus('master'); },
          "change": this.md_change,
          "edit": this.edit,
          "delete": this.delete,
          "audit": this.audit,
          "exportdata": this.exportdata,
          "action": this.do_action,
          "max-row-code": this.setMaxRowCode
        }
      })
    ]));

    /* details */
    if (!!this.details) {
      const cis = []; //child`s collection-info
      this.details.map((item) => {
        let id;
        const uri = item.value;
        if ((uri) && (0 === uri.indexOf('sin2:/'))) {
          id = uri.substr(8, 36);
          cis.push({id: id, name: item.title, uri: item.value});
        }
      });
      panels.push(h('split-area', {
        key: 'sa-det-' + this.collectionInfo.id,
        props: {size: 100 - top_size, minSize: 200},
        class: {"jet-childs-area": true}
      }, [
        h('v-tabs-items', {
          props: {value: this.detail_active}
        }, [
          cis.map((ci, i) => {
            ci.master = false;
            return h('v-tab-item', {props: {key: 'ti-child-' + ci.id}}, [
              h('jet-collection', {
                props: {
                  collectionInfo: ci,
                  active: (i === this.detail_active),
                  parentId: this.master_id
                },
                ref: 'detail_' + i,
                on: {
                    'on-focus': ()=>{this.focus('detail_' + i);}
                }
              })
            ]);
          })
        ]),
        h('v-tabs', {
          props: {light: true, dense: true, optional: true, height: 'auto'},
          style: {position: 'fixed'},
          on: {change: this.change_active_detail}
        }, [
          cis.map((ci) => {
            return h('v-tab', {
              props: {key: ci.id, title: ci.name},
              domProps: {'data-tab-id': ci.id}
            }, ci.name);
          })
        ])
      ]));
    } else {
      //hide child's pane when no-details
      panels.push(h('split-area', {
          key: 'sa-det-' + this.collectionInfo.id,
          props: {minSize: 0, size: 0},
          style: {display: 'none', height: '0'}
        })
      );
    }

    const fAddEdit = (this.mode === COL_MODES.add) || (this.mode === COL_MODES.edit);
    var focusedColl = this.focused;

    var uri_for_edit = ((!!focusedColl)&&focusedColl.isAllow) ? focusedColl.model.editor : null,
        id_for_edit = null;

    if (this.mode === COL_MODES.edit) {
      id_for_edit = focusedColl.getId();
    }

    return h('v-layout', {
        class: {'jet-conte': true, 'jet-no-details': !this.details}
      },
      [
        (this.comp) ? h(this.comp) : null,
        h('split', {
          props: {'direction': 'vertical', gutterSize: 8},
          class: {
            'fill-height': true,
            'd-none': fAddEdit
          }
        }, panels),
        fAddEdit
          ? h('jet-edit-conte', {
            props: {uri: uri_for_edit /*this.editorUri*/, add: (this.mode === COL_MODES.add), id: id_for_edit, onlyRead: this.onlyRead, maxRowCode: this.maxRowCode, master_id: this.master_id},
            on: {
              'hide': () => {
                this.mode = COL_MODES.def;
                this.$forceUpdate();
              },
              'data-change': this.change
            }
          })
          : null
      ]);
  }
};

function actionScript(collection, componentName) {
  const c = collection;

  const d = {
    data: function() {
      return {
        collection: c,
        componentName: componentName,
      };
    },
    methods: {},
    computed: {},
    template: '<component :is="componentName" :collection="collection" />',
  };

  c.comp = d;
}
</script>

<style lang="scss">
@import '@/styles/tabs';
@import "@/styles/index";

.jet-menu-actions {
    font-size: 0.9rem;
    & .v-list-item {
        min-height: inherit;
        padding: 0.5rem 1rem;
        &:hover{
            background: $yellow-color;
        }
    }
}
.jet-conte {
  height: calc(100vh - 134px);

  &.jet-no-details {
    & .gutter-vertical {
      display: none;
    }
  }

  .split {
    .fill-height {
      overflow: hidden;
    }
  }

  .v-toolbar {
    & .v-btn {
      svg {
        width: 18px;
        height: 18px;
      }
    }

    & .jet-col-search{
        width: auto;
        max-width: 12rem;
        margin-left: 1rem;
        margin-right: 1rem;
        transition: all ease 0.18s;
        font-size: 0.9rem;
        &.v-input--is-focused{
            max-width: 18rem;
        }
        & .v-icon.fas{
            font-size: 12px;
        }
    }
    & .jet-col-title{
        display: flex;
        align-items: center;
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
  & .jet-childs-area{
      & .v-window{
          height: calc(100% - 28px);
          &__container{
            height: 100%;
          }
          &-item--active{
            height: 100%;
            & .jet-collection-conte{
                height: 100%;
            }
          }
      }
      & .v-tabs{
        border-top: 1px solid #ccc;
        & .v-tabs-bar {
            height: auto;
            & .v-tab{
                padding-top: 6px;
                padding-bottom: 6px;
                font-size: 10px;
            }
        }
      }
  }
}
</style>
