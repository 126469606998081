<template>
    <v-row :class="{'trip-item': true, 'justify-space-between': true, autoplaned: autoplaned}">
      <!-- Данные по выезду -->
      <v-col>
        <v-row class="align-center flex-nowrap">
            <v-checkbox
                v-if="structure.checkbox"
                v-model="item.selected"
                :disabled="disabled"
                hide-details
                :color="autoplaned ? 'red-accent-4' : 'primary'"
                @change="changeSelected"
                @click.native="check($event)">
            </v-checkbox>
            <div class="trip-item__meta">
                <b class="trip-item__code">{{ item.tripCode }}</b>
                <div class="grey--text trip-item__times">{{ startTime }} - {{ endTime }}</div>
                <div class="trip-item__stops">{{ stopNames }}</div>
                <div class="trip-item__vehicles">{{ textVehicles }}</div>
            </div>
            <template v-if="item.violations">
                <v-spacer />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"
                            color="orange">
                        mdi-alert-outline
                    </v-icon>
                  </template>
                  {{item.violations.text}}
                </v-tooltip>
            </template>
        </v-row>
      </v-col>
      <v-col v-if="item.planned" cols="auto">
            <v-btn v-if="structure.addVehicle" 
                   outlined small 
                   color="primary" 
                   @click="addVehicle" 
                   :disabled="disabled">
                Добавить ТС
            </v-btn>
      </v-col>
    </v-row>
</template>

<script>
import PlansService from "@/services/PlansService";

export default {
  name: 'TripItem',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullDate: {
      type: Object,
      required: true,
    },
    structure: {
      type: Object,
      default: () => ({
        checkbox: true,
        addVehicle: true,
        delete: true,
        plan: true,
      }),
    },
  },
  data: () => ({
    startTime: '',
    endTime: '',
    vehicles: [],
    stopNames: '',
  }),
  computed: {
        autoplaned(){
            return (this.item.trips?.filter( t => !!t.autoplan )||[]).length;
        },
        textVehicles() {
          const vehicles = this.vehicles;
          let result = '';
          for (let i = 0; i < vehicles.length; i++) {
            result += vehicles[i].govnum;
            if (i > 0 && !!vehicles[i].stop) {
              result += ` (${vehicles[i].stop})`;
            }
            if (i + 1 < vehicles.length) {
              result += ', ';
            }
          }
          return result;
        },
  },
  created() {
        this.startTime = $utils.formatDate(new Date(this.item.startTime), 'HH:mm');
        this.endTime = $utils.formatDate(new Date(this.item.endTime), 'HH:mm');
        if (this.item.planned) {
          this.initVehicles();
        }
        this.getStops();
  },
  methods: {
    check(e) {
      this.$emit('click-checkbox', {
        isShiftPress: e.shiftKey,
        item: this.item,
      });
    },
    addVehicle() {
      this.$emit('add-vehicle', [this.item]);
    },
    changeSelected() {
      const index = this.value.findIndex(item => {
        return item.scheduleId === this.item.scheduleId;
      });
      if (index !== -1) {
        if (!this.item.selected) {
          this.value.splice(index, 1);
        }
      } else {
        if (this.item.selected) {
          this.value.push(this.item);
        }
      }
    },
    initVehicles() {
      for (const trip of this.item.trips) {
        if (trip.govnum) {
          const index = this.vehicles.findIndex(item => {
            return item.govnum === trip.govnum;
          });
          if (index === -1) {
            this.vehicles.push({
              govnum: trip.govnum,
              stop: trip.locationName || '',
            });
          }
        }
      }
    },
    getStops() {
      const dateWork = $utils.formatDate(
        new Date(this.fullDate.iso), 'yyyy-MM-DD 03:00:00',
      );
      PlansService.getStops(this.item.scheduleId, dateWork)
        .then(stopPoints => {
          const firstPointName = stopPoints?.[0]?.name || '';
          const lastPointName = stopPoints[stopPoints.length - 1]?.name || '';
          this.stopNames = firstPointName + ' - ' + lastPointName;
        })
        .catch(err => console.log('TripItem::getStops', err))
      ;
    },
  },
};
</script>
<style lang="scss" scoped>
    .trip-item{
        font-size: 0.9rem;
        margin: 1rem 0;
        padding-left: 1rem;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ccc;
        border-radius: 3px;
        min-height: 48px;
        & .col{
            &:first-child{
                max-width: calc(100% - 10rem);
            }
        }
        &__meta{
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            & > * {
                margin-right: 0.5rem;
            }
        }
        &__stops{
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &__times{
            white-space: nowrap;
        }
        &__vehicles{
            white-space: nowrap;
        }
        &.autoplaned{
            & .trip-item__code{
                color: #d50000;
            }
        }
    }
</style>