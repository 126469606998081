/**
 * Класс Report
 */
class Report {
  /**
   * @constructor
   * @param {Object} report
   */
  constructor(report) {
    this._report = report;
  }
  
  get id(){
      return this._report.id;
  }

  /**
   * описание отчёта
   */
  get description() {
    return this._report.reportDescription;
  }

  /**
   * название отчёта
   */
  get name() {
    return this.description.title;
  }
  
  get mime(){
      return ( this.description?.formats?.length > 0 )
                ? this.description.formats[0].mime
                : 'application/octet-stream';
  }
  
/**
 * Параметры отчета
 * @returns {Array|Report.get params._report.reportDescription.mainQuery.arguments}
 */
  get params(){
        var _params = [],
            mq = null;
        if ((!!this._report) && (!!this._report.reportDescription)){
            mq = this._report.reportDescription.mainQuery;
            if ( mq ){
                _params = mq.arguments;
                //normalize param`s to using
                _params.forEach( p => {
                    p.type = p.metaType;
                    p.label = p.title;
                    if (('id'===p.type) && p.hasOwnProperty('editor')){
                        var n = p.editor.indexOf('uri=');
                        p.uri = unescape(p.editor.substr(n + 4));
                    }
                });
            } else {
                _params = this._report.reportDescription.arguments;
                _params.forEach( p => {
                  if ( !p.type ) {
                    p.type = p.metaType;
                  }
                  var type = p.type.toLowerCase();
                  var dfe  = p.defaultExpression;
                  p.type = p.editor || ((type.lastIndexOf('.') > 0) ? type.substr(type.lastIndexOf('.')+1) : type);
                  p.metaType = p.type;
                  p.uri = (p.attributes) ? p.attributes.uri : null;
                  p.label = p.title;
                });
            }
        }
        return _params;
  }
  async call(params){
        return new Promise((resolve, reject) => {
            let url = '/rpc?d=report',
            _vals = "{";
            params.forEach((p, i)=>{
                let fAdd = true,
                    v = p.value;
                switch (p.type){
                    case "date":
                    case "dateTime":
                        v = 'java.util.Date{time:' + v.getTime() + 'l}';
                        break;/*
                    case "boolean":
                        v = (!!v) ? 'true' : 'false';
                        break;*/
                    case "string":
                    case "id":
                        fAdd = !$utils.isEmpty(v);
                        v = fAdd ? '"' + v + '"' : '';
                        break;
                    case "integer":
                    case "long":
                        v = v + 'l';
                        break;
                }
                if (fAdd){
                    if (i > 0){
                        _vals += ',';
                    }
                    _vals += `"${ p.name }":${ v }`;
                }
            });
            _vals += '}';
            console.log('sending rep values:', this.id, _vals);

            let d = jet.$store.getters['period/current'];
            let ctx = 'param{name:"dateWork",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
            d = jet.$store.getters['period/begin'];
            ctx += 'param{name:"dateBegin",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}},';
            d = jet.$store.getters['period/end'];
            ctx += 'param{name:"dateEnd",type:"date",value:java.util.Date{time:' + d.getTime() + 'l}}';
            
            const formId = 'jet-form-' + this.id,
                  mime = this.mime;
            
            if (/vnd.kih.jdl/i.test(mime)) {
                $.ajax({
                    url: '/rpc?d=report',
                    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                    data: '&type=json&report=' + this.id + '&args=' + '@' + _vals + '&ctx=' + '@[' + ctx + ']',
                    processData: false,
                    type: 'POST'
                }).then((data, status, request)=>{
                    const item = {
                        id: 'REPORT' + this.id,
                        name: this.name,
                        uri: 'TABLEREPORT',
                        report: this,
                        data: JSON.parse(data)
                    };
                    jet.collections.open(item);
                    resolve();
                }).catch( e => {
                    console.log('ERR (tab-report)', e);
                    reject(e);
                });
            } else {
                $.ajax({
                    url: '/rpc?d=report',
                    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                    data: '&report=' + this.id + '&args=' + '@' + _vals + '&ctx=' + '@[' + ctx + ']',
                    processData: false,
                    type: 'POST',
                    xhrFields: {responseType: 'blob'}
                }).then((data, status, request)=>{
                    let ext = request.getResponseHeader('content-disposition') || '.xls';
                    ext = ext.substr(ext.lastIndexOf('.'));
                    let url = window.URL.createObjectURL(new Blob([data],  {type : this.mime}));
                    let ref = document.createElement('a');
                    ref.href = url;
                    ref.download = this.name + ext;
                    document.body.appendChild(ref);
                    ref.click();
                    document.body.removeChild(ref);
                    window.URL.revokeObjectURL(url);
                    resolve();
                }).catch( e => {
                    console.log('ERR(report)', e);
                    reject(e);
                });
            }
      });
  }     //call
}

export default Report;
