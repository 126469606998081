<template>
  <div class="fill-height jet-tabs">
    <v-tabs v-model="selected" light
            reverse-transition="false"
            transition="false"
    >
      <v-tab v-for="tab in tabs" :key="tab.id"
             :data-tab-id="tab.id"
             @change="changeTab(tab)"
            >
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  {{ tab.name }}
                  <a v-if="'tab-dashboard'!==tab.id" href="#" @click.stop.prevent="closeTab">
                      <jet-svg xref="#close" />
                  </a>
                </div>
            </template>    
            <span>{{ tab.name }}</span>
        </v-tooltip>    
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selected" class="jet-collection" no-animation>
      <v-tab-item v-for="tab in tabs" :key="'ti-'+tab.id">
        <jet-conte :collection-info="tab" 
                   :ref="'jet-conte-'+tab.id" 
                   :hasToolbar="!(/^catrem:/).test(tab.uri)" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import JetSvg from '@/components/JetSvg';
import JetConte from '@/components/JetConte';

export default {
  name: 'JetTabBar',
  components: {
    JetConte,
    JetSvg
  },
  data: () => ({
    tabs: [
      {
        id: 'tab-dashboard',
        name: 'Главная',
        uri: 'Dashboard'
      }
    ]
  }),
  computed: {
    selected: {
      get() {
        if (this.active) {
          for (let i = 0; i < this.tabs.length; i++) {
            if (this.active.id === this.tabs[i].id) {
              return i;
            }
          }
        }
        return 0;
      },
      set(val) {
        console.log(val);
      },
    },
    active() {
      return this.$store.state.colls.active;
    }
  },
  methods: {
    closeTab(e) {
      let id = null;
      let tab = e.target;

      while (tab) {
        let a = $(tab).attr('data-tab-id');
        if (!!a) {
          id = a;
          break;
        }
        tab = tab.parentNode;
      }
      if (!!id) {
        for (let i = 0; i < this.tabs.length; i++) {
          if (id === this.tabs[i].id) {
            this.tabs.splice(i, 1);
            let tab = this.tabs[i - 1];
            this.changeTab(tab);
            break;
          }
        }
      }
    },
    changeTab(tab) {
      this.$store.commit('colls/active', tab);
    },
    getActiveCollection() {
      const colId = (this.active) ? this.active.id : null;
      if (!!colId) {
        const conte = this.$refs['jet-conte-' + colId];
        return ((conte) && (conte.length > 0)) ? conte[0].api : null;
      }
      return null;
    },
  },
  watch: {
    active(ci) {
      if (!ci) {
        return false;
      }
      let found = false;
      for (let i = 0; i < this.tabs.length; i++) {
        if (ci.id === this.tabs[i].id) {
          found = true;
          this.selected = i;
          break;
        }
      }
      if (!found) {
        this.selected = this.tabs.push(ci);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/tabs';

.jet-collection {
  height: calc(100% - 48px);

  & .v-window {
    &__container {
      height: 100% !important;
    }
  }
}
</style>
