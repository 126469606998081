/**
 * Serviced functions for Dashboard & etc
 */

import store from '@/store/index';

/**
 * Basic db-info 
 * @param {Number} attr : 
 *          1 - all users activities, 
 *          2 - last users activities?
 *          3 - num of routes
 *          4 - last routes info
 *          5 - vehicles info
 *          6 - plaining info
 *          7 - control
 *          8 - num of contract's
 *          9 - latest contract's
 *          see mt_db_info (pg) for details
 * @returns {Promise}
 */
export async function getMtInfo(attr){
    
    const tenantId = store.state.auth.subject?.tenantId;
    
    const opts = {
        type: 'query',
        query: '2f642407-7a1b-4e22-9c78-83abe4eae077.db_info',
        transform: true,
        params: {attr, uid: tenantId}
    };
    
    return $http.post(opts);
};   //getMtInfo


export async function getMtInfoAudi(attr){
    
    const tenantId = store.state.auth.subject?.tenantId;
    
    const opts = {
        type: 'query',
        query: '2f642407-7a1b-4e22-9c78-83abe4eae077.db_info_audit',
        transform: true,
        params: {attr, uid: tenantId}
    };
    
    return $http.post(opts);
};   //getMtInfo

export async function getStopStatistics(locId){
    const tenantId = store.state.auth.subject?.tenantId,
          re_num = /[^0-9]+/g;
  
    const opts = {
        type: 'query',
        transform: true,
        query: 'acb1489f-0dd9-4543-b9c1-104bace70bd0.locationStatisticScript',
        params: {
            locId,
            tenantId
        }
    };
    
    let n, res = [],
          _res = await $http.post(opts);
    
    _res.forEach( r => {
        r.start = $moment(r.planed);
        r.fact  = r.fact ? $moment(r.fact) : null;
        r.num   = Number(r.routecode?.replace(re_num, ''));
        r.id    = r.num + r.tripcode + r.govnum;
        r.govnum= r.govnum?.toUpperCase();
        if (r.fact){
            n = res.findIndex( r2 => {
                    return (r.id === r2.id) && 
                           ( 
                                (!r2.fact)
                             || ((r2.fact) && r.fact.isAfter(r2.fact))
                           );
                });
            if ( n < 0 ){
                res.push(r);
            } else {
                res.splice(n, 1, r);
            }
        } else {
            n = res.findIndex( r2 => (r.id === r2.id) );
            if ( n < 0 ){
                res.push(r);
            }
        }
    });
    
    
    return res.sort( (r1, r2) => {
        return r1.start.isBefore(r2.start) ? -1 : 1;
    } );
};      //getStopStatistics


export async function getCarriersStats(){
    
    const rpc = {
        type:  'core-read',
        query: `sin2:/v:e8fd22eb-f901-42af-874f-2bf5fe6a78f4/?filter=and(
                    eq(field(".isActiveRoute"),param(true,"boolean")),
                    lte(field(".startDt"),var("util.date.truncToDay(dateEnd)")),
                    or(
                       isnull(field(".endDt")),
                       gte(field(".endDt"),var("util.date.truncToDay(dateBegin)"))
                    )
                )&fields=vcServicedRoutes.carrier,vcServicedRoutes.carrier.orgID,count(.id) as numOf`
    };
    
    let n, res, resp = await $http.post(rpc);
    
    const ids = [],
          ci = resp.result.columnIndexes;
  
    console.log('carriers-stats', resp);
    
    res = resp.result.data.map( d => {
        return {
            id: d[ci["vcservicedroutes.carrier.orgid"]],
            carrier: d[ci["vcservicedroutes.carrier"]],
            n:  d[ci["numof"]]
        };
    }).sort( (r1, r2) => {
        return (r1.n < r2.n) ? 1 : (r1.n === r2.n) ? 0 : -1;
    } );
  
    let last = res.at(Math.min(9, res.length - 1));
    
    if ( last ){
        res.forEach( r => {
            if ( r.n >= last.n ){
                ids.push( r.id );
            }
        });
    }

    //2e4490d8-64f9-468d-b74e-ff4350a2b128
    rpc.query = `sin2:/v:ed51e994-9b50-4a27-b6f7-01fc5d44f0c0/?filter=sql("${ ids.map(i => `'${ i }'`).join(',') }")&fields=.ShortName`;
    rpc.transform = true;
    
    const orgs = await $http.post(rpc);
    
    res.forEach( r => {
        n = orgs.findIndex( o => o.id === r.id );
        r.name = ( n > -1 ) ? r.name = orgs[n].ShortName : null;
    });
    
    return res;

}   //getCarriersStats

export async function getMobiStats(){
    const all = [
        $http.get("/api/stats?q=views"),
        $http.get("/api/stats?q=titles")
    ];
    
    const res = {},
          _res = await Promise.all(all);
  
    if ( _res[0].success ){
        res.visits = _res[0].data.totals[0];
        res.views  = _res[0].data.totals[1];
    }
    
    if ( _res[1].success ){
        console.log('views',_res[1]);
        res.routes = [];
        let s, m, re = /^(\d{1,}\.\s)+/;
        _res[1].data.data.forEach( d => {
            s = d.dimensions[0].name;
            if ( re.test(s) ) {
                m = s.match(re);
                res.routes.push({
                    num: m[0].replace(/\.\s/,''),
                    name: s.replace(m[0], ''),
                    views: d.metrics[0]
                });
            }
        });
    }
    
    return res;
}

export async function getAreasStats(){
    const rpc = {
        type:  'query',
        transform: true,
        query: 'ec7e9f5f-6357-41a7-94c4-d42a17b2e2aa.cabman_mun_vc',
        params: {date: new Date()}
    };
    let resp = await $http.post(rpc);
    
    
    return resp.sort( (r1, r2) => {
        return (r1.plannedcount > r2.plannedcount)
                    ? -1
                    : (r1.plannedcount === r2.plannedcount) ? 0 : 1;
    });
}   //getAreasStats

