<template>
    <v-card :loading="pending"
            class="mt-areas__widget">
        <v-card-title>
            <v-icon class="mt-carriers__icon">mdi-city</v-icon>
            РЕГИОН
            <v-spacer />
            <v-btn size="small"
                   icon
                   v-on:click="$fetch()">
                <v-icon color="grey">mdi-reload</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="has('all')">
            <div class="text--orange"><v-icon size="small">mdi-bus-multiple</v-icon>&nbsp;ТС по реестрам: <b>{{ totals.vehicles }}</b></div>
            <div class="text-muted">(планируемые рейсы, %-видимости ТС)</div>
            <!--TODO: планирование: {{ totals.plnavg }}% | видимость ТС {{ totals.onlavg }}% -->
        </v-card-subtitle>
        <v-card-text v-if="has('all')">
            <canvas id="areas-chart" height="280"></canvas>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('report')">
                <v-icon small>mdi-file-table-outline</v-icon>
                сформировать отчет
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import Chart from 'chart.js/auto';    
import { getAreasStats } from "@/services/MtInfo";
const _BG_COLORS = ["rgb(14,70,162)", "rgb(14,70,162)", "rgb(14,70,162)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(175,175,175)", "rgb(175,175,175)", "rgb(175,175,175)", "rgb(0,131,143)", "rgb(0,151,167)"];

let _chart = null;

export default {
    name: 'MtAreas',
    data(){
        return {
            pending: false,
            all:   [],
            totals: {
                plnnum: 0,
                plnavg: 0,
                onlnum: 0,
                onlavg: 0,
                vehicles: 0
            }
        };
    },
    created(){
        this.$fetch();
    },
    unmounted(){
        if (_chart){
            _chart.destroy();
        }
    },
    methods: {
        has(q){
            switch(q){
                case "all":
                    return this.all?.length > 0;
            }
            return false;
        },
        $fetch(){
            this.pending = true;
            this.all = [];
            getAreasStats().then( res => {
                console.log('areas', res);
                this.all = res;
                
                if ( res.length > 0 ){
                    let totals =  {
                        plnnum: 0,
                        plnavg: 0,
                        onlnum: 0,
                        onlavg: 0,
                        vehicles: 0
                    },
                    n = 0;
                    res.forEach( r => { 
                        if ( r.onlinerate > 0 ){
                            totals.onlavg += Number(r.onlinerate);
                            totals.onlnum += 1;
                        }
                        if ( r.plannedrate > 0 ){
                            totals.plnavg += Number(r.plannedrate);
                            totals.plnnum += 1;
                        }
                        totals.vehicles += Number(r.reestrcount); 
                    });
                    if ( totals.onlnum > 0 ){
                        totals.onlavg = Number(totals.onlavg / totals.onlnum).toFixed(1);
                    }
                    if ( totals.plnnum > 0 ){
                        totals.plnavg = Number(totals.plnavg / totals.plnnum).toFixed(1);
                    }
                    console.log('totals', totals);
                    this.totals = totals;
                }
                
                this.pending = false;
                
                this.buildChart(0);
            }).catch(e => {
                this.pending = false;
                console.log('ERR (areas-stats)', e);
            });
        },
        buildChart( n ){
            var n = n + 1;
            if ( n > 100 ){
                return;
            }
            const node = $(this.$el).find("#areas-chart");
            if (
                    ( node.length < 1 ) || !this.has("all" )
               ){
                setTimeout(()=>{ this.buildChart.call(this, n ); }, 300);
                return;
            }
            
            const tops = this.all.filter( (a, n) => n < 10 );
            
            node.css({"height": "280px"});
            
            const cfg = {
                data: {
                    labels: tops.map( a => a.title ),
                    datasets: [
                        {
                            type: 'bar',
                            data: tops.map( a => a.plannedcount ),
                            backgroundColor: tops.map( (t, n) => _BG_COLORS.at(n).replace(/^(rgb)+/, 'rgba').replace(/\)$/, ',0.5)') ),
                            borderColor: tops.map( (t, n) => _BG_COLORS.at(n) )
                        },
                        {
                            type: 'line',
                            backgroundColor: "#fdb45c",
                            borderColor: "#fdb45c",
                            data: tops.map( a => a.onlinerate )
                        }
                    ]
                },
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    interaction: {
                        intersect: false,
                        mode: 'index',
                        axis: 'y'
                    },
                    elements: {
                        bar: {borderWidth: 1}
                    },
                    plugins: {
                        legend: false,
                        tooltip: {
                            callbacks: {
                                label: l => {
                                    if ( l.datasetIndex === 0 ){
                                        return `${ l.raw } рейсов`;
                                    }
                                    return `${ l.raw }%`;
                                }
                            }
                        }
                    }
                }
            };
            
            if ( _chart ){
                _chart.destroy();
            }
            
            try {
                _chart = new Chart(node.get(0), cfg);
            }catch(e){
                console.log('ERR(chart)', e);
            }
            
        },   //buildChart
        go(q){
            switch(q){
                case "report":
                    jet.reports.open({
                        id: "cc6bc96f-a8d6-4d4a-a3b7-4a120aaf5e43",
                        uri: "report:/ce1b5269-2cf1-4eed-b3c6-74e7ce297e29/",
                        title: "10.67 Отчет о видимости ТС"
                    });
                    break;
            }
        }   //go
        
    }
}    
</script>
<style lang="scss">
    @import "@/styles/nav-head.scss";
    
    .mt-areas{
        &__widget{
            &.v-card{
                & .v-card__title{
                    background-color: $light-blue;
                    color: $blue;
                    & .v-icon{
                        margin-right: 0.5rem;
                        color: $blue;
                    }
                }
                & .v-card__subtitle{
                    margin-top: 0 !important;
                    & .v-icon {
                        color: $orange !important;
                    }
                    & span{
                        color: $orange;
                        font-weight: 500;
                    }
                }
                
                & .v-card__text{
                    & canvas{
                        min-height: 272px;
                        width: 100%;
                        margin: 1rem auto;
                    }
                    h3 {
                        font-weight: 400;
                        font-style: italic;
                        text-align: right;
                    }
                }
                & .text--orange{
                    color: $orange
                }
            }
        }
    }
</style>
