<template>
    <div class="jet-nav-head">
        <v-toolbar height="auto" absolute>
            <v-slide-group v-model="root"
                           show-arrows>
                <template v-for="item in roots">
                    <v-slide-item :key="item.id"
                                  :value="item.id"
                                  v-slot="{ active, toggle }">
                        <v-menu :value="active"
                                bottom
                                tile
                                origin="bottom left"
                                :min-width="240">
                            <template v-slot:activator="{ on, attrs }">
                                <v-card :color="active ? 'primary' : 'white'"
                                        :dark="active"
                                        :on="on"
                                        v-on:click="item.hasChilds ? toggle() : open(item)">
                                    <v-card-title>
                                        <v-icon>{{ item.ico }}</v-icon>&nbsp;
                                        {{ item.name }}
                                    </v-card-title>
                                    <v-card-text v-html="item.descr"></v-card-text>
                                </v-card>
                            </template>
                            <v-list v-if="item.hasChilds"
                                    dense 
                                    class="jet-nav__childs">
                                <v-subheader>
                                    {{ item.name }}
                                </v-subheader>
                                <v-list-item v-for="c in item.children"
                                             :key="c.id">
                                    {{ c.name }}
                                </v-list-item>
                            </v-list>
                        </v-menu>    
                    </v-slide-item>
                </template>
                <v-slide-item>
                    <mt-plaining :plaining="plaining" 
                                 v-on:click="open({id:'plaining'})" />
                </v-slide-item>
                <v-slide-item>
                    <mt-vehicles-pc :vehicles="vehicles" 
                                    v-on:click="open({id:'vehicles'})" />
                </v-slide-item>
                <v-slide-item>
                    <mt-views />
                </v-slide-item>
            </v-slide-group>
        </v-toolbar>
        <v-container fluid>
            <v-row>
                <v-col cols="7">
                    <v-row>
                        <v-col cols="12">
                            <jet-hello />
                        </v-col>    
                        <v-col cols="12">
                            <mt-routes-widget v-on:total="plaining = $event"/>
                        </v-col>    
                    </v-row>
                </v-col>
                <v-col cols="5">
                    <mt-map-widget />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="7">
                    <mt-carriers />
                </v-col>
                <v-col cols="5">
                    <mt-vehicles :list="false" 
                                 v-on:totals="vehicles = $event"/>
                </v-col>    
            </v-row>
            <v-row>
                <v-col cols="7">
                    <mt-areas />
                </v-col>
            </v-row>    
        </v-container>
    </div>
</template>
<script>
import { getScrollParent } from "@/utils/dom";
import JetHello from '@/components/dev/dashboard/JetHello';
import MtVehicles from '@/components/dev/dashboard/MtVehicles';
import MtCarriers from '@/components/dev/dashboard/MtCarriers';
import MtMapWidget from '@/components/dev/dashboard/MtMapWidget';
import MtRoutesWidget from '@/components/dev/dashboard/MtRoutesWidget';
import MtPlaining from '@/components/dev/dashboard/MtPlaining';
import MtVehiclesPc from '@/components/dev/dashboard/MtVehiclesPc';
import MtViews from '@/components/dev/dashboard/MtViews';
import MtAreas from '@/components/dev/dashboard/MtAreas';
    
const MT_ROOTS = {
    tobase: {
        id: "tobasenav",
        name: "К основной навигации",
        descr: "переход к функциональным<br />подсистемам и модулям",
        ico: "mdi-apps",
        children: null,
        hasChilds: false,
        uri: "base:/"
    },
    fav: {
        id: "favorites",
        name: "Избранное",
        descr: 'коллекции, сохраненные<br />для "быстрого" доступа',
        ico: "mdi-star",
        children: null,
        hasChilds: false,
        uri: false
    },
    map: {
        id: "map",
        name: "Карта",
        descr: 'on-line мониторинг',
        ico: "mdi-map-legend",
        children: null,
        hasChilds: false,
        uri: "map:/"
    }
};
export default {
    name: "JetNavHead",
    components: {
        JetHello,
        MtVehicles,
        MtCarriers,
        MtMapWidget,
        MtRoutesWidget,
        MtPlaining,
        MtVehiclesPc,
        MtViews,
        MtAreas
    },
    inject: [
        "openMap"
    ],
    data(){
        return {
            all: MT_ROOTS,
            root: null,
            plaining: null,
            vehicles: null
        };
    },
    mounted(){
        this.getfavs();
        setTimeout(()=>{
            const bar = $(this.$el).find(".v-toolbar"),
                conte = $(this.$el).find(".container");
            console.log('bar', bar.outerHeight());
            const h = bar.outerHeight() - 48;
            conte.css({marginTop: `${ h }px`, height: `calc(100% - ${ h }px`});
        }, 300);
    },
    computed: {
        roots(){
            return Object.keys(this.all).map( k => this.all[k] ).filter( a => a.hasChilds || a.uri );
        }
    },
    methods: {
        getfavs(){
            this.$store.dispatch("app/getFavs").then( ()=>{
                this.all.fav.children = this.$store.state.app.favs;
                this.all.fav.hasChilds = this.all.fav.children?.length > 0;
            });
        },
        open(item){
            switch (item.id){
                case "tobasenav":
                    this.$emit("base");
                    break;
                case "map":
                    this.openMap();
                    break;
                case "plaining":
                    jet.collections.open({
                        id: "07feb476-a676-4176-bbb1-2806ce3b5a7e",
                        name: "Планирование",
                        uri: false
                    });
                    break;
                case "vehicles":
                    let card = $(this.$el).find(".container .mt-vehicles");
                    let container = getScrollParent(card.get(0));
                    this.$vuetify.goTo(card.get(0), {container, offset: 40});
                    break;
            }
        }   //opan
    }
};
</script>
<style lang="scss" scoped>
    @import "@/styles/nav-head.scss";
    
    .jet-nav{
        &-head {
            background: #b0deff; /*#f5f5f5;*/
            height: calc(100vh - 200px);
            overflow: hidden;
            & .v-toolbar{
                width: 100%;
                z-index: 3;
                background: #e6f3fe;
                height: 166px;
                & .v-card{
                    margin: 0.5rem 1rem 0.5rem 0;
                    &__title{
                        color: var(--v-primary-base);
                        text-transform: uppercase;
                        font-size: 1rem;
                        & .v-icon{
                            color: var(--v-primary-base);
                        }
                    }
                    &__text{
                        line-height: 1.125;
                    }
                    &.theme--dark{
                        & .v-card__title{
                            color: white;
                            & .v-icon{
                                color: white;
                            }
                        }
                    }
                }
            }
            & .container{
                height: calc(100% - 122px);
                overflow: auto;
                margin-top: 122px;
                padding-top: 1rem;
                padding-bottom: 5rem;
            }
        }
        &__childs{
            & .v-list-item{
                &:not(:last-child){
                    border-bottom: 1px solid #ccc;
                }
            }
        }
    }
</style>