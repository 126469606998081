<script>
import { NULL_ID, isEmpty } from "@/utils";
    
export default{
    props: {
        owner: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        /*params*/
    }),
    created(){
        console.log('10.64 created by', this.owner);
        this.owner.on_set = this._set;
        this._setRoute(null);
    },
    methods: {
        _set(param){
            console.log('10.64.set: ' + param.name + ' ', param.value);
            switch (param.name){
                case "routeId":
                    this._setRoute(param.value);
                    break;
            }
        },
        _setRoute(routeId){
            this.routeId = routeId;
            const comp = this.owner.$refs["versionID"];
            const filter = `eq(field(\"vcReleaseSchedules.route\"),param(\"${ isEmpty(routeId) ? NULL_ID : routeId }\", \"id\"))`;
            console.log('comp (filter)', comp, filter);
            comp.setFilter(filter);
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>