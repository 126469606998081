<template>
    <v-dialog v-model="show"
              content-class="h-stop"
              scrollable
              max-width="760"
              min-height="480">
        <v-card flat rounded="0" 
                :loading="pending">
            <v-toolbar color="primary" dark dense>
                <v-toolbar-title>
                    <v-icon small>mdi-bus-stop</v-icon>
                    {{ title }}
                    <div class="text-muted">
                        расписания проходящих маршрутов
                    </div>
                </v-toolbar-title>
                <v-spacer />
                <v-btn v-on:click="show = false"
                       icon>
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <v-data-table v-if="pending || has('routes')"
                              class="mt-stop__routes jet-table"
                              fixed-header
                              disable-sort
                              :headers="hdrs"
                              :items="routes"
                              :items-per-page="-1"
                              item-value="id"
                              single-select
                              disable-pagination
                              hide-default-footer
                              no-data-text="..."
                              :height="640">
                    <template v-slot:item.start="{ item }">
                        {{ item.start.format('HH:mm') }}
                    </template>
                    <template v-slot:item.fact="{ item }">
                        {{ item.fact ? item.fact.format('HH:mm') : '' }}
                    </template>
                </v-data-table>
                <v-alert v-else
                         class="ma-5">
                    По выбранному ОП <b>{{ title }}</b> нет проходящих маршрутов
                </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn small
                       color="primary" 
                       v-on:click="show = false">закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { getStopStatistics } from "@/services/MtInfo";

const _HDRS = [
        {text: '№',       value: 'routecode'},
        {text: 'Маршрут', value: 'routename'},
        {text: 'Время по расписанию', value: 'start', width: 48},
        {text: 'Время фактическое',   value: 'fact', width: 48},
        {text: '№ рейса', value: 'tripcode'},
        {text: 'ТС',      value: 'govnum'}
];

export default {
    name: "MtStopRoutes",
    data(){
        return {
            show: false,
            pending: false,
            stop: null,
            routes: null,
            hdrs: _HDRS
        };
    },
    computed: {
        title(){
            return this.stop?.name || '';
        }
    },
    methods: {
        has(q){
            switch(q){
                case "routes":
                    return this.routes?.length > 0;
            }
            return false;
        },
        $fetch(){
            this.pending = true;
            this.routes  = [];
            getStopStatistics(this.stop.id).then( res => {
                this.routes = res;
            }).catch(e => {
                jet.msg({
                    text: `Ошибка получения списка маршрутов по выбранной остановке:<div class="small">${ e.message }</div>`,
                    color: 'warning'
                });
            }).finally(()=>{
                this.pending = false;
            });
        },
        open(stop){
            this.stop = stop;
            this.show = true;
            this.$fetch();
        }
    }
};
</script>
<style lang="scss">
    .h-stop{
        & .v-toolbar{
            &-title{
                width: 100%;
                font-size: 1rem;
            }
        }
    }
</style>