<template>
    <v-footer app inset light class="jet-footer">
        <div class="footer_copyright" v-html="get('copy')"></div>
        <v-spacer/>
        <v-btn text v-on:click="$emit('onperiod')" small>
            {{ period.current }} / {{ period.begin }} - {{ period.end }}
        </v-btn>
        <v-btn class="ma-1" text icon small title="Справка">
            <v-icon small>mdi-help-circle-outline</v-icon>
        </v-btn>
    </v-footer>
</template>

<script>
import { isEmpty } from '@/utils';

export default {
    name: 'JetFooterBar',
    computed: {
      period(){
          const _m = this.$moment,
                _f = 'DD.MM.YYYY';
          return {
                begin: _m(this.$store.getters['period/begin']).format(_f),
                current: _m(this.$store.getters['period/current']).format(_f),
                end: _m(this.$store.getters['period/end']).format(_f)
          };
      }
    },
    methods: {
        get(q){
            switch(q){
                case "copy":
                    return isEmpty(this.$store.getters['branding/get']('brand.copy')) 
                                ? this.$store.getters['branding/get']('brand.web.system.name')
                                : this.$store.getters['branding/get']('brand.copy');
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
    .jet-footer {
        font-size: 0.75rem;
        padding: 0 16px;
        .v-btn{
            letter-spacing: unset;
            margin: 0 auto;
            color: #676767;
            font-size: 0.85rem;
            font-weight: normal;
        }
    }
</style>
