<template>
    <v-list class="jet-favs"
            dense>
        <v-list-item v-for="f in favs"
                     :key="'fav' + f.id"
                     v-on:click="$emit('open', f)">
            {{ f.name }}
        </v-list-item>
    </v-list>
</template>
<script>
    export default {
        name: 'JetFavs',
        computed: {
            favs(){
                return this.$store.state.app.favs;
            }
        }
    }
</script>    
<style lang="scss">
    .jet-favs{
        & .v-list-item:not(:last-child){
            border-bottom: 1px solid #ccc;
        }
    }
</style>