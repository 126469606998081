/**
 * Map sin2 NQ-result to json-plain obj`s
 * @param {Object} sin2-result object
 * @returns { Array }
 */
function _sin2objNq(sin2res) {
    const {fields, data} = sin2res,
          res = [];
          
    if (
            (data) && Array.isArray(data)
       ){
       data.forEach( d => {
           let o = {};
           fields.forEach( (f, n) => {
               o[f] = d[n];
           });
           res.push(o);
       });
    }
    return res;
};      //_sin2objNq

/**
 * Map sin2 result to json-plain obj`s
 * @param {Object} sin2res result of sin2 jsonRps query
 * @returns {Array|sin2obj.res}
 */
export function sin2obj(sin2res){
    if ( !sin2res.columnIndexes ){
        return _sin2objNq(sin2res);
    }
    
    const { data } = sin2res,
          res = [];
    
    
    if (
            (data) && Array.isArray(data)
       ){
       
        const keys = sin2obj.getkeys( sin2res );
        
        data.forEach( e => {
            let o = {};
            keys.forEach( k => {
                o[k.id] = e[k.n];
            });
            res.push(o);
        });
    }
    
    return res;
};

/**
 * Prepare result keys for json {n: columnIndex, id: field(value), ...}
 */
sin2obj.getkeys = sin2res => {
    
    const { columnIndexes, model } = sin2res;
    const { columns } = model,
          classId = model.idColumn.id?.split('.')?.at(0),
          keys = [];
      
    Object.keys(columnIndexes).forEach( k => {
        let n = columnIndexes[k];
        let rawcol = columns[n];
        
        if ( /^(\_sec\_|\_ssc\_)+/.test(rawcol.id) ) {
            return;
        }
        
        let col = rawcol.id.split(".");
        
        if ( col[0] === classId ){
            col.splice(0, 1);
        }
                  
        keys.push( {
                n,
                id:     col.join(""),
                _id:    rawcol.id,
                type:   rawcol.typeName,
                asName: "id"!==rawcol.typeName && rawcol.attributes.asName,
                isId:   rawcol.id === model.idColumnId,
                hidden: rawcol.attributes.hiddenInTable,
                title:  rawcol.title
        });
    });
    
    return keys;
};
