<script>
/**
 * Collection implementation: used at JetConte
 */
import Vue from 'vue';
import Model from '@/core/Model';
import JetTable from '@/components/JetTable';
import JetSvg from '@/components/JetSvg';
import Main from '@/components/dev/Main';
import DspRoutes from '@/components/dev/dspRoutes';
import PtMonMap from '@/components/dev/ptMonMap';
import Geozones from '@/components/dev/components/geozones/Geozones';
import Planning from '@/components/dev/modules/planning/Planning';
import InterCarrier from '@/components/dev/modules/inter_carrier/InterCarrier';
import Notifications from '@/components/dev/modules/notifications/Notifications';
import Moderation from './dev/modules/moderation/Moderation';
import SelectProfile from '@/components/dev/modules/profile/SelectProfile';
import MtDashboard from '@/components/dev/MtDashboard';
import MtSchedules from '@/components/dev/MtSchedules';
import Incidents from './dev/modules/incidents/Incidents';
import CarrierReports from './dev/modules/carrier_reports/CarrierReports';
import Tariffing from '@/components/dev/modules/tariffing/Tariffing';
import Violations from '@/components/dev/modules/violations/Violations';
import AutoBuildingReport from '@/components/dev/modules/auto_building_10_44/AutoBuilding10.44';
import LocationsSchedules from '@/components/dev/modules/locations_schedules/LocationsSchedules';

import TableReport from '@/components/JetTableReport';
import JetReportTab from '@/components/JetReportTab';
import evacMap from "@/components/dev/components/eva-ext/evacMap";  

import { EvaJ } from '@/components/dev/components/eva-ext/EvaJ';
import { VehiclesAll } from "@/components/dev/components/vehicles/VehiclesAll";  

class ApiCollectionTable {
  constructor(collection) {
    this._coll = collection;
  }

  get loading() {
    return this._coll.rowsLoading;
  }

  get count() {
    return this._coll.rows.length;
  }

  get rows() {
    return this._coll.rows;
  }

  get selected() {
    return this._coll.selected;
    //return (this._coll.selRow < 0) ? null : this._coll.rows[this._coll.selRow];
  }

  get index() {
    console.log('Unsupported value index');
    return this._coll.selRow;
  }

  /**
   * id from selected row
   * @returns {uuid/null}
   */
  get id() {
    return this._coll.getId();
  }

  /*
   * select row by id
   */
  set id(id) {
    this._coll.setId(id);
  }

  set(name, val) {
    this._coll.set(name, val);
  }
  
  get extend(){
    return this._coll.$refs["extend"];
  }
  
}  //api

const DSP_ROUTES_GUID = 'c7d84861-a522-4ed8-891c-3bd82c9fd370';
const MAP_ROUTES_GUID = '802b6602-a729-4b6b-b01a-772e29cb6647';
const GEO_ZONE_ROUTES_GUID = 'f9bbc4ab-3a04-4192-a948-5fe4b227f1b0';
const PLANNING_GUID = '07feb476-a676-4176-bbb1-2806ce3b5a7e';
const INTER_CARRIER_GUID = '014e273b-7e1d-40e1-9504-2b134281a4e9';
const NOTIFICATIONS_GUID = 'd51d5cb4-d16e-4251-89d5-c27e5727b163';
const PROFILE_GUID = '8d9edb1f-b8ef-4b3a-b0c9-f08f48e8d585';
const MODERATION_GUID = 'f1bda563-e308-4882-9615-dd527a152043';
const INCIDENTS_GUID = '34ef1259-92c2-438b-9dd3-9f65c46983bc';
const SCHEDULE_GUID = '3100ec29-e929-4a9c-83cc-15b87fbe2800';
const SCHEDULE_IN_ROUTES_GUID = '99d02cc9-733e-4702-becd-3d9f2964d58f';
const CARRIER_REPORTS_GUID = 'e62dbf26-f0a8-4dfe-8e1e-0a6dec26f850';
const TARIFFING_GUID = '84b0aa5f-419e-4b92-9ff6-5c1cab7c5550';
const VIOLATIONS_GUID_OLD = '24c2f5b8-bdf0-44f9-a3ea-09768e7e39d3';
const VIOLATIONS_GUID = 'ec07bdb9-2e87-43e2-b399-c58fa4f1cfca';
const SETTINGS_AUTOBUILDING_10_44_GUID = 'c8a7b617-64f1-4e27-8566-91eb766e60ef';
const LOCATIONS_SCHEDULES_GUID = '59941934-608d-4c17-944a-7a037c0c7169';
const REPORT = 'REPORT';

const ROUTES_BY_CONTRACT = 'ffd7d02e-81e4-48bf-8066-c384c9385a84';

const EVAC_MAP = '4a409336-70d8-4e77-9ab3-c144c8e1f253';
const VEHICLES = 'ad3ddb59-060d-47d1-b7d9-e000d3d9f28f';

const JetTabMenu = {
    name: 'JetTabMenu',
    props: ['show', 'actions'],
    components: {JetSvg},
    data(){
        return {menu: false};
    },
    watch: {
        show(val){
            this.menu = (!!val) ? true : false;
        }
    },
    computed: {
        hasActions(){
            return ((!!this.actions) && (this.actions.length > 0));
        }
    },
    render(h){
        return h('v-menu', {
            props: {
                absolute: true,
                "close-on-click": true,
                "close-on-content-click": true,
                "content-class":"jet-tab-menu",
                "position-x": this.show.x,
                "position-y": this.show.y,
                tile: true,
                value: this.menu
            }
        }, [
            h('v-list', {
                props: {dense: true}
            }, [
                this.hasActions
                    ? [h('v-menu', {
                            props: {
                                "content-class": "jet-tab-menu",
                                "close-on-click": true,
                                "close-on-content-click": true,
                                "open-on-hover": true,
                                "offset-x": true,
                                tile: true
                            },
                            scopedSlots: {
                                activator: ({on}) => {
                                    return h('v-list-item', {on: on}, [
                                        h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-kra'}})]),
                                        h('v-list-item-content', 'действия')
                                    ]);
                                }
                            }
                        }, [
                            h('v-list', {props: {dense: true}}, [
                                this.actions.map((a)=>{
                                    return h('v-list-item', {
                                        on: {click: ()=>{
                                                this.menu = false;
                                                this.$parent.$emit('action', a);
                                        }}
                                    }, a.name);
                                })
                            ])
                        ]),
                        h('v-divider')]
                    : null,
                h('v-list-item', {on: {click: ()=>{this.$parent.$emit('edit', true);}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-plus'}})]),
                    h('v-list-item-content', 'добавить')
                ]),
                h('v-list-item', {on: {click: ()=>{this.$parent.$emit('edit', false);}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-edit'}})]),
                    h('v-list-item-content', 'редактировать')
                ]),
                h('v-list-item', {on: {click: ()=>{this.$parent.$emit('delete');}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-minus'}})]),
                    h('v-list-item-content', 'удалить')
                ]),
                h('v-divider'),
                h('v-list-item', {on: {click: ()=>{this.$emit('refresh');}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-refresh'}})]),
                    h('v-list-item-content', 'обновить')
                ]),
                h('v-list-item', {on: {click: ()=>{this.$emit('exportdata');}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-file-excel'}})]),
                    h('v-list-item-content', 'экспорт')
                ]),
                h('v-divider'),
                h('v-list-item', {on: {click: ()=>{this.$parent.$emit('audit');}}}, [
                    h('v-list-item-icon', [h('jet-svg', {props: {xref: '#ico-clipboard-check'}})]),
                    h('v-list-item-content', 'открыть аудит')
                ])

            ])
        ]);
    }
};  //JetTabMenu


export default {
  constants: {
    DSP_ROUTES_GUID,
    MAP_ROUTES_GUID,
    GEO_ZONE_ROUTES_GUID,
    INTER_CARRIER_GUID,
    NOTIFICATIONS_GUID,
    PROFILE_GUID,
    MODERATION_GUID,
    INCIDENTS_GUID,
    SCHEDULE_GUID,
    SCHEDULE_IN_ROUTES_GUID,
    CARRIER_REPORTS_GUID,
    TARIFFING_GUID,
    VIOLATIONS_GUID,
    VIOLATIONS_GUID_OLD,
    SETTINGS_AUTOBUILDING_10_44_GUID,
    LOCATIONS_SCHEDULES_GUID,
    REPORT,
    EVAC_MAP,
    VEHICLES
  },
  name: 'JetCollection',
  components: {
      JetTable,
      JetTabMenu
  },
  props: {
    collectionInfo: { /* collection Info {id,name,master,uri} */
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    parentId: {             /* m-d supporting value */
        type: String,
        required: false
    }
  },
  data: function(){
    return {
      api: null,
      rowsLoading: false,
      model: null,
      headers: [],
      error: null,
      rows: [],
      selRow: -1,     /* TODO: */
      meta: null,     /* meta from fs Lob (links & other...) */
      focused: false,
      selected: null,
      ctxShow: false, /* context menu show params (see: JetTable contextmenu:row) */
      s: null,         /* search string */
      comp: null,
    };
  },
  computed: {
        isAllow(){
            return (!!this.model);
        },
        isDataView(){
            var {uri} = this.collectionInfo;
            return ((!!uri)&&(uri.indexOf('sin2:/v:')>-1));
        },
        id(){
            return this.collectionInfo.id;
        },
        title: {
            get(){
                return this.collectionInfo.name;
            },
            set(title){
                this.collectionInfo.name = title;
            }
        },
        master(){
            return (!!this.collectionInfo) ? this.collectionInfo.master : false;
        },
        actions(){
            if (!!this.model){
                return this.model.guiActionHtml;
            }
            return [];
        },
        loadData(){ /* disabling std load data from sin2 */
            if (
                    (!!this.extend)
                 && (!!this.extend.data)
                 && (typeof this.extend.data().loadData !== 'undefined') ){
                return this.extend.data().loadData;
            }
            return true;
        }
    }, //computed
    methods: {
        refresh: async function(id){ /*TODO: by id-only*/
            const extend = this.$refs['extend'];
            if (!!extend){
                if (extend.refresh){
                    return extend.refresh(id);
                }
            }
            if ( !this.loadData ){ //disabling loading with JS
                return;
            }
            if (!this.collectionInfo.master && $utils.isEmpty(this.parentId)){
                return;
            }

            this.error = null;
            var only = !$utils.isEmpty(id),
                { uri } = this.collectionInfo;

            if (only){
                uri = uri.substr(0, 44) + '?id=' + id;
            } else {
                if (!this.collectionInfo.master){
                    uri = (!!this.parentId)
                            ? unescape(uri).replace(/\$\{sin.client.form.getValueAsParam\((.*)\)\}/gi, 'param("' + this.parentId + '","id")')
                            : null;
                    if(this.id == ROUTES_BY_CONTRACT) {
                      uri = `sin2:/v:ffd7d02e-81e4-48bf-8066-c384c9385a84/?filter=and(
                        isnull(field(".vehicleID")),
                        eq(field(".contractID"),param("${this.parentId}","id"))
                      )`;
                    }
                    
                }
            }


            this.selected = null;
            if (!uri){
                this.rows = []; //empty data for bad-uri
                return;
            }

            this.rowsLoading = true;
            const table = this.$refs["table"];
            if (typeof table?.onBeforeLoad !== "undefined"){
                table.onBeforeLoad(this.model);
            }
            try {
                var res = await $http
                            .post('/rpc?d=jsonRpc', {
                                type: 'core-read',
                                query: uri
                            });
                console.log('refresh', res);
                this.rowsLoading = false;
                if (res.error){
                    this.error = res.error;
                    throw res.error;
                }
                const ci = this.model.columnIndexes,
                      data = res.result.data,
                      rows = [];
                var keyId = this.model.columnId,
                    current = -1;
                data.map((_raw, i)=>{
                    var row = {};
                    for (const columnId in ci) {
                        if (ci.hasOwnProperty(columnId)) {
                            const index = ci[columnId];
                            row[columnId] = _raw[index];
                        }
                    }
                    rows.push(row);
                });
                if (only){  // insert/update row
                  if(!!rows[0]) {
                    current = this.rows.findIndex( r => r[keyId]===id);
                    if (current > -1){
                      this.rows.splice(current, 1, rows[0]);
                    } else {
                      this.rows.unshift(rows[0]);
                    }
                  }  
                } else {
                  this.rows = rows;
                }
                const selectedId = id || this.collectionInfo.selectedId;
                if (!!selectedId) {
                  //this.set("id", selectedId);
                  const selectedRow = this.rows.find(it => it[keyId] === selectedId);
                  if (table){
                    table.selected = selectedRow;
                    this.selected = selectedRow;
                  }
                }
                
                if (typeof table?.onAfterLoad !== "undefined"){
                    table.onAfterLoad();
                }

                if(this.rows.length) {
                  const rowsCodes = this.rows.map(it => it?.sincalendarCode);
                  this.$emit('max-row-code', Math.max(...rowsCodes));
                }
                
            } catch(err) {
                console.log('ERR refresh', err);
                this.error = err;
            } finally {
                this.rowsLoading = false;
            }
        },       //refresh
        getId(){
            const tb = this.$refs["table"];
            return (!!tb) ? tb.selId : null;
        },
        setId(id){
            const tb = this.$refs["table"];
            if (!!tb){
                tb.selId = id;
            }
        },
        set(name, val){
            switch(name){
                case "focused":
                    this.focused = val;
                    break;
                case "id":
                    this.setId(val);
                    break;
                case "title":
                    this.title = val;
                    break;
                case "parent":
                    this.set_parent(val);
                    break;
                default:
                    const extend = this.$refs['extend'];
                    if (!!extend){
                        if (!!extend.set){
                            extend.set(name, val);
                        }
                    }
            }
        },
        audit() {
            var tb  = this.$refs["table"];
            if ((!tb)||(!tb.selected)){
              jet.msg({
                text:'Для просмотра данных аудита необходимо выбрать запись в таблице!',
                color:'default'
              });
              return;
            }
            jet.audit(tb.selId, tb.selName);
        },
        exportdata() {
          var tb  = this.$refs["table"];
          if (typeof tb !== 'undefined') {
            $xls.exportdata(tb);
          } else if (this.$children.length > 0) {
            var chs = this.$children[0];
            if (!!chs.reportName && chs.tableHeaders.length > 0) {
              chs.model = {columns: chs.tableHeaders, name: chs.reportName};
              $xls.exportdata(chs);
            }
          }
        },
        async delete() {
            var tb  = this.$refs["table"];
            if ((!tb)||(!tb.selected)){
                return;
            }
            var yes = await jet.confirm({
                    title: 'Удаление',
                    msg: 'ВНИМАНИЕ! Вы действительно хотите удалить выбранную запись из таблицы "'
                        + this.title + '": ' + tb.selName + '?'
                });
            if (!yes){
                return;
            }
            try {
                const cid = this.model.columnId,
                      id_for_delete = tb.selId;
                var { uri } = this.collectionInfo,
                uri = uri.substr(0, 44) + '?id=' + id_for_delete;
                var res = await $http
                  .post('/rpc?d=jsonRpc', {
                    type: 'core-delete',
                    query: uri,
                    params: [{ id: 'id', type: 'id', value: id_for_delete }]
                  });
                if (!!res.error) {
                  throw res.error;
                }
                this.rows.map((r, i) => {
                  if (r[cid] === id_for_delete) {
                    this.rows.splice(i, 1);
                  }
                });
            } catch (e) {
                jet.msg({
                  text: 'ВНИМАНИЕ! Ошибка удаления.'
                    + '<br /><small>Информация для технической поддержки: '
                    + e.message + '</small>',
                  color: 'warning'
                });
            }
        },  //delete
        search(s){
            this.s = s;
            const extend = this.$refs['extend'];
            if (!!extend){
                if (extend.search){
                    return extend.search(s);
                }
            }
        }
    },    //methods
    created: function() {
        this.api = new ApiCollectionTable(this);
        let { master, uri, id } = this.collectionInfo,
            empty_model = {
                                id: this.id,
                                columns: [],
                                projects: []
            };
            
        if ('tab-dashboard' === this.id) {
          this.model = new Model(empty_model);
          this.extend = MtDashboard;
          return;
        }

    let ext = null;
    if (this.id === 'e7bea991-16e7-4efb-8081-b9553010a516') {    //Dashboard
      this.model = new Model(empty_model);
      this.extend = MtDashboard;
    } else if (this.id === SCHEDULE_GUID || this.id === SCHEDULE_IN_ROUTES_GUID) { // Расписания
      this.model = new Model(empty_model);
      this.extend = MtSchedules;
    } else if (this.id === DSP_ROUTES_GUID) { // Дисп-маршруты
      this.model = new Model(empty_model);
      this.extend = DspRoutes;
    } else if (this.id === MAP_ROUTES_GUID) { // ПП: карта
      this.model = new Model(empty_model);
      this.extend = PtMonMap;
    } else if (this.id === GEO_ZONE_ROUTES_GUID) { // Геозоны
      this.model = new Model(empty_model);
      this.extend = Geozones;
    } else if (this.id === PLANNING_GUID) { // Планирование
      this.model = new Model(empty_model);
      this.extend = Planning;
      // } else if (this.id === INTER_CARRIER_GUID) { // Межрегиональные перевозчики
      //   this.model = new Model(empty_model);
      //   this.extend = InterCarrier;
    } else if (this.id === NOTIFICATIONS_GUID) { // Уведомления
      this.model = new Model(empty_model);
      this.extend = Notifications;
    } else if (this.id === PROFILE_GUID) { // Профиль
      this.model = new Model(empty_model);
      this.extend = SelectProfile;
    } else if (this.id === MODERATION_GUID) { // Модерация
      this.model = new Model(empty_model);
      this.extend = Moderation;
    } else if (this.id === INCIDENTS_GUID) { // Инциденты
      this.model = new Model(empty_model);
      this.extend = Incidents;
    } else if (this.id === CARRIER_REPORTS_GUID) { // Отчётность
      this.model = new Model(empty_model);
      this.extend = CarrierReports;
    } else if (this.id === TARIFFING_GUID) { // Тарификация
      this.model = new Model(empty_model);
      this.extend = Tariffing;
    } else if (this.id === VIOLATIONS_GUID_OLD || this.id === VIOLATIONS_GUID) { // Контроль нарушений
      this.model = new Model(empty_model);
      this.extend = Violations;
    } else if (this.id === SETTINGS_AUTOBUILDING_10_44_GUID) { // Тарификация
      this.model = new Model(empty_model);
      this.extend = AutoBuildingReport;
    } else if (this.id === LOCATIONS_SCHEDULES_GUID) { // Поиск проезда через остановки
      this.model = new Model(empty_model);
      this.extend = LocationsSchedules;
    } else if ( /REPORT/i.test(this.id) ) {
      this.model = new Model(empty_model);
      this.extend = TableReport;
      this.extend.props = {collection: this.collectionInfo};
    } else if ( /report/i.test(this.collectionInfo.uri) ) {
      this.model = new Model(empty_model);
      this.extend = JetReportTab;
      this.extend.props = {report: this.collectionInfo};
    } else if(this.id === EVAC_MAP) { // Карта эвакуаторов
      this.model = new Model(empty_model);
      this.extend = evacMap;
    } else {
      
      if ( !this.isDataView ) {
        // console.log('No data model #', this.id);
        return;
      }
      
      let n = uri.lastIndexOf('/');
      uri = ((n > 36) ? uri.substr(0, n) : uri) + '?id=null';

      const self = this;
      
      try {
        (async () => {
          var resp = await $http.post('/rpc?d=jsonRpc', {
            type: 'core-read',
            query: uri
          });
          if (resp.error) {
            throw resp.error;
          }
          //TODO: Named Query handling
          var model = new Model(resp.result.model);
          //console.log('2.JetCollection-created (model)', model);
          const scripts = model.projects.filter((p) => {
            return p.isCtrl;
          });
          if (scripts.length > 0) {
            var runner = await $http.get(
              '/rpc?d=file&uri=' + model.scripts[0].resource.ref,
              {
                dataType: 'text',
                contentType: 'text/plain',
              },
            );
            if (Array.isArray(runner)) {
              ext = new Function(`"use strict";${runner[0]}`)();
            }
          }
          if (!!ext) {
            // console.log('Extending at #', self.id, ext);
            self.extend = ext;
          }
          
          var comp;
          if ( id === 'fc4d98cc-4e89-4d73-8b20-ae56e1df6646' ){
              comp = { ...EvaJ };
          } else if ( id === VEHICLES ){
              comp = { ...VehiclesAll };
          } else {
              comp = { ...JetTable };
          }
          comp.name = `JetTable${id.replaceAll('-', '')}`;
          Vue.component(comp.name, comp);
          this.comp = comp;
        
          await model.get_meta();   //get model meta (editor & etc...)

          self.model = model;
          self.headers = self.model.columns;
          self.$emit('on-model', model);
          
          // console.log('4.JetCollection-refreshing', self.loadData);
          self.$nextTick( () => {
              
            if ( (master) || !$utils.isEmpty(self.parentId) ) {
              self.refresh();
            }
          });
        })();
      } catch(e) {
        console.log(e);
        this.error = e;
      }
    }
  },       //created
  watch: {
    error: function(err) {
      jet.msg({
        text: 'ВНИМАНИЕ! Ошибка получения данных, попробуйте обновить таблицу позже<br /><small>Информация для технической поддержки: '
          + (!!err.message) ? err.message : err + '</span>', color: 'warning'
      });
    },
    parentId: function(val){
        if (this.active){
            this.$nextTick(this.refresh);
        } else {
            this.rows = []; //reset for next load
        }
    },
    active: function(val){
        if ((!!val)&&(this.rows.length<1)){
            this.$nextTick(this.refresh);
        }
    }
  },
  render(h){
    var childs = null;
    if (this.error) {
      const msg = 'ВНИМАНИЕ! При загрузке данных произошла ошибка. Попробуйте обновить \
                        коллекцию или обратитесь в техническую поддержку.<br />\
                        <small>Информация для разработчиков: ' + this.error.message + '</small>';    //TODO: text(?)
      childs = [h('v-alert', {
        props: {type: 'error', outlined: true, text: true},
        class: { 'my-5': true, 'mx-5': true },
        domProps: { innerHTML: msg }
      })];
    } else if ( !this.model ) {                            //for model loading
      childs = [h('v-progress-linear', { props: { indeterminate: true }, class: { 'my-6': true } })];
    } else if ( this.extend ) {
        childs = [
          h(this.extend, {
            ref: 'extend',
            on: {
              'on-model': model => {
                this.$emit('on-model', model);
              }
            },
            props: (this.extend.props) ? this.extend.props : null
          })
        ];
    } else if ( !this.isDataView ) {
        childs = [];    //TODO:
    } else {
        const { master, id } = this.collectionInfo;
        
        
        childs = [
            h(this.comp, {
                ref: "table",
                props: {
                    loading: this.rowsLoading,
                    mode: (!!master) ? 'master' : 'detail',
                    model: this.model,
                    rows: this.rows,
                    focused: this.focused,
                    search: this.s
                },
                on: {
                    change: id => {
                        this.selected = this.$refs["table"].selected;
                        if (!!master){
                            this.$emit('change', id);
                        }
                    },
                    contextmenu: e => {
                        this.ctxShow = e;
                    }
                }
            }),
            h('jet-tab-menu', {
                props: {show: this.ctxShow, actions: this.actions},
                on: {
                    refresh: this.refresh
                }
            })
        ];
    }
    
    return h('div', {
        key: 'coll-conte-' + this.collectionInfo.id,
        class: {
          'jet-collection-conte': true,
          'jet-collection-master': this.collectionInfo.master,
          'jet-collection-detail': !this.collectionInfo.master,
          'jet-focused': this.focused
        }
      }, childs
    );
  }   //render
};
</script>

<style lang="scss">
    @import "@/styles/index";
    .jet-tab-menu {
        & .v-list{
            font-size: 0.9rem;
            & .v-list-item {
                min-height: inherit;
                padding: 0.25rem 1rem;
                &:hover{
                    background: $yellow-color;
                }
                &__content{
                    padding: 0;
                }
                &__icon {
                    margin: 0 !important;
                    height: 18px;
                    & svg{
                        width: 18px;
                        height: 18px;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }   /*jet-tab-menu*/
    
    .jet-collection-conte{
        height: 100%;
        &.jet-collection-master{
            height: calc(100% - 48px);
        }
        &.jet-collection-detail{
            overflow: auto;
        }
    }

    .v-slide-group{
        & .v-slide-group__next, & .v-slide-group__prev{
            & .v-icon{
                font-size: 0.9rem;
            }
        }
    }
</style>
