/**
 * Как пользоваться
 *
 * Для установки значений
 *
 * ```js
 *   this.$store.dispatch('cache/setItem', {
 *     key: 'some_key',
 *     value: 'some_value'
 *   });
 * ```
 *
 * Для получения данных
 *
 * TIP: пока только так, другой интерфейс во Vuex так и не приняли
 *
 * ```js
 *   this.$store.getters['cache/getItem']('some_key')
 * ```
 */

const state = {
  // Кеш
  cache: {},
};

const actions = {
  // Установка в кеш через действие
  setItem(context, {key, value}) {
    context.commit('setItem', {key, value});
  },
  // Удаление 1 элемента
  removeItem(context, key) {
    context.commit('removeItem', key);
  },
  // Очистка всего кеша
  clear(context) {
    context.commit('clear');
  },
};

const mutations = {
  // Установка значения в кеш
  setItem(state, {key, value}) {
    state.cache[key] = value;
  },
  // Удаление 1 элемента
  removeItem(state, key) {
    if (_hasOwnProperty(state.cache, key)) {
      delete state.cache[key];
    }
  },
  // Очистка данных
  clear(state) {
    state.cache = {};
  },
};

const getters = {
  // Получение значения по ключу
  getItem: state => key => {
    return state.cache[key] ?? null;
  },
  // Объем кеша
  length: state => state.cache.length,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
