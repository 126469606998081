<template>
    <v-container fluid>
        <v-row align="center">
          <v-col cols="1">
            <v-btn v-on:click="back" 
                   color="primary">
                <v-icon small>mdi-chevron-left</v-icon>&nbsp;назад
            </v-btn>
          </v-col>
          <v-col>
            {{ selectedVehicle.gov }} / {{ selectedVehicle.oname }}
          </v-col>
          <v-col align="right">
            <v-btn color="primary" @click="showTripsVehicle">
              Список всех рейсов для выбранного ТС
            </v-btn>
          </v-col>
        </v-row>

    <!-- Кол-во рейсов, "Только незапланированные", Поиск ТС -->
    <v-row align="center">

      <!-- Искать маршрут -->
      <v-col cols="5">
        <JetSearchField
          label="Искать маршрут"
          v-model="searchRoute"
        ></JetSearchField>
      </v-col>

      <v-col cols="7" class="d-flex">

        <!-- Только незапланированные -->
        <v-checkbox
          class="mt-0 mr-2"
          v-model="onlyUnplanned"
          hide-details
          label="Только незапланированные"
        ></v-checkbox>

        <!-- Назначен выбранный ТС -->
        <v-checkbox
          class="mt-0 mr-2"
          v-model="appointedSelectedVehicle"
          hide-details
          label="Назначен выбранный ТС"
        ></v-checkbox>

        <!-- Выбрать все -->
        <v-checkbox
          class="mt-0"
          v-model="selectAll"
          hide-details
          label="Выбрать все"
        ></v-checkbox>
      </v-col>

    </v-row>

    <v-row v-if="!loadingRoutes" style="height: 500px">

      <!-- Маршруты -->
      <v-col class="fill-height" cols="5" style="overflow: auto"
             :aria-disabled="true">
        <v-radio-group v-model="selectedRoute" class="my-0 pt-0">
          <v-list class="py-0">
            <template v-for="route in filteredRoutes">
              <v-list-item
                :key="route.routeName"
              >
                <v-radio
                  class="py-2 px-1 mb-0 black--text"
                  :label="route.routeName"
                  :value="route"
                ></v-radio>
                <v-spacer/>
                <span class="pr-2 grey--text">
                   {{ route.tripPlan + ' / ' + route.tripTotal }}
                  </span>
              </v-list-item>
              <v-divider :key="'dvider_'+route.routeId"/>
            </template>
          </v-list>
        </v-radio-group>
      </v-col>

      <!-- Постановки -->
      <v-col class="fill-height" cols="7" style="overflow: auto"
             :aria-disabled="true">
        <template v-if="!rightDialog" class="d-flex justify-center">
          Выберите маршрут назаначения транспорта на рейс(ы)
        </template>
        <template v-else>
          <!-- Компоненты Выезд или Рейс -->
          <template :class="{'pb-12' : selected.length > 0}"
                    v-if="!loadingSchedules">
            <template v-for="departure in filteredSchedules">
              <DepartureItem
                v-if="departure.isDeparture"
                :key="departure.id"
                v-model="selected"
                :item="departure"
                :fullDate="fullDate"
                :disabled="loadingPlanButton"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicleExt"
                @click-checkbox="clickCheckbox"
                :onlyUnplanned="onlyUnplanned"
              ></DepartureItem>
              <TripItem
                v-else
                :key="departure.id"
                v-model="selected"
                :item="departure.schedules[0]"
                :fullDate="fullDate"
                :disabled="loadingPlanButton"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicleExt"
                @click-checkbox="clickCheckbox"
              ></TripItem>
            </template>
          </template>
          <div v-else class="b-spinner align-center mt-6" align="center">
            <v-progress-circular indeterminate color="primary"/>
          </div>
        </template>
      </v-col>
    </v-row>
    <div v-else class="b-spinner align-center mt-6" align="center">
      <v-progress-circular indeterminate color="primary"/>
    </div>

    <!-- Bottom bar -->
    <v-row>
      <v-col cols="5" align="right">
        <v-btn color="primary" :disabled="!selectedRoute" @click="showTrips">
          Обновить рейсы
        </v-btn>
      </v-col>

      <v-col cols="3">
        <v-btn color="primary" :disabled="!rightDialog || loadingPlanButton"
               @click="closeSelectTrip">
          Отмена
        </v-btn>
      </v-col>

      <v-col cols="4" class="d-flex justify-end">

        <!-- Удалить -->
        <v-btn
          color="red"
          class="white--text mr-2"
          :disabled="selected.length === 0"
          @click="dialogDelete = true"
        >
          Удалить
        </v-btn>

        <!-- Диалог удаления -->
        <v-dialog v-model="dialogDelete" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="body-1">
               Распланировать
              </span>
            </v-card-title>
            <v-card-text class="text">
              Вы уверены, что хотите распланировать {{ tripsName }}?
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                color="primary"
                @click="dialogDelete = false"
                :disabled="loadingDelete"
              >
                Отмена
              </v-btn>
              <v-btn
                class="white--text"
                color="red"
                @click="deleteTrips()"
                :loading="loadingDelete"
              >
                Снять
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Планировать ТС на рейсов -->
        <v-btn
          color="primary"
          :disabled="selected.length === 0 || isTwoVehicle"
          @click="dialogPlanning = true"
        >
          Планировать ТС на {{ selected.length }} рейсов
        </v-btn>

        <!-- Диалог для планирования -->
        <JetDialog
          title="Планировать"
          v-model="dialogPlanning"
          width="700"
          :show-dividers="false"
        >
          <template v-slot:default>
            Запланировать ТС с рег. номером {{ selectedVehicle.gov }} на {{ tripsName }}.
          </template>
          <template v-slot:actions>
            <div class="px-4 d-flex" style="width: 100%;">
              <v-btn
                color="primary"
                @click="dialogPlanning = false"
                :disabled="loadingPlanButton"
              >
                Отмена
              </v-btn>
              <v-spacer/>
              <v-btn
                v-if="rightDialog"
                :disabled="!selectedVehicle || loadingPlanButton"
                color="primary"
                @click="showPeriodPicker = true"
                class="mr-2"
              >
                Планировать на период
              </v-btn>
              <v-dialog
                ref="dialog"
                v-model="showPeriodPicker"
                persistent
                width="290px"
              >
                <v-date-picker
                  v-model="datePeriodEnd"
                  scrollable
                  locale="ru-Latn"
                  :first-day-of-week="1"
                  :weekday-format="dayOfWeekToFormat"
                >
                  <v-spacer/>
                  <v-btn color="primary" @click="showPeriodPicker = false, datePeriodEnd = fullDate.iso">
                    Отмена
                  </v-btn>
                  <v-btn color="primary" @click="planTripsOnPeriod(), showPeriodPicker = false">
                    Планировать
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-btn
                v-if="rightDialog"
                :disabled="!selectedVehicle"
                color="primary"
                @click="planTrips"
                :loading="loadingPlanButton"
              >
                Планировать
              </v-btn>
            </div>
          </template>
        </JetDialog>
      </v-col>
    </v-row>

    <!-- Диалог выбора остановочного пункта -->
    <v-dialog v-model="stopPointDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="body-1">
            Добавление ТС на рейс {{ tripCode }}
          </span>
        </v-card-title>
        <v-card-text class="text">
          <v-autocomplete
            v-model="stopPoint"
            :items="stopPoints"
            :loading="!loadingStopPoints"
            item-text="name"
            item-value="stopId"
            label="Остановочный пункт"
            placeholder="Введите название остановочного пункта"
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Нет данных (На маршруте 2 конечные ОП)
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="primary"
            @click="stopPointDialog = false"
            :disabled="loadingPlanButton"
          >
            Отмена
          </v-btn>
          <v-btn
            v-if="rightDialog"
            :disabled="!selectedVehicle"
            color="primary"
            @click="planTrips"
            :loading="loadingPlanButton"
          >
            Планировать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Диалог со списком всех рейсов для выбранного ТС -->
    <v-dialog v-model="dialogVehicleTrips" width="900px" persistent>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="body-1 py-0">
              Список всех рейсов для <b>{{ selectedVehicle.gov }}</b> на {{ fullDate.format }}
            </v-col>
            <v-col class="py-0 d-flex justify-end">
              <v-checkbox
                class="mt-0 mr-2"
                v-model="selectAllVehiclePlan"
                hide-details
                label="Выбрать все"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="black--text">
          <!-- Компоненты Выезд или Рейс -->
          <template
            :class="{'pb-12' : selected.length > 0}"
            v-if="!loadingVehicleSchedules"
          >
            <template v-for="departure in vehicleTrips">
              <DepartureItem
                v-if="departure.isDeparture"
                :key="departure.id"
                :item="departure"
                :value="selectedTripsVehicle"
                :fullDate="fullDate"
                :disabled="loadingPlanButton"
                :structure="{
                    checkbox: true,
                    addVehicle: false,
                    delete: true,
                    plan: true,
                }"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicleExt"
              ></DepartureItem>
              <TripItem
                v-else
                :key="departure.id"
                :item="departure.schedules[0]"
                :value="selectedTripsVehicle"
                :fullDate="fullDate"
                :disabled="loadingPlanButton"
                :structure="{
                    checkbox: true,
                    addVehicle: false,
                    delete: true,
                    plan: true,
                  }"
                @plan="planButton"
                @deleted="deletedTrips"
                @add-vehicle="addVehicleExt"
              ></TripItem>
            </template>
            <div v-if="vehicleTrips.length === 0">
              Нет постановок для выбранного ТС
            </div>
          </template>
          <div v-else class="b-spinner align-center" align="center">
            <v-progress-circular indeterminate color="primary"/>
          </div>
          <v-row>
            <v-col class="pb-0">
              <v-btn color="primary" @click="dialogVehicleTrips = false" :disabled="loadingDelete">
                Закрыть
              </v-btn>
            </v-col>
            <v-col class="pb-0" align="right">
              <v-btn
                class="white--text"
                color="red"
                @click="deleteTrips(selectedTripsVehicle)"
                :disabled="selectedTripsVehicle.length === 0"
                :loading="loadingDelete"
              >
                Удалить {{ selectedTripsVehicle.length }} рейс(-ов)
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogError" width="600px" persistent>
      <v-card>
        <v-card-title>
          Внимание
        </v-card-title>
        <v-card-text>
          {{ errorText }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="dialogError = false">Закрыть</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Service
import PlansService from "@/services/PlansService";
import VehicleService from "@/services/VehicleService";

// Component
import DepartureItem from "../../../DepartureItem";
import TripItem from "../../../TripItem";

// Mixin
import TripMixin from "../../../TripMixin";
import JetDialog from "@/components/JetDialog";
import JetSearchField from "@/components/JetSearchField";
import {dayOfWeekToFormat} from "@/services/JetDate";

export default {
  name: "TabTrips",
  components: {
    JetSearchField,
    JetDialog,
    DepartureItem,
    TripItem,
  },
  props: {
    // Выбранное ТС
    selectedVehicle: {
      type: Object,
      required: true,
    },
    // Выбранная дата
    fullDate: {
      type: Object,
      required: true,
    }
  },
  mixins: [
    TripMixin,
  ],
  data: () => ({
    // Диалог для планирования
    dialogPlanning: false,
    // Поиск маршрута
    searchRoute: '',
    // Маршруты
    routes: [],
    loadingRoutes: true,
    // Выбранный маршрут
    selectedRoute: null,
    // Список всех рейсов для выбранного ТС
    dialogVehicleTrips: false,
    vehicleTrips: [],
    selectAllVehiclePlan: false,
    loadingVehicleSchedules: true,
    selectedTripsVehicle: [],
    dayOfWeekToFormat: () => '',
  }),
  watch: {
    selectedVehicle() {
      this.loadRoutes();
    },
    fullDate() {
      this.loadRoutes();
      this.loadTrips();
    },
    selectedRoute(newValue) {
      if (newValue) {
        this.showTrips();
      } else {
        this.loadingSchedules = false;
        this.rightDialog = false;
      }
    },
    changed(newValue) {
      if (newValue) {
        this.loadRoutes();
        if (this.dialogVehicleTrips) {
          this.showTripsVehicle();
        }
        this.changed = false;
      }
    },
    selectAllVehiclePlan(newValue) {
      for (const departure of this.vehicleTrips) {
        departure.selected = newValue;
        for (const schedule of departure.schedules) {
          schedule.selected = newValue;
          if (newValue) {
            const index = this.selectedTripsVehicle.findIndex(item => {
              return item.scheduleId === schedule.scheduleId;
            });
            if (index === -1) {
              this.selectedTripsVehicle.push(schedule);
            }
          }
        }
      }
      if (!newValue) {
        this.selectedTripsVehicle = [];
      }
    }
  },
  computed: {
    filteredRoutes() {
      const regexp = new RegExp(`${this.searchRoute || ''}`, 'i');
      return this.routes.filter(route => regexp.test(route.routeName));
    },
  },
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.loadRoutes();
  },
  methods: {
    back() {
      this.$emit('back');
      this.rightDialog = false;
    },
    async loadTrips() {
      this.firstItem = null;
      this.isTwoVehicle = false;
      this.selectAll = false;
      this.selected = [];
      this.loadingSchedules = true;
      this.vehicles = await VehicleService.getIdAndGovnum(
        this.selectedVehicle.acid,
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
      );
      this.filteredVehicles = this.vehicles.slice();
      this.schedules = await PlansService.getTrips(
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
        this.selectedRoute?.routeId || '',
        this.vehicles,
      );
      this.loadingSchedules = false;
    },
    closeSelectTrip() {
      this.rightDialog = false;
      this.clearSelected();
    },
    async loadRoutes() {
      this.loadingRoutes = true;
      this.routes = [];
      const carriersToday = await PlansService.getPlans(
        this.$store.state.auth.subject.tenantId,
        $utils.formatDate(new Date(this.fullDate.iso), 'yyyy-MM-DD'),
      );
      this.routes = carriersToday.filter(item => {
        return item.carrierId === this.selectedVehicle.acid;
      });
      // Что бы при перезагрузке списка маршрутов из них выбирался
      // тот же маршрут
      if (this.selectedRoute) {
        this.selectedRoute = this.routes.filter(route => {
          return route.routeId === this.selectedRoute.routeId;
        })?.[0] || null;
      }
      this.loadingRoutes = false;
    },
    async showTrips() {
      this.rightDialog = true;
      await this.loadTrips();
    },
    planButton(schedules, plan = true) {
      if (schedules) {
        this.clearSelected();
        this.selected = schedules;
      }
      if (plan) {
        this.dialogPlanning = true;
      } else {
        this.stopPointDialog = true;
      }
    },
    // Нужна дополнительная проверка, на повтор ТС
    addVehicleExt(schedule) {
      let duplicated = false;
      for (const trip of schedule[0]?.trips || []) {
        if (trip.govnum === this.selectedVehicle.gov) {
          duplicated = true;
          break;
        }
      }
      if (duplicated) {
        jet.msg({
          text: 'Невозможно поставить ТС на тот же рейс',
          color: 'error'
        });
        return;
      }
      this.addVehicle(schedule);
    },
    async showTripsVehicle() {
      this.selectedTripsVehicle = [];
      this.dialogVehicleTrips = true;
      this.selectAllVehiclePlan = false;
      this.loadingVehicleSchedules = true;
      this.vehicles = await VehicleService.getIdAndGovnum(
        this.selectedVehicle.acid,
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
      );
      const schedules = await PlansService.getTrips(
        $utils.formatDate(new Date(this.fullDate.iso), this.DATE_FORMAT),
        null,
        this.vehicles,
        this.selectedVehicle.id,
      );
      if (schedules.length === 1 && schedules[0].schedules.length === 0) {
        this.vehicleTrips = [];
      } else {
        this.vehicleTrips = schedules;
      }
      this.loadingVehicleSchedules = false;
    },
  },
};
</script>

<style scoped lang="sass">
.border
  border: 1px solid black
  border-collapse: collapse
  border-radius: 4px
</style>

<style scoped>
.black--text /deep/ label {
  color: black;
}
</style>
