<template>
    <v-card class="jet-hello">
        <v-card-text>
            <v-row>
                <v-col cols="auto">
                    <v-avatar rounded="500" 
                              :class="{chief: has('chief')}">
                        <v-icon color="primary">mdi-account</v-icon>
                    </v-avatar>
                </v-col>
                <v-col class="jet-hello__hello">
                    {{ hello }}
                    <div class="text-muted">{{ org }}</div>
                </v-col>
                <v-col v-if="has('chief')" cols="auto">
                    <v-btn icon
                           :loading="pending"
                           v-on:click="_load">
                        <v-icon>mdi-reload</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <template v-if="has('chief')">
                <v-row class="registries">
                    <v-col cols="6">
                        <h3>Реестр межмуниципальных<br />маршрутов</h3>
                        <template v-if="registry.mmproj">
                            <v-btn small
                                   text
                                   color="grey"
                                   class="pl-0 mb-3"
                               v-on:click.stop.prevent="open('mmproj')"
                               :download="registry.mmproj.file">
                                проект от {{ formatDate(registry.mmproj.dt, "short") }}
                            </v-btn>
                            <br />
                            <v-btn small
                                   tile
                                   color="primary"
                                v-on:click.stop.prevent="confirm('mmproj')">
                                утвердить проект
                            </v-btn>
                        </template>
                    </v-col>
                    <v-col cols="6">
                        <h3>Реестр смежных<br />межрегиональных маршрутов</h3>
                        <template v-if="registry.mrproj">
                            <v-btn small
                                   text
                                   color="grey"
                                   class="pl-0 mb-3"
                                   v-on:click.stop.prevent="open('mrproj')"
                                   :download="registry.mrproj.file">
                                проект от {{ formatDate(registry.mrproj.dt, "short") }}
                            </v-btn> 
                            <br />
                            <v-btn small
                                   tile
                                   color="primary"
                                v-on:click.stop.prevent="confirm('mrproj')">
                                утвердить проект
                            </v-btn>
                        </template>
                    </v-col>
                </v-row>
                <v-row class="registries">
                    <v-col cols="6">
                        <v-btn v-if="registry.mmapr"
                               class="approved"
                               small
                               v-on:click.stop.prevent="open('mmapr')"
                               :download="registry.mmapr.file">
                           <v-icon small>mdi-checkbox-outline</v-icon>&nbsp;утвержденная версия от {{ formatDate(registry.mmapr.dt, "short") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="registry.mrapr"
                               class="approved"
                               small
                               v-on:click.stop.prevent="open('mrapr')"
                               :download="registry.mrapr.file">
                           <v-icon small>mdi-checkbox-outline</v-icon>&nbsp;утвержденная версия от {{ formatDate(registry.mrapr.dt, "short") }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="jet-hello__changes" v-if="has('audit')">
                    <v-col cols="12">
                        <v-icon small>mdi-clock-outline</v-icon>&nbsp;последние изменения: 
                        <a href="#" v-for="a in audit"
                           :key=" 'audit-' + a.attr"
                           v-on:click="openAudit(a)">
                            {{ a.name }}&nbsp;{{ formatDate(a.dt, "short") }}
                        </a>    
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
        <mt-audit-last ref="dlg" />
    </v-card>
</template>
<script>
import MtAuditLast from "./MtAuditLast";
import { formatDate } from "@/utils/utils";
import { getMtInfoAudi } from "@/services/MtInfo";

export default {
    name: "JetHello",
    components: {
        MtAuditLast
    },
    data(){
        return {
            pending: false,
            registry: {
                mmproj: null,
                mrproj: null,
                mmapr: null,
                mrapr: null
            },
            audit: null
        };
    },
    created(){
        this._load();
    },
    computed: {
        user(){
            return this.$store.state.profile.subject?.title || this.$store.state.profile.subject?.name;
        },
        org(){
            return this.$store.getters["profile/orgName"];
        },
        hello(){
            let s, h = new Date();
            h = h.getHours()*100 + h.getMinutes();
            if ( ( h > 500 ) && ( h < 1300 ) ){
                s = 'Доброе утро';
            } else if ( ( h > 1159 ) && ( h < 1900 ) ){
                s = 'Добрый день';
            } else if ( ( h > 1800 ) && ( h < 2359 ) ){
                s = 'Добрый вечер';
            } else {
                s = 'Добрый ночи';
            }
            s += ', ' + this.user;
            return s;
        }
    },
    methods: {
        has(q){
            switch(q) {
                case "audit":
                    return this.audit?.length > 0;
                case "chief":
                    return this.$store.getters['profile/hasrole']("1+\.+\s{0,}рук");
            }
            return false;
        },
        formatDate,
        _load(){
/*                
* target = "mm" - реестр межмуниципальных маршрутов
* target = "mr" - реестр межрегиональных маршрутов

* mode = 1 - создание реестра
* mode = 2 - проверка неутвержденного реестра
* mode = 3 - утверждение реестра
* mode = 4 - получение проекта реестра
* mode = 5 - получение утвержденного реестра
* 
*/
            this.registry.mmproj = null;
            this.registry.mrproj = null;
            this.registry.mmapr  = null;
            this.registry.mrapr  = null;
            this.pending = true;
            Promise.all([
                $http.get('/api/busreports/reestr?target=mm&mode=2'),
                $http.get('/api/busreports/reestr?target=mr&mode=2'),
                $http.get('/api/busreports/reestr?target=mm&mode=6'),
                $http.get('/api/busreports/reestr?target=mr&mode=6')
            ]).then( proms => {
                console.log('hello', proms);
                if (proms[0].success){
                    this.registry.mmproj = {
                        type: "mm",
                        dt: proms[0].date,
                        file: proms[0].name
                    };
                }
                if (proms[1].success){
                    this.registry.mrproj = {
                        type: "mr",
                        dt: proms[1].date,
                        file: proms[1].name
                    };
                }
                if (proms[2].success){
                    this.registry.mmapr = {
                        type: "mm",
                        dt: proms[2].date,
                        file: proms[2].name
                    };
                }
                if (proms[3].success){
                    this.registry.mrapr = {
                        type: "mr",
                        dt: proms[3].date,
                        file: proms[3].name
                    };
                }
                console.log('registries', this.registry);
                this.pending = false;
            }).catch(e => {
                this.pending = false;
                console.log('ERR(hello)', e);
            });
            if ( this.has("chief") ){
                getMtInfoAudi(0).then( res => {
                    console.log("changes", res);
                    this.audit = res;
                });
            }
        },   //load
        open(regi){
            let r = this.registry[regi];
            if ( !r ){
                return;
            }
            this.pending = true;
            fetch(`/api/busreports/reestr?target=${ r.type }&mode=${ /(proj)+/.test(regi) ? 4 : 5}`)
                .then( resp => resp.blob() )
                .then( blob => {
                    let url = URL.createObjectURL(blob);
                    let ref = document.createElement('a');
                    ref.href = url;
                    ref.download = r.file;
                    document.body.appendChild(ref);
                    ref.click();
                    document.body.removeChild(ref);
                    window.URL.revokeObjectURL(url);
                    this.pending = false;

            }).catch(e =>{
                this.pending = false;
            });
        },
        confirm(regi){
            let r = this.registry[regi];
            if ( !r ){
                return;
            }
            $http.get(`/api/busreports/reestr?target=${ r.type }&mode=3`).then( resp => {
                console.log('confirm', resp);
                if (resp.success){
                    jet.msg({text: `Проект реестра утвержден успешно ${ this.formatDate(resp.date, "DD.MM.YYYY HH:mm") }!`});
                    this._load();
                }
            });
        },
        openAudit(a){
            this.$refs["dlg"].open(a);
        }
    }
};
</script>
<style lang="scss">
    @import "@/styles/nav-head.scss";
    
    .jet-hello{
        &__hello{
            color: $blue;
        }
        & h3 {
            font-weight: 500;
            font-size: 0.9rem;
            margin-bottom: 1.5rem;
            color: $blue;
            background-color: $light-blue;
            height: 4rem;
            padding: 0.5rem;
            border-radius: 6px;
            text-transform: uppercase;
        }
        & .text-muted{
            color: grey;
            font-size: 0.75rem;
        }
        & .chief{
            &::after{
                display: block;
                position: absolute;
                width: 8px;
                height: 8px;
                left: 75%;
                top: 50%;
                border-radius: 8px;
                background-color: $orange;
                content: "";
                z-index: 1;
            }
        }
        & .v-btn.approved{
            background-color: $mid-blue;
            color: $blue;
            border: 1px solid darked($mid-blue, 20%);
        }
        &__changes {
            background: $light-blue;
            a {
                margin-left: 1rem;
                color: $blue;
                font-weight: 500;
                &:link{
                    text-decoration: none;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
            & .v-icon{
                color: $orange;
            }
        }
    }
</style>