<template>
    <v-card class="map-widget">
        <div id="map-widget"></div>
        <mt-stop-routes ref="routes" />
    </v-card>    
</template>
<script>
import * as DG from "2gis-maps";
import { isEmpty } from "@/utils";
import MtStopRoutes from '@/components/dev/dashboard/MtStopRoutes';

export default {
    name: 'MtMapWidget',
    components: {
        MtStopRoutes
    },
    data(){
        return {
            map: null
        };
    },
    mounted(){
        this.$nextTick(()=>{ this.initmap(); });
    },
    methods: {
        _getLayer(name){
            let res = null;

            const _get = layer => {
                if ( res ){
                    return;
                }
                if ( name === layer.options?.name){
                    res = layer;
                } else if (layer.eachLayer){
                    layer.eachLayer( l => _get(l) );
                }
            };

            this.map?.eachLayer( l => _get(l) );

            return res;
        },  //_getLayer
        _clean(name){
            const cleans = [],
                  badName = "xxx$$$zzz";
              
            this.map?.eachLayer( l => {
                let name = l.options?.name || badName;
                if ( l.options?.point ){
                    cleans.push( l );
                }
            } );

            if ( isEmpty(name) ){
                cleans.forEach( l => {
                    try {
                        if ( l.eachLayer ){
                            l.eachLayer( _l => this.map.removeLayer( _l ) );
                        }
                        this.map.removeLayer( l );
                    }catch(e) {}
                } );
            } else {
                cleans.filter( l => {
                    return (l.options?.name||badName).indexOf(name) >- 1;
                }).forEach( l => {
                    try {
                        if ( typeof l.eachLayer !== "undefined" ){
                            l.eachLayer( _l => this.map.removeLayer( _l ) );
                        }
                        this.map.removeLayer( l );
                    }catch(e) {}
                });
            }
        },  //_clean
        initmap(){
            const node = this.$el.querySelector("#map-widget");
            DG.Map.BoxZoom = void(0);
            DG.Map.addInitHook("addHandler","boxZoom",void(0));
            DG.then(()=>{
                const brget = this.$store.getters["branding/get"];
                const ll    = {
                    lat: brget("brand.map.lat"),
                    lon: brget("brand.map.lon")
                };
                if ( isEmpty(ll.lat) ){
                    ll.lat = 45.04484;
                    ll.lon = 38.97603;
                }
                
                try {
                    let map = new DG.Map(node, {
                        zoom: 13,
                        maxZoom: 22,
                        boxZoom: 0,
                        zoomControl: false,
                        poi: false,
                        center: ll
                    });

                    console.log('2gis(map)', map);

                    let fsc = document.querySelector(".leaflet-control-container .dg-control-round__icon_name_fullscreen");
                    if (fsc){
                        fsc.parentNode.style = "display: none";
                    }
                    
                    map.on('click', e => {
                        e.originalEvent.preventDefault();
                        e.originalEvent.stopPropagation();
                        this.nearests(e);
                        return false;
                    });
                    
                    setTimeout(function(){
                        map.invalidateSize({debounceMoveend: true});
                    }, 4000);
                    
                    this.map = map;

                } catch(e){
                    console.log('ERR(map)', e);
                }
            });
        },
        nearests( e ){
            const _MARKER_ID = 'map-chooser',
                  _GROUP_ID  = 'map-nearests';

            this._clean(_GROUP_ID);
            if ( !e ) {
                this.clean(_MARKER_ID);
                return;
            }

            const latlng = e?.latlng || this.map.getCenter();
            let marker = this._getLayer(_MARKER_ID);
            if ( !marker ){
                marker = new DG.marker(latlng, {
                                                name: _MARKER_ID,
                                                zIndexOffset: 1100,
                                                riseOnHover: true,
                                                draggable: true,
                                                index: -1,
                                                point: latlng,
                                                icon: new DG.divIcon({
                                                    className: "map-point nearest",
                                                    iconAnchor: [15, 15],
                                                    html: `<b class="mdi mdi-map-marker-radius-outline"></b>`
                                                })
                        });
                this.map.addLayer(marker);
            } 
            marker.setLatLng(latlng);
            let params = new URLSearchParams();
            params.append("point", `${latlng.lat},${latlng.lng}`);
            params.append("radius", 300);
            params.append("dateWork", (new Date()).getTime());
            params.append("language", "ru");

            $http.get(`/api/v1/stops/nearest?${ params.toString()}`,{
                dataType: 'json',
                contentType: 'application/json;charset=utf-8'
            }).then( res => {
                    res.forEach( r => {
                        const m = new DG.marker([r.lat, r.lon], {
                                        id: r.id,
                                        point: r,
                                        title: r.name,
                                        name: _GROUP_ID,
                                        zIndexOffset: 1099,
                                        riseOnHover: true,
                                        draggable: false,
                                        index: -1,
                                        icon: new DG.divIcon({
                                                    className: "map-point",
                                                    iconAnchor: [15, 15],
                                                    html: `<b class="mdi mdi-bus-stop" style="background:#2196F3"></b>`
                                        })
                                });
                        this.map.addLayer(m);
                        m.bindLabel(r.name, { static: true });
                        m.showLabel();
                        m.on('click', e => {
                            let pt = e.target?.options?.point;
                            if ( pt ){
                                this.$refs["routes"].open(pt);
                            }
                        });
                    });
                }).catch(e => {
                    $jet.msg({text: `Не удается определить наличие ОП по выбранным координатам ${ latlng.lng } / ${ latlng.lat }: <small>${ e.message }</small>`, color: "warning"});
                    console.log('ERR (nearest)', e);
                });
        }   //nearests
    }
}    
</script>
<style lang="scss">
    @import "@/styles/nav-head.scss";
    
    .map-widget{
        height: 100%;
        min-height: 320px;
        & #map-widget {
            height: 100%;
            max-height: 1024px;
            & .map-point {
                line-height: 1;
                & b {
                    display: block;
                    width: 28px;
                    height: 28px;
                    line-height: 26px;
                    border: 2px solid #fff;
                    border-radius: 50%;
                    background-color: $blue !important;
                    color: #fff;
                    opacity: 0.75;
                    text-align: center;
                    & .mdi{
                        font-size: 13px;
                    }
                }
                &.nearest{
                    & b{
                        background-color: #d50000 !important;
                    }
                }
                
            }
            & .dg-label{
                &__content{
                    background-color: #FFECB3;
                    border-color: #FFF8E1;
                    color: #3E2723;
                    box-shadow: 0 2px 2px rgba(0,0,0,0.33);
                }
            }
        }
    }
</style>