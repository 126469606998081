<template>
    <v-data-table class="jet-table pln-routes"
                  single-select
                  fixed-header
                  :items-per-page="30"
                  :headers="headers"
                  :items="routes"
                  :loading="loadingRoutes"
                  :search="search"
                  v-on:click:row="showTrips">
        <template v-slot:top>
            <v-toolbar dense
                       flat
                       elevation="0">
                <JetSearchField label="Найти маршрут по номеру, названию или перевозчику"
                                v-model="search" />
                <v-spacer />
                <div class="grey--text text-right">выберите маршрут для планирования</div>
            </v-toolbar>
        </template>
        <template v-slot:item.routePlan="{ item }">
            {{ item.routePlan }}
            <v-chip v-if="item.autoplan" 
                    x-small 
                    label
                    dark
                    class="ml-2"
                    color="#d50000">A</v-chip>
        </template>    
    </v-data-table>
</template>

<script>
// Сервисы
import PlansService from '@/services/PlansService';

// Общие компоненты
import JetSearchField from '@/components/JetSearchField';

export default {
  name: 'TabRoute',
  props: {
    // Выбранная дата
    fullDate: {
      type: Object,
      required: true,
    },
  },
  components: {
    JetSearchField,
  },
    data: () => ({
      routes: [],
      loadingRoutes: true,
      search: '',
      headers: [
        { text: 'Маршрут', value: 'routeName' },
        { text: 'Перевозчик', value: 'carrierName' },
        { text: 'Планирование', value: 'routePlan', align: "end" }
      ],
    }),
    computed: {
        tenantId(){
            return this.$store.state.auth.subject.tenantId;
        }
    },
    created() {
          this.loadRoutes();
    },
    methods: {
      async loadRoutes() {
        if (this.fullDate.iso) {
          this.loadingRoutes = true;

          try {
              this.routes = await PlansService.getPlans(this.tenantId, this.fullDate.iso);
          } catch (e) {
              console.log('ERR(routes)', e);
              this.routes = [];
              jet.msg({
                color: 'warning',
                text: `Не удалось загрузить данные о планировании:<br /><small>${ e.message } ${ e.data|| '' }</small>`
              });
          } finally {
              this.loadingRoutes = false;
          }
        }
      },
      showTrips(route) {
          this.$emit('selectRoute', route);
      }
    },
    watch: {
      fullDate() {
            this.loadRoutes();
      }
    }
};
</script>
<style lang="scss" scoped>
    .pln-routes{
        height: calc(100vh - 220px);
        & .v-toolbar{
            margin-bottom: 0.5rem;
        }
    }
</style>