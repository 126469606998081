/** 
 * BroadcastChannel - communication (see getFavs)
 * @type BroadcastChannel
 */

let bc = null;

const state = {
    navs: [],
    favs: [],
    setts: null,
    modes: {
        dashboard: 0
    }
};

const mutations = {
    setFavs(state, favs){
        state.favs = favs;
    },
    setNavs(state, navs){
        state.navs = navs;
    },
    setSetts(state, setts){
        if (setts){
            state.setts = setts;
        }
    },
    clearSetts(state) {
        state.setts = null;
        jet.worker?.postMessage({type:"save", name: "settings", data: null});
    },
    setSnackbar(state, inf){
        //console.log('depricated');
        jet.msg(inf);
    },
    mode(state, mode){
        if (mode.dashboard){
            state.modes.dashboard = mode.dashboard;
        }
    }
};

const actions = {
    async getNavs({ commit, state }){
        if (state.navs.length > 0){
            return state.navs;
        }
        
        let navs = [];
        
        try {
            const data = await $http.post('/rpc?d=jsonRpc', {
                            type: 'tree-childs',
                            query: 'sin2:/v:fcfbd587-1b85-4bfb-aaf0-1258f4ff88dc'
                  });
            if (data.result){
                const reView  = /^(sin2:\/v:)+/;
                function hasView(item){
                    if (item.isView) {
                        return true;
                    }
                    let res = false;
                    if (item.children){
                        item.children.forEach( child => {
                            res = res || hasView(child);
                        });
                    }
                    return res;
                };
                function normalize(items, l){
                    let res = [];
                    items.forEach( item => {
                        let _item = {
                            id: item.id,
                            name: item.name,
                            uri: item.path,
                            isView: reView.test(item.path),
                            lvl: l,
                            hasChilds: false
                        };
                        if ( item.childs ) {
                            _item.children = normalize(item.childs, l + 1);
                            _item.hasChilds = _item.children?.length > 0;
                        }
                        res.push(_item);
                    });
                    return res;
                };
                        
                navs = normalize(data.result, 0).filter( item => hasView(item) );
                
                commit("setNavs", navs);
                
            }
        } catch(e){
            console.log('ERR(nav)', e);
        }
        return navs;
    },
    async getFavs({ commit, state }, id){
        return new Promise( (resolve, reject) => {
            try {
                if ( !bc ){
                    bc = jet.worker;
                    bc.addEventListener("message", e => {
                        console.log("message", e);
                        if (e.data.favorittes?.length > 0){
                            commit("setFavs", e.data.favorittes);
                        }
                    });
                    bc.postMessage({type:"read", name: "favorittes"});
                }
                if ( $utils.isEmpty(id) ){
                    resolve();
                } else {
                    let f;
                    if ( state.favs.length > 0 ){
                        f = state.favs.filter( f => f.id === id ).at(0);
                    }
                    resolve(f);
                }
            } catch(e){
                console.log('ERR(favs)', e);
                reject(e);
            }
        });
    },
    
    async setFav({ commit, state }, fav){
        return new Promise( (resolve, reject) => {
            if ( !bc ){
                reject({message: 'No messages-channel'});
                return;
            }
            let favs = state.favs;
            let n = favs.findIndex( f => f.id === fav.id );
            if ( n < 0 ){
                favs.push(fav);
            } else {
                favs.splice(n, 1);
            }
            bc.postMessage({type:"save", name: "favorittes", data: favs});
            commit("setFavs", favs);
            resolve( (n < 0) ? true : false);
        });
    },
    
    /**
     * Get a local saved settings
     * @param {Function} commit 
     * 
     */
    async getSetts({ commit }){
        
        return new Promise((resolve, reject)=>{
            if ( !jet.worker ){
                reject({message: 'No worker'});
                return;
            }
            
            const _on_setts = e => {
                if ( "read"===e.data?.type ) {
                    console.log("read (settings)", e);
                    if ( Object.hasOwn(e.data, "settings") ){
                        commit("setSetts", e.data.settings);
                        jet.worker.removeEventListener("message", _on_setts);
                        resolve();
                    }
                }
            };

            jet.worker.addEventListener("message", _on_setts);
            jet.worker.postMessage({type:"read", name: "settings"});
            
            setTimeout(()=>{
                reject({message: 'timeout for settings reading'});
            }, 2000);
        });
    },   //getSetts
    
    setSetts({ state, commit }, data){
        
        const settings = (state.setts) ? {...state.setts} : {};
        settings.at = new Date();
        
        if (data){
            Object.keys(data).forEach( k => {
                settings[k] = data[k];
            });
        }

        commit("setSetts", settings);
        
        jet.worker?.postMessage({type:"save", name: "settings", data: settings});
    }
};      

const getters = {
    favoritted: state => id => {
        return state.favs.findIndex( f => f.id === id ) > -1;
    },
    settings: state => q => {
        return state.setts 
                    ? q ? state.setts[q] : state.setts
                    : null;
    }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
