<template>
    <div>
        <v-app-bar app clipped-left flat 
                   color="primary" 
                   light dark 
                   class="jet-app-bar">
            <v-btn text icon @click="$emit('navshow')">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <div class="jet-left-header">
                <v-btn v-if="has('tenants')"
                       @click="$emit('tenants')"
                       text>
                    <template v-slot:default>
                        <div class="jet-tenant__conte">
                            <div class="jet-tenant__name">
                                {{ appName }}
                            </div>
                            <v-icon>mdi-chevron-down</v-icon>
                        </div>
                    </template>
                </v-btn>
                <template v-else>
                    {{ appName }}
                </template>
            </div>
            <div class="mt-search">
                <mt-searcher variant="underline" 
                             dark />
            </div>    
            <v-spacer></v-spacer>
            <template v-if="has('chief')">
                <v-btn icon
                       class="mr-3"
                       v-on:click="$emit('headneed')">
                    <v-tooltip bottom
                               color="#FFF9C4">
                        <template v-slot:activator="{ on }">
                              <v-icon v-on="on"
                                      class="chief">
                                  mdi-account-tie-outline
                              </v-icon>
                        </template>      
                        <span style="color:#666">этот аккаунт является административным</span>
                    </v-tooltip>
                </v-btn>    
            </template>    
            <div class="right-header">{{ userName }}</div>
            <v-btn class="jet-notify-btn" text icon title="Уведомления" @click="dialogNotification = !dialogNotification">
              <v-badge :value="countNotification" overlap dot color="red">
                <jet-svg width="18" height="18" xref="#ico-bell" />
              </v-badge>
            </v-btn>
            <v-btn text icon title="Выход" @click="logout">
              <jet-svg width="18" height="18" xref="#sign-out"/>
            </v-btn>
        </v-app-bar>
        <NotificationShort
          ref="notificationDrawer"
          :show="dialogNotification"
          @close="dialogNotification = false"
        ></NotificationShort>
    </div>
</template>

<script>
import JetSvg from '@/components/JetSvg';
import JetSearchField from "@/components/JetSearchField";
import NotificationShort from "./dev/modules/notifications/components/NotificationShort";
import OtherService from "@/services/OtherService";
import MtSearcher from '@/components/dev/MtSearcher';

var WS_URI = {
    servers: process.env.VUE_APP_BACKEND_NATS_SERVER,
    user: process.env.VUE_APP_BACKEND_NATS_USERNAME,
    pass: process.env.VUE_APP_BACKEND_NATS_PASSWORD
};

const _TM_NOTIFY = 5*60*1000;

export default {
  name: 'JetAppBar',
  components: {
    JetSvg,
    JetSearchField,
    NotificationShort,
    MtSearcher
  },
  data: () => ({
    dialogNotification: false,
    countNotification: 0,
    atNotify: 0,
    codec: null,
    nats: null,
    sids: [],
    search: '',
  }),
  computed: {
    userName() {
      return this.$store.getters['profile/getName'];
    },
    appName() {
      let s = this.$store.getters['profile/orgName'];
      return $utils.isEmpty(s)
        ? this.$store.getters['branding/get']('brand.client.name')
        : s;
    },
  },
  created() {
    this.getUnreadCount();
    setTimeout(this.connectNats, 10000);
  },
  destroyed() {
    this.disconnectNats();
  },
  methods: {
    has(q){
        switch(q) {
            case "tenants":
                const s = this.$store.getters['profile/subject'];
                if (
                        (!!s)
                     && (!!s.availableTenants)
                   ) {
                    return Object.keys(s.availableTenants).length > 0;
                }
                return false;
            case "chief":
                return this.$store.getters['profile/hasrole']("1+\.+\s{0,}рук");
        }
        return false;
    },
    logout() {
      this.$store.dispatch('profile/logout');
    },
    async getUnreadCount() {
        const at = (new Date()).getTime();
        if ((at - this.atNotify) < _TM_NOTIFY) {
            return; //don`t quering by error
        }
        this.atNotify = at;
        try {
            this.countNotification = await OtherService.getUnreadCount();
        }catch(e){
            console.log('ERR (countNotification)', e);
            this.countNotification = -1;
        }
    },
    async connectNats() {
      if ( !(!!this.nats) ){
          try {
                this.codec = await jet.http.getJSONCodec();
                this.nats = await jet.http.getNats(WS_URI);
                const userId = this.$store.state.auth.subject.id;
                const s = this.nats.subscribe('PUBLIC.kigat.eventMessaging.count.' + userId);
                ( async() => {
                  for await (const m of s) {
                    try{
                      this.onNatsMessageReceive( this.codec.decode(m.data) );
                    } catch(e){
                      console.log('ERR on_event:', e);
                    }
                  }
                } )();
                this.sids.push(s);
            } catch(e){
                console.log('ERR on nats', e);
            }
        }
    },  //async connectNats
    disconnectNats() {
      if (!!this.nats) {
            try {
                this.sids.forEach(s => s.unsubscribe());
                this.sids = [];
                this.nats.close();
            } catch(e){}
            finally{
                this.nats = null;
            }
      }
    },
    onNatsMessageReceive(msg) {
      this.getUnreadCount();
      this.$refs.notificationDrawer.newExist = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.jet-app-bar {
    & .v-input {
      font-size: 0.8rem;
    }
    & .v-btn{
        letter-spacing: unset;
        & .jet-tenant{
            &__name{
                max-width: 20rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 0.5rem;
            }
            &__conte {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
            }    
        }
    }
    & .v-icon.chief{
        opacity: 0.7;
        &::after{
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            left: 90%;
            border-radius: 6px;
            background-color: #ffcc00;
            content: "";
            opacity: 1;
        }
    }

    & .jet-left-header {
        max-width: 30rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
</style>
