import JetTable from '@/components/JetTable';

export const VehiclesAll = {
    extends: JetTable,
    data(){
        return {
            all: false
        };
    },
    computed: {
        uri(){
            if ( this.all ){
                return `sin2:/v:5732a720-3935-4e6f-999d-0624a123dcf0/?sort=vcVehicle.startdt`;
            } else {
                return `sin2:/v:6857aebe-2248-4e72-a9ec-918d5e722cf6/?filter=and(lte(field(".startDt"),var("util.date.truncToDay(dateEnd)")),
                                        or(isnull(field(".endDt")),
                                           gte(field(".endDt"),var("util.date.truncToDay(dateBegin)"))
                                       ))`;
            }
        }
    },
    methods: {
        _reload(){
            this.all = !this.all;
            
            let p = this.model.projects[this.model.projects.length - 1];
            p.name = this.all ? 'Показать актуальные' : 'Показать ВСЕ';
            
            this.$parent.collectionInfo.uri = this.uri;
            this.$parent.refresh();
            
        },  //_reload
        onBeforeLoad(model){
            console.log('vehicles (model/ci)', model, this.$parent.collectionInfo);
            if ( !this.$parent.collectionInfo?.master ){
                console.log('No master');
                return;
            }
            
            if ( model.projects.findIndex(p=>'vehiclesall'===p.id) < 0 ){
                
                model.projects.push({
                    id: 'vehiclesall',
                    isAction: true,
                    type: 'typeJsClientHtml',
                    name: 'Показать ВСЕ',
                    resource: {
                        attrs: {mimeType: 'text/javascript'}
                    },
                    call: this._reload
                });
                
                let p = this.$parent;
                while( p ){
                    if (p.$refs?.toolbar){
                        p.$refs.toolbar.use_actions(model.guiActionHtml);
                        //p.$refs.toolbar.$forceUpdate();
                        break;
                    }
                    p = p.$parent;
                }
            }
        }
    }
};
