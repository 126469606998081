<template>
  <div class="px-4" :key="keyComponent">
    <!-- Импорт из Excel -->
    <JetDialog
      title="Выберите файл для загрузки"
      width="600"
      v-if="showExcelImportDialog"
      v-model="showExcelImportDialog"
    >
      <template #default>
        <v-layout column>
          <v-flex class="my-2">
            <v-file-input
              clearable hide-details
              class="pt-0 mr-2"
              ref="importExcelField"
              placeholder="Из Excel"
              :loading="fileUploading"
              :disabled="fileUploading"
              @change="importFromExcel"
              @click:clear="clearImportFromExcel"
            ></v-file-input>
          </v-flex>

          <v-flex>
            <v-data-table
              show-select
              no-data-text="Загрузите файл для выбора листов"
              item-key="name"
              v-model="sheetTable.selected"
              :headers="sheetTable.headers"
              :items="sheetTable.items"
            ></v-data-table>
          </v-flex>
        </v-layout>
      </template>

      <template #actions>
        <v-spacer/>

        <v-btn
          color="primary"
          :disabled="sheetTable.selected.length === 0 || fileUploading"
          @click="importFromExcel(null)"
        >
          Отправить
        </v-btn>

        <v-btn
          outlined
          color="primary"
          :disabled="fileUploading"
          @click="showExcelImportDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </JetDialog>

    <!-- Диалог для показа "лога ошибок" после импорта -->
    <JetDialog
      title="Лог импорта из Excel"
      v-model="excelImportLogDialog"
      :persistent="true"
    >
      <template v-slot:default>
        <div
          style="overflow: auto; max-height: 400px;"
          v-html="excelImportLogData || 'Нет данных'"
        ></div>
      </template>

      <template v-slot:actions>
        <v-spacer/>

        <v-btn color="primary" @click="excelImportLogDialog = false">
          Закрыть
        </v-btn>
      </template>
    </JetDialog>

    <!-- Диалог для показа удаления постановок -->
    <JetDialog
      persistent
      title="Удалить следующие рейсы?"
      width="1200"
      applyButtonText="Сохранить"
      v-if="showRemoveTripsDialog"
      v-model="showRemoveTripsDialog"
      @on-apply-click="onRemoveTripsSaveClick"
      @on-cancel-click="onRemoveTripsCancelClick"
    >
      <template #default>
        <v-data-table
          show-select
          item-key="id"
          v-model="removeTripsTable.selected"
          :headers="removeTripsTable.headers"
          :items="removeTripsTable.items"
        ></v-data-table>
      </template>
    </JetDialog>

    <!-- AppBar Планирования -->
    <v-row>
      <!-- Табы -->
      <v-col cols="3">
        <v-tabs
          v-model="mainTab"
        >
          <v-tab>Рейсы</v-tab>
          <v-tab>Транспорт</v-tab>
        </v-tabs>
      </v-col>

      <!-- Дата планирования -->
      <v-col cols="3">
        <CalendarDatePlanning
          @set-date="setDate"
        ></CalendarDatePlanning>
      </v-col>

      <!-- Импортировать -->
      <v-col v-if="showImport" align="right" cols="6" class="d-flex justify-end">
        <span class="px-1">
          <v-btn text @click="showExcelImportDialog = true"
                 small
                 elevation="1">
              импортировать из Excel&nbsp;<v-icon small>mdi-file-table-outline</v-icon>
          </v-btn>
        </span>

        <!-- С другого дня -->
        <!-- WDQZ-1255 Скрыть возможность копирования -->
        <ImportDialog
          v-if="false"
          :selected-date="fullDate"
          @refresh="keyComponent = !keyComponent"
        ></ImportDialog>
      </v-col>
    </v-row>

    <v-tabs-items v-model="mainTab">

      <!-- Страница РЕЙСЫ -->
      <v-tab-item>
        <MainTabPlanning
          v-if="mainTab === 0"
          :fullDate="fullDate"
          @hideImport="hideImport"
        ></MainTabPlanning>
      </v-tab-item>

      <!-- Страница ТРАНСПОРТ -->
      <v-tab-item>
        <MainTabVehicles
          v-if="mainTab === 1"
          :fullDate="fullDate"
        ></MainTabVehicles>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// Компоненты
import JetDialog from '@/components/JetDialog';

// Сервисы
import PlansService from '@/services/PlansService';

// Calendar
import CalendarDatePlanning from './components/calendar/CalendarDatePlanning';
import ImportDialog from './components/calendar/ImportDialog';

// Tab
import MainTabPlanning from './components/modules/MainTabPlanning/MainTabPlanning';
import MainTabVehicles from './components/modules/MainTabVehicles/MainTabVehicles';

// Миксины
import HideGlobalToolbarMixin from '@/components/dev/mixin/HideGlobalToolbarMixin';

let _currentFile = null;
let _loadedRoutes = {};
let _haveUserAnswer = false;

export default {
  mixins: [
    HideGlobalToolbarMixin,
  ],
  components: {
    MainTabVehicles,
    MainTabPlanning,
    ImportDialog,
    CalendarDatePlanning,
    JetDialog,
  },
  name: 'Planning',
  data: () => ({
    mainTab: 0,
    fullDate: {
      iso: null,
      format: null,
    },
    // Ключ для обновления страницы
    keyComponent: true,
    // Флаг что происходит импорт Из Excel
    fileUploading: false,
    // Флаг показа диалога на импорт постановок
    showExcelImportDialog: false,
    // Флаг показа диалога удаления постановок
    showRemoveTripsDialog: false,
    // Флаг показа лога импорта
    excelImportLogDialog: false,
    // Данные лога
    excelImportLogData: '',
    //
    showImport: true,
    // Настройка таблицы выбора имен листов
    sheetTable: {
      // Имена листов
      items: [],
      // Заголовки
      headers: [
        { text: 'Наименование', value: 'name' },
      ],
      // Выбранные имена листов
      selected: [],
    },
    // Настройка таблицы удаления постановок
    removeTripsTable: {
      // Массив постановок
      items: [],
      // Заголовки
      headers: [
        { text: '№', value: 'pos' },
        { text: 'Маршрут', value: 'routeName' },
        { text: 'Рейс', value: 'code' },
        { text: 'Гос. номер', value: 'vehicle' },
      ],
      // Выбранные постановки
      selected: [],
    },
  }),
  watch: {
    mainTab(newValue) {
      this.showImport = newValue === 0;
    },
    showExcelImportDialog(val) {
      if (!val) {
        _currentFile = null;

        this.sheetTable.selected = [];
        this.sheetTable.items = [];

        setTimeout(() => {
          // this.$refs['importExcelField'].reset();
          this.showExcelImportDialog = false;
        }, 200);
      }
    },
  },
  mounted() {
    // Загрузка списка маршрутов
    PlansService.loadRoutes()
      .then(data => {
        (data || []).forEach(it => {
          it['_routeName'] = `${it.routecode} ${it.routename}`;

          _loadedRoutes[it.id] = it;
        });
      })
      .catch(err => {
        _err('Planning::PlansService.loadRoutes', err);

        jet.msg({
          color: 'warning',
          text: 'Не удалось загрузить список маршрутов',
        });
      });
  },
  methods: {
    setDate(date) {
      if (date && date.iso !== this.fullDate.iso) {
        this.fullDate = date;
      }
    },
    hideImport(hide) {
      this.showImport = !hide;
    },
    clearImportFromExcel() {
      _currentFile = null;

      this.sheetTable.selected = [];
      this.sheetTable.items = [];

      this.removeTripsTable.selected = [];
      this.removeTripsTable.items = [];

      _haveUserAnswer = false;

      this.showExcelImportDialog = true;
    },
    // Импорт постановок из файла
    importFromExcel(file) {
      if (file == null && _currentFile !== null) {
        file = _currentFile;
      }

      if (file != null) {
        _currentFile = file;

        const fileName = 'file.' + file.name.substr(file.name.lastIndexOf('.') + 1);

        const fd = new FormData();
        fd.append('file', file, fileName);
        fd.append('_removeTripsIds', 'Dsdshjfbsckbs');

        this.fileUploading = true;

        let url = `/fileParser?parser=trips&_uansw=${_haveUserAnswer}`;

        if (this.sheetTable.selected.length) {
          const sNames = this.sheetTable.selected
            .map(it => it.name)
            .join(',');

          url += `&_selectedSheetNames=${sNames}`;
        }

        if (_haveUserAnswer) {
          const ids = this.removeTripsTable.selected
            .map(it => it.id)
            .filter(it => it != null)
            .join(',');

          fd.append('_removeTripsIds', ids);
        }
        
        const token = jet.$store.getters["profile/token"],
              opts  = {
                            method: 'POST',
                            credentials: 'include',
                            body: fd,
                            headers: {
                              'X-File-Name': fileName
                            }
              };
        if ( token?.token ){
            opts.headers["Authorization"]=`Bearer ${ token.token }`;
        }

        fetch(url, opts)
                .then(response => response.json())
                .then(this._onTripsParsed)
                .catch(err => {
                  console.error('Planning::Upload excel file', err);
                  jet.msg({
                    text: `Произошла ошибка на сервере:<br /><small>${ err.message } ${ err.data || '' }</small>`,
                    color: 'error'
                  });
                })
                .finally(() => {
                  this.fileUploading = false;
                });
      } else {
        _currentFile = null;

        this.sheetTable.selected = [];
        this.sheetTable.items = [];

        this.showExcelImportDialog = true;
      }
    },
    // При сохранении удаляемых рейсов
    onRemoveTripsSaveClick() {
      _haveUserAnswer = true;

      this.showRemoveTripsDialog = false;

      this.importFromExcel(_currentFile);
    },
    // При отмене удаления рейсов
    onRemoveTripsCancelClick() {
      _haveUserAnswer = true;

      this.removeTripsTable.selected = [];
      this.showRemoveTripsDialog = false;

      this.importFromExcel(_currentFile);
    },
    // Обработка ответа парсинга файла постановок
    _onTripsParsed(res) {
      this.excelImportLogData = '';

      const success = res?.success || false;

      if (!success) {
        jet.msg({
          text: 'Запрос завершился неудачно',
          color: 'warning',
        });

        _currentFile = null;

        return;
      }

      const data = res?.data || {};

      // Пришли имена листов
      if (data.sheet_names !== undefined) {
        this.sheetTable.items = (data.sheet_names || []).map(it => ({ name: it }));
        this.showExcelImportDialog = true;

        return;
      }

      // Необходимо отобразить диалог на удаление постановок
      if (data.need_delete !== undefined && data.need_delete) {
        let pos = 0;

        this.removeTripsTable.items = (data.to_delete?.to_delete || []).map(it => {
          it.pos = ++pos;
          it.routeName = _loadedRoutes[it.routeName]?._routeName || 'Нет маршрута';

          return it;
        });

        this.showRemoveTripsDialog = true;

        return;
      }

      this.showExcelImportDialog = false;

      if (data.error !== undefined && data.error !== '') {
        this.excelImportLogData += 'Ошибки импорта: <br><br>' + data.error.split('\n').join('<br/>');
        this.excelImportLogDialog = true;
      }

      if (data.saved !== undefined) {
        this.excelImportLogData += `Сохранено: ${data.saved}<br/>`;
        this.excelImportLogDialog = true;

        _currentFile = null;

        jet.msg({
          text: `Успешно добавлено ${data.saved} записей!`,
          color: 'primary',
        });
      }

      if (data.saveInfo !== undefined && data.saveInfo !== '') {
        this.excelImportLogData += `<br/><hr/>` + data.saveInfo.split('\n').join('<br/>');
        this.excelImportLogDialog = true;

        _currentFile = null;
      }

      const oldTab = this.mainTab;
      this.mainTab = -1;

      this.$nextTick(() => {
        this.mainTab = oldTab;
      });
    },
  },
  computed: {
    sheetCountItems() { // TODO: remove
      return this.sheetTable.items.length;
    },
  },
};
</script>
