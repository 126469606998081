<template>
    <v-dialog v-model="show"
              scrollable
              max-width="720px"
              content-class="mt-rt-choose">
        <v-card :loading="routesLoading||(mode === MODES.loading)">
            <v-toolbar tile>
                <v-autocomplete label="маршрут"
                                clearable
                                ref="routeInp"
                                v-model="route"
                                name="route"
                                :items="routes"
                                hide-details
                                hide-no-data
                                hide-selected
                                item-text="routeName"
                                item-value="id"
                                :filter="routesFilter"
                                return-object
                                v-on:input="selRoute">
                    <template v-slot:selection="{ item }">
                        {{ item.routeCode }}. {{ item.routeName }}
                    </template>    
                    <template v-slot:item="{ item, on, attrs }">
                        <v-list-item class="mt-route-item"
                                     v-on="on"
                                     v-bind:attrs="attrs">
                            <v-list-item-avatar>
                                {{ item.routeCode }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.routeName }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    <span v-if="item.verStatusName" 
                                          :class="{'mr-3': true, 'font-weight-bold': /^(утв)+/i.test(item.verStatusName)}">
                                        {{ item.verStatusName }}
                                    </span>    
                                    {{ item.carriers || '' }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-autocomplete>
                <v-spacer />
                <v-btn v-on:click="show=false"
                       icon>
                    <v-icon small>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-if="!has('route')" class="py-5">
                <v-alert border="left"
                         colored-border
                         color="primary"
                         elevation="2">
                    <template v-if="routesLoading">
                        Загрузка списка маршрутов...
                    </template>    
                    <template v-else>
                        Выберите маршрут из списка...
                    </template>    
                </v-alert>
            </v-card-text>
            <v-card-text v-else-if="has('vers')">
                <h2>Версии расписаний {{ route.routeCode }}.&nbsp;{{ route.routeName }}</h2>
                <v-list class="mt-rt-versions">
                    <v-list-item v-for="v in vers"
                                 :key="v.vcreleaseschedulesId"
                                 v-bind:class="{approved: has('approved', v), lost: has('lost', v)}"
                                 v-on:click="selVersion(v)">
                        <v-list-item-avatar>
                            {{ v.vcreleaseschedulesVersion }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ v.vcreleaseschedulesVerstatusName }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ fmt(v.vcreleaseschedulesVerstart) }}
                                {{ !!v.vcreleaseschedulesVerend ? ' - ' + fmt(v.vcreleaseschedulesVerend) : '' }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mt-rt-meta">
                                маршрут: {{ v.sVerstatusName }}
                                &nbsp;вер.№&nbsp;<b>{{v.sVersion}}</b>&nbsp;{{ fmt(v.sVerstart) }}
                                {{ !!v.sVerend ? ' - ' + fmt(v.sVerend) : '' }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { MODES, isEmpty } from '@/utils';
import Model from '@/core/Model';
const $moment = require('moment');

const SIN_ROUTES_URI = `sin2:/v:d122d351-3e3e-4983-a73c-199f0fa1bed5?filter=
                        and(
                                exists(\"24b28908-8b07-4840-b9a9-995593478876\",
                                    \"and(
                                            eq(field(\\\".verId\\\"),super.field(\\\".verId\\\")),
                                            or(
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Утвержден\\\", \\\"string\\\")),
                                              eq(field(\\\".verStatus.Name\\\"),param(\\\"Проект\\\", \\\"string\\\"))
                                            )
                                          )\"
                                    ),
                                eq(field(\".isActiveRoute\"), param(true,\"boolean\"))
                        )&fields=.id,.verId,.routeCode,.routeName,.verStatus.Name,.carriers`;
export default {
    name: 'RouteSelVersion',
    data(){
        return {
            MODES,
            SIN_ROUTES_URI,
            mode: MODES.none,
            show: false,
            routes: null,
            routesLoading: false,
            route: null,
            vers: null
        };
    },
    computed: {
        routeId(){
            return this.route?.id || this.route?.vcroutesId;
        },
    },
    methods: {
        async $fetch(){
            if ( this.routes?.length > 0 ){
                return;
            }
            this.routesLoading = true;
            try {
                const routes = await $http.post({
                            type: 'core-read',
                            transform: true,
                            query: SIN_ROUTES_URI 
                });
                //for backward's
                routes.forEach( r => {
                    r.vcroutesId = r.id;
                    r.vcroutesVerid = r.verID;
                    r._num = r.routeCode ? Number(r.routeCode.replace(/\D/g, '')) : 0;
                });
                this.routes = routes.sort( (r1, r2) => { return r1._num < r2._num ? -1 : 1; } );
                console.log('routes(all)', this.routes);
            } catch(e){
                console.log('ERR(routes)', e);
            } finally {
                this.routesLoading = false;
            }
        },
        routesFilter(item, s, itemText){
            if ( this.routes?.length > 0) {
                if ( isEmpty(s) ){
                    return true;
                }
                if (item.routeCode?.indexOf(s) > -1){
                    return true;
                }
                return item.routeName.toLowerCase().indexOf( s.toLowerCase() ) > -1;
            } 
            return false;
        },
        has(q, v){
            switch(q){
                case "approved":
                    return /^(утве)+/i.test(v.vcreleaseschedulesVerstatusName); //утв.
                case "lost":
                    return /^(утратил)+/i.test(v.vcreleaseschedulesVerstatusName);
                case "route":
                    return !!this.route;
                case "vers":
                    return this.vers?.length > 0;
            }
            return false;
        },
        fmt(dt){
            return (!!dt) ? $moment(dt).format('DD.MM.YYYY') : null;
        },
        open(route){
            this.vers = [];
            this.show = (new Date()).getTime();
            (async ()=>{
                await this.$fetch();
                if ( !route ){
                    this.route = null;
                    return;
                }
                let n, id = route.id || route.vcroutesId;
                if ( !isEmpty(id) ){
                    n = this.routes.findIndex( r => r.id === id );
                    if ( n > -1 ){
                        this.route = this.routes[n];
                        this.loadVers();
                    }
                }
            })();
        },
        selRoute(route){
            this.route = route;
            this.loadVers();
        },
        async loadVers(){
            this.vers = [];
            
            if ( !this.has('route') ){
                return;
            }
            this.mode = MODES.loading;
            const verUri = `sin2:/v:17743043-925a-48e4-90b5-8f1e77226a01?filter=
                                    exists(\"17743043-925a-48e4-90b5-8f1e77226a01\",
                                                   \"and(
                                                       eq(field(\\\"s.verid\\\"),super.field(\\\"s.verid\\\")),
                                                       eq(field(\\\"s.id\\\"),param(\\\"${ this.routeId }\\\",\\\"id\\\"))
                                                   )\"
                                    )
                                    &sort=vcReleaseSchedules.verStatus.Name,-vcReleaseSchedules.verStart`;
            try{
                const res = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: verUri
                });
                if (!!res.error){
                    throw res.error;
                }
                const m = new Model(res.result.model);
                this.vers = m.sin2obj(res.result.data, true);
                console.log('vers', this.vers);
                this.mode = MODES.default;
            } catch(e){
                this.mode = MODES.error;
            }
        },
        /**
         * Select shedule version at list
         * @param {Object} ver (see loadVers)
         */
        selVersion(ver){
            this.$emit("input", {route: this.route, ver, vers: this.vers});
            this.show = false;
        }
    }
};
</script>
<style lang="scss">
    .mt-rt-choose{
        & .v-select{
            .v-select__selection{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-wrap: nowrap;
            }
        }
        & h2{
            font-weight: 400;
            margin: 1rem 0;
            color: var(--v-primary-base);
        }
        & .mt-route{
            &-item{
                &:not(:last-child){
                    border-bottom: 1px solid #efefef;
                }
                &.v-list-item{
                    &__avatar{

                    }
                    &__subtitle{
                        font-size: 0.75rem;
                        color: #777;
                    }
                }
            }
        }
        & .mt-rt-versions{
            & .v-list-item{
                border-top: 1px solid #efefef;
                &.approved{
                    font-weight: 500;
                    color: var(--v-primary-base) !important;
                }
                &.lost{
                    color: #757575;
                }
                &__avatar{
                    font-size: 1.5rem;
                }
                & .mt-rt-meta{
                    font-size: 0.85rem;
                    font-weight: 400;
                    text-transform: lowercase;
                }
            }
        }
        & .v-card{
            &__text{
                height: 480px;
            }
        }
    }
</style>