<template>
    <v-card :loading="pending"
            class="mt-carriers__widget">
        <v-card-title>
                <v-icon class="mt-carriers__icon">mdi-account-cowboy-hat</v-icon>
                ПЕРЕВОЗЧИКИ
        </v-card-title>
        <v-card-subtitle v-if="has('all')">
            <span>всего: {{ totals.carriers }}</span> | обслуживаемых маршрутов: {{ totals.routes }}
        </v-card-subtitle>
        <v-card-text v-if="has('all')">
            <canvas id="carriers-chart" height="280"></canvas>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('carriers')">
                <v-icon small>mdi-account-cowboy-hat</v-icon>
                к списку перевозчиков
                </v-btn>
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('routes')">
                обслуживаемые маршруты
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import Chart from 'chart.js/auto';    
import { getCarriersStats } from "@/services/MtInfo";
const _BG_COLORS = ["rgb(14,70,162)", "rgb(14,70,162)", "rgb(14,70,162)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(160,214,250)", "rgb(175,175,175)", "rgb(175,175,175)", "rgb(175,175,175)", "rgb(0,131,143)", "rgb(0,151,167)"];

let _chart = null;

export default {
    name: 'MtCarriers',
    inject: [
        "openMap"
    ],
    data(){
        return {
            pending: false,
            all:   [],
            totals: {
                routes: 0,
                carriers: 0
            },
            chart: null
        };
    },
    created(){
        this.$fetch();
    },
    unmounted(){
        if (_chart){
            _chart.destroy();
        }
    },
    methods: {
        has(q){
            switch(q){
                case "all":
                    return this.all?.length > 0;
            }
            return false;
        },
        $fetch(){
            this.pending = true;
            this.all = [];
            getCarriersStats().then( res => {
                this.all = res;
                
                if ( res.length > 0 ){
                    let totals =  {routes: 0, carriers: 0},
                        n = 0;
                    res.forEach( a => { totals.routes += a.n; });
                    let s = new Set(res.map(a => a.carrier));
                    totals.carriers = s.size;
                    this.totals = totals;
                }
                
                this.pending = false;
                
                this.buildChart(0);
            }).catch(e => {
                this.pending = false;
                console.log('ERR (carriers-stats)', e);
            });
        },
        buildChart( n ){
            var n = n + 1;
            if ( n > 100 ){
                return;
            }
            const node = $(this.$el).find("#carriers-chart");
            if (
                    ( node.length < 1 ) || !this.has("all" )
               ){
                setTimeout(()=>{ this.buildChart.call(this, n ); }, 300);
                return;
            }
            
            const tops = this.all.filter( a => !!a.name );
            
            node.css({"height": "280px"});
            
            const cfg = {
                data: {
                    labels: tops.map( a => a.name ),
                    datasets: [{
                            type: 'bar',
                            data: tops.map( a => a.n ),
                            backgroundColor: tops.map( (t, n) => _BG_COLORS.at(n).replace(/^(rgb)+/, 'rgba').replace(/\)$/, ',0.5)') ),
                            borderColor: tops.map( (t, n) => _BG_COLORS.at(n) )
                    }]
                },
                options: {
                    indexAxis: 'y',
                    responsive: true,
                    intersect: true,
                    maintainAspectRatio: false,
                    interaction: {
                        mode: 'nearest',
                        axis: 'y'
                    },
                    elements: {
                        bar: {borderWidth: 1}
                    },
                    plugins: {legend: false},
                    onClick: e => {
                        let nearest = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
                        nearest = nearest.at(0);
                        if ( nearest ) {
                            nearest = tops.at(nearest.index);
                            if ( nearest ){
                                this.openMap(nearest.carrier, 'carrier');
                            }
                        } else {
                            console.log('no found', e);
                        }
                    }
                }
            };
            
            if ( _chart ){
                _chart.destroy();
            }
            
            try {
                _chart = new Chart(node.get(0), cfg);
            }catch(e){
                console.log('ERR(chart)', e);
            }
            
        },   //buildChart
        go(q){
            switch(q){
                case "carriers":
                    jet.collections.open({
                        id: "c51f2d81-987e-4dde-93d0-45870eb573d0",
                        uri:'sin2:/v:ad8fd1d7-e1b8-4edc-926c-dfd45c2da6ae/?filter=and%28%0A++++lte%28field%28%22.startDt%22%29%2C+var%28%22util.date.truncToDay%28dateEnd%29%22%29%29%2C%0A++++or%28%0A++++++++isnull%28field%28%22.endDt%22%29%29%2C%0A++++++++gte%28field%28%22.endDt%22%29%2C+var%28%22util.date.truncToDay%28dateBegin%29%22%29%29%0A++++%29%0A%29',
                        name:'1.2.1 Автоперевозчики'
                    });
                    break;
                case "routes":
                    jet.collections.open({
                        id: 'b9afa2dd-7be0-4bdb-b9a3-e91f8f0cca1e',
                        uri:'sin2:/v:e8fd22eb-f901-42af-874f-2bf5fe6a78f4/?filter=and%28%0A++++eq%28field%28%22.isActiveRoute%22%29%2Cparam%28true%2C+%22boolean%22%29%29%2C%0A++++lte%28field%28%22.startDt%22%29%2C+var%28%22util.date.truncToDay%28dateEnd%29%22%29%29%2C%0A++++or%28%0A++++++++isnull%28field%28%22.endDt%22%29%29%2C%0A++++++++gte%28field%28%22.endDt%22%29%2C+var%28%22util.date.truncToDay%28dateBegin%29%22%29%29%0A++++%29%0A%29',
                        name:'1.2.2 Обслуживаемые маршруты'
                    });
                    break;
            }
        }   //go
        
    }
}    
</script>
<style lang="scss">
    @import "@/styles/nav-head.scss";
    
    .mt-carriers{
        &__widget{
            &.v-card{
                & .v-card__title{
                    background-color: $light-blue;
                    color: $blue;
                    & .v-icon{
                        margin-right: 0.5rem;
                        color: $blue;
                    }
                }
                & .v-card__subtitle{
                    margin-top: 0 !important;
                    & .v-icon {
                        color: $orange !important;
                    }
                    & span{
                        color: $orange;
                        font-weight: 500;
                    }
                }
                
                & .v-card__text{
                    & canvas{
                        min-height: 272px;
                        width: 100%;
                        margin: 1rem auto;
                    }
                    h3 {
                        font-weight: 400;
                        font-style: italic;
                        text-align: right;
                    }
                }
            }
        }
    }
</style>