<template>
    <div class="jet-nav-base">
        <div class="v-toolbar v-toolbar--absolute">
            <v-slide-group v-model="root"
                           show-arrows>
                <template v-for="item in roots">
                    <v-slide-item :key="item.id"
                                  :value="item.id"
                                  v-slot="{ active, toggle }"
                                  class="ma-3">
                        <v-card :color="active ? 'primary' : 'white'"
                                :dark="active"
                                v-on:click="item.hasChilds ? toggle() : $emit('item', item)">
                            <v-card-title>
                                <v-icon>{{ item.ico }}</v-icon>&nbsp;
                                {{ item.name }}
                            </v-card-title>
                            <v-card-text>
                                {{ item.descr }}
                            </v-card-text>
                        </v-card>
                    </v-slide-item>
                </template>
            </v-slide-group>
        </div>
        <v-card v-show="(childs.length > 0)" 
                class="jet-nav-childs"
                ref="nav-childs">
            <v-card-text>
                <v-list dense>
                    <template v-if="root === all.pp.id">
                        <v-list-item>
                            <mt-searcher />
                        </v-list-item>
                    </template>
                    <template v-for="c in childs">
                        <v-subheader v-if="c.hasChilds">{{ c.name }}</v-subheader>
                        <v-list-item v-else
                                     v-on:click="$emit('item', c)">
                            <v-list-item-title>
                                {{c.name}}
                            </v-list-item-title>    
                            <v-list-item-icon v-if="'favorites'==root">
                                <v-icon small
                                        v-on:click.stop.prevent="rmfav(c)">mdi-star</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import MtSearcher from "@/components/dev/MtSearcher";
    
const MT_ROOTS = {
    fav: {
        id: "favorites",
        name: "Избранное",
        descr: 'коллекции "быстрого" доступа',
        ico: "mdi-star",
        children: null,
        hasChilds: false,
        uri: false
    },
    base: {
        id: "f8c7b95c-6330-4d0a-9f7e-5ac3c8b953a6",
        name: "Общесистемные сведения",
        descr: "справочники и классификаторы, настроечные и конфигурационные данные",
        ico: "mdi-file-table-box-multiple-outline",
        hasChilds: false,
        uri: false
    },
    pp: {
        id: "f9e7bcb9-406c-488c-beae-37100fd7ef4a",
        name: "Пассажирские перевозки",
        descr: "on-line мониторинг и контроль движения ТС",
        ico: "mdi-bus-multiple",
        hasChilds: false,
        uri: false
    },
    pln: {
        id: "07feb476-a676-4176-bbb1-2806ce3b5a7e",
        name: "Планирование",
        descr: "Планирование движения согласно утвержденным графикам",
        ico: "mdi-timetable",
        hasChilds: false,
        uri: false
    },
    opp: {
        id: "2673e703-a8f5-4306-bd4b-2c132972b55b",
        name: "Реестры",
        descr: "реестры маршрутов, перевозчиков, договоров,...",
        ico: "mdi-file-document-multiple-outline",
        hasChilds: false,
        uri: false
    },
    reps: {
        id: "360cd195-77fe-4da6-9463-c2aa26ed417a",
        name: "Отчеты",
        descr: "Все отчеты Системы  по группам",
        ico: "mdi-file-table-outline",
        hasChilds: false,
        uri: false
    },
    adm: {
        id: "15dde0c6-2963-4bf8-acc2-1855b30d974c",
        name: "Администрирование",
        descr: "управление учетными записями, роли пользователей",
        ico: "mdi-account-multiple-outline",
        hasChilds: false,
        uri: false
    }
};

export default {
    name: "JetNavBase",
    components: {
        MtSearcher
    },
    data(){
        return {
            all: MT_ROOTS,
            root: null
        };
    },
    async created(){
        const all = await this.$store.dispatch("app/getNavs");

        const _expand = (item, items) => {
            const _item = items.filter( i => i.id === item.id ).at(0);
            if ( _item ){
                item.children = _item.children;
                item.hasChilds = (item.children?.length > 0);
                if ( !item.hasChilds && _item.uri ){
                    item.uri = _item.uri;
                }
                return;
            }
            items.filter( i => i.hasChilds).forEach( i => {
                _expand(item, i.children);
            });
        };

        Object.keys(this.all).forEach( k => {
            const r = this.all[k];
            _expand(r, all);
        });
        
        this.root = this.roots.at(0)?.id;
        
        this.getfavs();
        
        console.log('roots(all)', this.all);
        
        this.$nextTick(()=>{
            console.log('roots(only)', this.roots);
        });

    },
    computed: {
        roots(){
            return Object.keys(this.all).map( k => this.all[k] ).filter( a => a.hasChilds || !!a.uri );
        },
        childs(){
            const childs = [],
                  root   = Object.keys(this.all).map( k => this.all[k] ).filter(a => a.id === this.root ).at(0);
          
            if (root?.hasChilds){
                childs.push(root);
                const _expand = item => {
                    if ( item.hasChilds ){
                        item.children.forEach( i => {
                            childs.push(i);
                            if ( i.hasChilds ){
                                _expand(i);
                            }
                        });
                    }
                };
                _expand(root);
            }
            return childs;
        }
    },
    methods: {
        getfavs(){
            this.$store.dispatch("app/getFavs").then( ()=>{
                this.all.fav.children = this.$store.state.app.favs;
                this.all.fav.hasChilds = this.all.fav.children?.length > 0;
                if (this.all.fav.hasChilds){
                    this.root = this.all.fav.id;
                }
            });
        },
        rmfav(item){
            this.$store.dispatch("app/setFav", item).then( res => this.getfavs() );
        }
    },
    watch: {
        root(val){
            if ( val ){
                this.$nextTick(()=>{
                    document.querySelector(".dshb-conte").scrollTop = 0;
                });
            }
        }
    }
}    
</script>
<style lang="scss">
    .jet-nav-base{
        & .v-toolbar{
            left: 0;
            width: 100%;
            background: #fff;
        }
        & .v-slide{
            &-item{
            }
        }
        & .jet-nav-childs { 
            & .v-card__text {
                max-height: calc(100vh - 340px);
                overflow: auto;
            }
        }
        & .v-list{
            & .v-subheader{
                justify-items: flex-end;
                justify-content: flex-end;
                &:first-child{
                    font-size: 1rem;
                    font-weight: bold;
                    font-style: italic;
                }
            }
            &-item:not(:last-child){
                border-bottom: 1px solid #ccc;
            }
        }
    }
</style>