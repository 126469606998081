import router from '@/router';
import store from '@/store';

const state = {
    subject: null,
    id: null,
    name: null,
    tenant: null,
    title: null,
    countAttempts: $utils.readLocalStorage('auth', 'countAttempts') || 0,
    lastAction: $utils.readLocalStorage('auth', 'lastAction') || Date.now(),
    countAttemptsLimit: 3,
    timeout: 5 // в минутах
};

const mutations = {
    setSubject(state, payload) {
        state.subject = payload;
        store.commit('auth/setSubject', payload); //for backward's
        if (payload) {
            state.id = payload.id;
            state.name = payload.name;
            state.tenant = payload.tenantId;
            state.title = payload.title;
        } else {
            state.id = null;
            state.name = null;
            state.tenant = null;
            state.title = null;
            store.commit('app/clearSetts');
        }
        jet.on('subject');
    },
    setTenant(state, payload){
        state.tenant = payload.id;
        state.subject.tenantId = payload.id;
        jet.on('tenant');
    },
    incrementCount(state) {
        if (state.countAttempts >= state.countAttemptsLimit) {
            state.countAttempts = 1;
        } else {
            state.countAttempts += 1;
        }
        state.lastAction = Date.now();
        $utils.saveLocalStorage('auth', {
            countAttempts: state.countAttempts,
            lastAction: state.lastAction
        });
    },
    resetCount(state) {
        state.countAttempts = 0;
        $utils.saveLocalStorage('auth', {
            countAttempts: state.countAttempts
        });
    }
};

const actions = {
    login(store, payload) {
        const { commit, dispatch } = store;
        const { remember, user } = payload;
        
        return new Promise((resolve, reject) => {
            
            if ( "ping"!==user.login ){
                document.cookie = 'JSESSIONID=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
            
            const options = {
                type: 'auth',
                basicAuth: 'Basic ' + btoa(user.login + ':' + user.password)
            };
            
            const _fail = e => {
                console.log('ERR (auth)', e);
                commit('incrementCount');
                commit('setSubject', null);
                reject(e);
            };
            
            $http.post(options).then( res => {
                console.log('auth', res);
                if (res.result) {
                    const subject = res.result;
                    commit('resetCount');
                    commit('setSubject', subject);
                    dispatch('profile/init', subject, { root: true });
                    if (
                            (subject.tokens)
                         && ('ping' !==user.login)
                    ){
                        dispatch('app/setSetts', {tokens: subject.tokens, user: user.login}, { root: true });
                    }
                    resolve(subject);
                } else {
                    _fail(res.error);
                }
            }).catch( e => {
                _fail(e);
            });
            
        });
    },  //login
    /**
     * Check by access token
     * @param {Object} store
     * @returns {Promise}
     */
    check: async store => {
        const { commit, dispatch } = store;
        return new Promise( (resolve, reject) => {
            const at = store.rootGetters["app/settings"]("tokens")?.access;
            if ( !at ){
                reject({message: 'No token'});
                return;
            }
            
            document.cookie = 'JSESSIONID=;  Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
            const options = {
                        type: 'auth',
                        auth: `Bearer ${ at.token }`
                    };
            $http.post(options).then( r => {
                console.log("auth(check)", r);
                if (r.result) {
                    const subject = r.result;

                    if ( !subject.tokens ){
                        subject.tokens = {};
                    }
                    
                    subject.tokens["access"] = at;
                    commit('setSubject', subject);
                    dispatch('init', subject);
                    //TODO: nax? dispatch('setAuthorization', basicAuth);
                    
                    resolve(subject);
                } else {
                    commit('setSubject', null);
                    reject(r.error);
                }
            }).catch( e => {
                console.log("ERR auth(check)", e);
                commit('setSubject', null);
                reject( e );
            });
        });
    },
    logout(store, payload) {
        const { commit, dispatch } = store;
        const promise = resolve => {
            /**
             * Action on success
             * @param {Object} res
             */
            function onSuccess(res) {
                commit('setSubject', null);
                dispatch('setAuthorization', null);
                dispatch('clearCookies');
                router.push('/login');
                resolve(res);
            }
            /**
             * Action on error
             * @param {Object} err
             */
            function onFail(err) {
                commit('setSubject', null);
                dispatch('setAuthorization', null);
                dispatch('clearCookies');
                resolve({ error: err });
            }
            const options = {
                type: 'logout'
            };
            const url = `/rpc?d=jsonRpc`;
            $http
                .post(url, options)
                .then(onSuccess)
                .catch(onFail);
        };
        return new Promise(promise);
    },
    /**
     * @deprecated Using app/setSetts
     * @param {type} store
     * @param {type} basicAuth
     * @returns {undefined}
     */
    setAuthorization(store, basicAuth) {
        $utils.saveLocalStorage('auth', { auth: basicAuth || null });
    },
    clearCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    },
    init(auth) {
        var user = null;
        var newUser = true;
        var profiles = $utils.readLocalStorage('profiles');
        if (!profiles) {
            profiles = [];
        }
        for (var i = 0; i < profiles.length; i++) {
            if (profiles[i].id === auth.id) {
                profiles[i].attrs = auth.attrs;
                profiles[i].availableTenants = auth.availableTenants;
                profiles[i].groups = auth.groups;
                profiles[i].id = auth.id;
                profiles[i].name = auth.name;
                profiles[i].primaryGroupId = auth.primaryGroupId;
                profiles[i].roles = auth.roles;
                profiles[i].settings = auth.settings;
                profiles[i].tenantId = auth.tenantId;
                profiles[i].tenants = auth.tenants;
                profiles[i].title = auth.title;
                newUser = false;
                user = profiles[i];
                break;
            }
        }
        if (newUser) {
            user = {
                attrs: auth.attrs,
                availableTenants: auth.availableTenants,
                groups: auth.groups,
                id: auth.id,
                name: auth.name,
                primaryGroupId: auth.primaryGroupId,
                roles: auth.roles,
                settings: auth.settings,
                tenantId: auth.tenantId,
                tenants: auth.tenants,
                title: auth.title
            };
            profiles.push(user);
        }
        $utils.saveLocalStorage('profiles', profiles);
    },
    tenant({commit, dispatch}, payload){
        return new Promise( (resolve, reject) =>{
            $http.post({type: 'set-tenant', params: payload.id})
                .then( async (res) => {
                    if (!!res.result){
                        commit("setTenant", payload);
                        dispatch("logout");
                        resolve();
                    } else {
                        throw res;
                    }
                })
                .catch( e => {
                    console.log('ERR (set-tenant)', e);
                    reject(e);
                });
        });
    }
};

const getters = {
    isEsia: async () => {
        try {
            const esia = await $http.get('/esia?mode=json');
            return esia.isconfig;
        } catch (e) {
            return false;
        }
    },
    checkEsia: async () => {
        try {
            const options = {
                type: 'auth',
                nologout: true
            };
            const esia = await $http.post(options);
            return esia;
        } catch(e) {
            console.log(e);
        }
    },
    isAuthenticated(state) {
        return (!!state.subject)&&(!/^$/.test(state.subject.id));
    },
    getName(state) {
        return state.title;
    },
    getID(state) {
        return state.id;
    },
    timeout(state) {
        const {
            countAttempts,
            lastAction,
            countAttemptsLimit,
            timeoutDuration
        } = state;
        const timeoutDurationMs = timeoutDuration * 60 * 1000;
        const countOver = countAttempts >= countAttemptsLimit;
        const timeOver = Date.now() - lastAction >= timeoutDurationMs;
        return countOver && !timeOver;
    },
    subject(state){
        return state.subject;
    },
    orgName(state){
        if (!!state.subject.tenantId){
            return (!!state.subject)&&(!!state.subject.availableTenants)
                        ? state.subject.availableTenants[state.subject.tenantId].title
                        : '';
        } else {
            return '';
        }
    },
    token(state){
        return state.subject?.tokens?.access;
    },
    hasrole: state => name => {
        const re = new RegExp(`^(${ name })+`, 'gi');
        let res = false;
        if (state.subject?.roles){
            Object.keys( state.subject.roles ).forEach( k => {
                //console.log(state.subject.roles[k].title);
                if ( re.test(state.subject.roles[k].title) ){
                    res = state.subject.roles[k];
                }
            });
        }
        return res;
    }
    
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
