<template>
    <v-card class="mt-views"
            v-show="has('all')"
            v-on:click="go">
        <v-card-title><v-icon>mdi-eye-outline</v-icon>&nbsp;просмотры</v-card-title>
        <v-card-text class="justify-center">
            <template v-for="r in all.routes.filter( (r, n) => n < 6 )">
                <v-chip small
                        label
                        :key="'route-' + r.num"
                        v-on:click.stop.prevent="go(r.num)">
                        {{ r.num }}
                </v-chip>    
            </template>
            <div class="text-muted">
                <v-icon small>mdi-human-male-female</v-icon> / 
                <v-icon small class="mr-2">mdi-eye-outline</v-icon>
                {{ all.visits }} / {{ all.views }}
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { getMobiStats } from "@/services/MtInfo";

let w = null;

export default{
    name: 'MtViews',
    data(){
        return {
            all: {
                views: -1,
                visits: -1,
                routes: []
            }
        };
    },
    created(){
        this.$fetch();
    },
    methods: {
        $fetch(){
            getMobiStats().then(res =>{
                console.log("views", res);
                this.all = res;
            }).catch(e => {
                console.log("ERR(views)", e);
            });
        },
        has(q){
            switch(q){
                case "all":
                    return (this.all.views > 0);
            }
            return false;
        },
        async go( num ){
            let url = "//kt.krasnodar.ru/";
            if ( num ){
                try {
                    const rpc = {
                        type: "core-read",
                        transform: true,
                        query: `sin2:/v:acd2f542-598c-4540-b46c-03375098d467/?filter=and(
                                  eq(field(".routeCode"),param("${ num }", "string")),
                                  and(lte(field(".verStart"), var("util.date.truncToDay(dateEnd)")),
                                        or(
                                              isnull(field(".verEnd")),
                                              gte(field(".verEnd"), var("util.date.truncToDay(dateBegin)"))
                                        )
                                  )
                                )&fields=.id,.routeName`
                    };
                    
                    let res = await $http.post(rpc);
                    
                    console.log(`route #${ num }`, res);
                    
                    if (res.length > 0){
                        url += `#/map?id=${ res[0].id }`;
                    }
                } catch(e){
                    console.log(`ERR(on #route ${ num }`, e);
                }
            }
            if ( !w || w.closed ){
                w = window.open(url, '_blank');
            } else {
                w.location.href = url;
            }
            w.focus();
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/nav-head.scss";
    
.mt-views{
    &.v-card{
        & .v-card__title{
            text-align: center;
            justify-content: center;
            color: var(--v-primary-base);
            text-transform: uppercase;
            font-size: 1rem;
            & .v-icon{
                color: var(--v-primary-base);
            }
        }
        & .v-card__text{
            max-width: 13rem;
            display: flex;
            flex-wrap: wrap;
            margin-top: -1rem;
            & .v-chip{
                color: white;
                &:not(:last-child){
                    margin-right: 0.25rem;
                    margin-bottom: 0.25rem;
                }
                &:nth-child(1){
                    background: $blue;
                }
                &:nth-child(2){
                    background: $mid-blue;
                }
                &:nth-child(3){
                    background: $orange;
                }
                &:nth-child(4){
                    background: $grey;
                }
            }
            & .text-muted{
                width: 100%;
                text-align: right;
                justify-self: flex-end;
            }
        }
    }
}
</style>