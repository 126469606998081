<template>
    <v-card class="mt-vehicles"
            v-show="has('all')"
            v-on:click="$emit('click')">
        <v-card-title><v-icon>mdi-bus-multiple</v-icon>&nbsp;транспорт</v-card-title>
        <v-card-text>
            <div class="mt-vehicles__pc">{{ pc }}%</div>
            <canvas width="84" height="84"></canvas>
        </v-card-text>
    </v-card>
</template>
<script>
import Chart from 'chart.js/auto';    

export default {
    name: "MtVehiclesPc",
    props: {
        vehicles: {
            type: Array,
            required: false,
            default: null
        }
    },
    data(){
        return {
            chart: null,
            pc: null
        };
    },
    unmounted(){
        this.chart?.destroy();
    },
    methods: {
        has(q){
            switch(q){
                case "all":
                    return (this.vehicles?.length > 0);
            }
            return false;
        },
        $charting(){
            var n = n + 1;
            if ( n > 100 ){
                return;
            }

            const node = $(this.$el).find("canvas");
            if (
                    ( node.length < 1 ) || !this.has("all" )
               ){
                setTimeout(()=>{ this.$charting( n ); }, 300);
                return;
            }
            
            let online = 0, all = 0;
            this.vehicles.forEach( (v, n) => {
                all += v;
                if ( n < 2 ){
                    online = all;
                }
            });
            
            this.pc = ( all > 0 )
                        ? Number(online * 100 / all).toFixed(1)
                        : 0;
            const cfg = {
                        type: 'doughnut',
                        data: {
                                labels: ['графиков', 'рейсов'],
                                datasets: [{
                                        clip: {left: 2, top: 2, right: 2, bottom: 2},
                                        backgroundColor: ["#fdb45c", "#afb0ad"],
                                        borderColor:     ["#ECEFF1", "#ECEFF1"],
                                        data: [online, all - online],
                                        hoverOffset: 4
                               }]
                        },
                        options: {
                            responsive: false,
                            parsing: false,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                title: {
                                    display: false
                                }
                            }
                        }
            };
            if ( this.chart ){
                this.chart.destroy();
            } 
            this.chart = new Chart(node.get(0), cfg);
        }
    },
    watch: {
        vehicles(val){
            console.log("vehicles", val);
            if ( val?.length > 0 ){
                this.$nextTick(()=>{ this.$charting(); });
            }
        }
    }
}    
</script>
<style lang="scss" scoped>
    .mt-vehicles{
        &.v-card{
            & canvas{
                width: 84px;
                height: 84px;
                margin: -22px auto 0 auto;
            }
            & .v-card__title{
                text-align: center;
                justify-content: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
                font-size: 1rem;
                & .v-icon{
                    color: var(--v-primary-base);
                }
            }
        }
        &__pc{
            left: 0;
            top: 14px;
            font-size: 0.9rem;
            position: absolute;
            color: var(--v-primary-base);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>