<script>
import JetInput from '@/components/editors/JetInput';
//import JetFiles from '@/components/JetFiles';
//import JetEditTable from '@/components/JetEditTable';

const FRM_EXT = {
    "eva_j_form": "8190818d-bf31-41d3-8e3c-08582b85f7e9",
    "dsp_ops_form": "b44b1b40-6feb-4352-8d2b-c597859da9a9",
    "work_mode_form": "db8151c4-a879-43ac-88f5-1710e49ddafa",
    "production_clnd": "a2beef32-e23a-4993-a46f-712169215948",
    "autocarriers_form": "217aff7c-804e-4425-a8fe-c9fe805954b4",
    "serviced_routes_form": "d2fe468f-fcc8-4b9e-a275-7aec953cf137",
    "vehicles_form": "0c117b36-5e32-444e-84ab-5cc4340b0be4"
};

import EvaJForm from '@/components/dev/components/eva-ext/EvaJForm';
import DspOpsForm from '@/components/dev/components/dsp-ext/DspOpsForm.vue';
import WorkModeForm from '@/components/dev/components/ref-ext/WorkModeForm.vue';
import ProductionCalendar from '@/components/dev/components/production_clnd/ProductionCalendar.vue';
import AutoCarriersForm from '@/components/dev/components/autocarriers/AutoCarriersForm.vue';
import ServicedRoutesForm from '@/components/dev/components/serviced_routes/ServicedRoutesForm.vue';
import VehiclesForm from '@/components/dev/components/vehicles/VehiclesForm.vue';

import Model from '@/core/Model';




class JetOwnProxy {
    controls = []
    constructor(conte){
        this._own = conte;
    }
    reg(comp){
        this.controls.push(comp);
    }
    unreg(comp){
    }
    reglink(comp) {
        this._own.links.push(comp);
    }
    unreglink(comp) {
        var i = this._own.links.indexOf(comp);
        if ( i !== -1 ) {
          this._own.links.splice(i, 1);
        }
    }
    val(name, val){
        this._own.values[name] = val;
    }
}   //JetFormProxy

export default {
    name: 'JetEditConte',
    props: {
        /** 
         * uri for sin2-view
        */
        uri: { 
            type: String,
            required: false,
            default: 'xxx$$$xxx'
        },
        /**
         * Object (id & etc) for editing
         */
        id: {
            type: String,
            required: false
        },
        add: {            
            type: Boolean,
            required: true,
            default: true
        },
        onlyRead: {           
            type: Boolean,
            required: false,
            default: false
        },
        temporary: {
            type: Boolean,
            default: false
        },
        maxRowCode: {
            type: Number,
            default: 1
        },
        master_id: {
            type: String,
            default: null
        }
    },
    data: function(){
        return {
            model: null, /*sin2-based model*/
            raw:   null, /*sin2 raw-data*/
            loading: false,
            form:  null,
            error: null,
            tabIdx: 0,
            values: {},
            links: [],
            v: null
        };
    },
    created(){
        this.do_edit();
    },
    computed:{
        value: {
            get: function() {
                return this.v;
            },
            set: function(val) {
                this.v = val;
            }
        },
        _add(){
            return !(
                        (!this.add) 
                      &&(!!this.raw)
                      &&(this.raw.length > 0)
                    );
        }
    },
    provide: function(e) {
        var self = this;
        return {
            objValue: (id) => {
                self.value = id;
            },
            maxRowCode: () => {
                return self.maxRowCode;
            },
            getMasterId: () => {
                return self.master_id;
            }
        }
    },
    methods:{
        async loadModel(){
            const self = this;
            var uri = this.uri;
            this.value = this.id;
            if ( "/" !== uri?.charAt(uri.length-1) ){
                uri += "/";
            }
            uri += "?id=" + ((!!this.id) ? this.id : "null");
            try {
                var resp = await $http.post('/rpc?d=jsonRpc', {
                    type: 'core-read',
                    query: uri
                });
                if (!!resp.error){
                    throw resp.error;
                }
                console.log('resp', resp);
                
                this.model = new Model(resp.result.model);
                console.log('this.model', this.model);
                console.log('this.model.id', this.model.id);
                this.raw   = resp.result.data;
                var meta = await this.model.get_meta();  //for form reading & etc
                
                var form = this.model.form;
                console.log('form', form);
                console.log('self', self);
                
                if (!!form){
                    form = (new Function(form))();
                    if (!form.hasOwnProperty("components")){
                        form.components = {};
                    }
                    form.components['JetInput'] = JetInput;
                    //form.components['JetFiles'] = JetFiles;
                    //form.components['JetEditTable'] = JetEditTable;
                    if (!form.hasOwnProperty("computed")){
                        form.computed = {};
                    }
                    form.computed["tab"] = {
                        get: ()=>{ return self.tabIdx; },
                        set: (val)=>{ self.tabIdx = val; }
                    };
                    if (!form.hasOwnProperty("provide")){
                        form.provide = {};
                    }
                    form.provide['owner'] = new JetOwnProxy(this);
                    form.inject = ['objValue'];
                    if (!form.hasOwnProperty("methods")){
                        form.methods = {
                            onDataChange(){}    //stub
                        };
                    }
                    
                    form.data = this.getValues;
                    form.mixins = [];
                    switch (this.model.id){
                        case (FRM_EXT.eva_j_form ):
                            form.mixins.push( EvaJForm );
                            break;
                        case (FRM_EXT.dsp_ops_form):
                            form.mixins.push( DspOpsForm );
                            break;
                        case (FRM_EXT.work_mode_form):
                            form.mixins.push( WorkModeForm );
                            break;
                        case (FRM_EXT.production_clnd):
                            form.mixins.push( ProductionCalendar );
                            form.inject = ['maxRowCode'];
                            break;
                        case (FRM_EXT.autocarriers_form): 
                            form.mixins.push( AutoCarriersForm );
                            break;
                        case (FRM_EXT.serviced_routes_form): 
                            form.mixins.push( ServicedRoutesForm );
                            form.inject = ['getMasterId'];
                            break;
                        case (FRM_EXT.vehicles_form):
                            form.mixins.push( VehiclesForm );
                            form.inject = ['getMasterId'];
                            break;
                    }
                    this.form = form;
                }
                console.log('model loaded (edit)', this.model, this.form);
            } catch(e){
                this.error = e;
                console.log(e);
            }
        },
        do_edit(){
            this.loading = true;
            this.loadModel();
            this.loading = false;
        },
        getValues(){
            this.values = {};
            if (!!this.model){
                var ci = this.model.columnIndexes,
                    fAdd = this._add;
                var ids = Object.keys(ci);
                ids.map((i)=>{
                    this.values[i] = (fAdd) ? null : this.raw[0][ci[i]];
                });
            } 
            return this.values;
        },
        async save(fixOnly){
            const form = this.$refs.form;
            const form1 = form.$children[0].$children[0];
            if ( !form1.validate() ) {
                console.log('No form validate', form);
                return;
            }
            if ( this.temporary ) {
                this.$emit('save', this.values);
                this.$emit('hide');
                //return;
            }
            
            if (typeof form.beforeSave !== "undefined" ){
                const res = await form.beforeSave();
                if(!res && res != undefined) {
                    return;
                }
            }

            var data = this.values,
                id   = (this.value) ? this.value : $utils.uuidv4(), //this._add ? $utils.uuidv4() : this.id,
                opts = {
                    type: this._add ? 'core-create' : 'core-update',
                    query: 'sin2:/v:' + this.model.id,
                    params: [
                        {id: 'id', type:'id', value: id}
                    ]
                };
            console.log('this.model.params', this.model.params);
            console.log('this.model.id', this.model.id);
            this.model.params.map( item =>{
                if(this.model.id != FRM_EXT.production_clnd) {
                    opts.params.push({id: item._id, type: item.type, value: data[item.name]});
                } else if(!!data[item.name]) {
                    opts.params.push({id: item._id, type: item.type, value: data[item.name]});
                }
            });
            console.log('saving form:', form, opts);
            console.log('OPTS', opts);
            try {
                var res = await $http.post(opts);

                if(typeof this.form.methods?.updateTenant !== "undefined") {
                    const updateTenantParams = {
                        in_carrierID: data['vcautocarrierId'] || res.result[this.model.id],
                        in_tenantID: data['vcautocarrierTenant']
                    };
                    await this.form.methods.updateTenant(updateTenantParams);
                }
                
                console.log('SAVE res:', res);
                if (!!res.error){
                    throw res.error;
                }
                this.$emit('data-change', {add: this._add, id: id});
                
                if (typeof form.afterSave !== "undefined" ){
                    await form.afterSave({ id });
                }
               
                this.links.map( l => { res = l.save(id); });
               
                if (!fixOnly){
                    this.$emit('hide');
                }
            } catch(e){
                console.log('SAVE ERR:', e);
                jet.msg({text:'ВНИМАНИЕ! Ошибка сохранения изменений. <br /><small>Информация для технической поддержки: ' + 
                         e.message + '</small>', type:'warning'});
            }
        },
    },
    render: function(h){
        return h('v-card', {
                class: {'jet-edit-conte': true}, 
                props: {
                            flat: true, 
                            loading: this.loading,
                            width: '100%'
                }
            }, [
            h('v-card-title',[
                (!!this.model) ? this.model.name : ''
            ]),
            h('v-card-subtitle', [
                (this._add) ? 'добавление' : 'редактирование'
            ]),
            (!!this.form) 
                ? h('v-card-text', [
                        h('v-form', {ref: 'form', class: {'jet-form': true}}, [
                            h(this.form)
                        ])
                    ])
                : null, //TODO: when form is`t avail
            h('v-card-actions',[
                h('v-btn', {
                    props: {outlined:true, tile:true, color: 'primary', small: true, disabled: this.onlyRead},
                    on: {click: ()=>{this.save(false);}}
                }, 'Записать'),
                h('v-btn', {
                    props:{outlined:true, tile:true, color: 'gray', small: true, disabled: this.onlyRead},
                    on: {click: ()=>{this.save(true);}}
                }, 'Применить'),
                h('v-btn', {
                    props:{outlined:true, tile:true, color: 'gray', small: true},
                    on: {click:()=>{this.$emit('hide');}}
                }, 'Отменить')
            ])
        ]);
    }   //render
}
</script>
<style lang="scss">
    .v-card.jet-edit-conte{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%; 
        & .v-card__title{
            padding-bottom: 12px;
            padding-top: 4px;
            line-height: 1.115;
        }
        & .v-card__subtitle{
            padding-bottom: 0;
        }
        & .v-card__text{
            height: 100%;
            max-height: calc(100% - 120px);
            & .jet-form{
                overflow-y: scroll;
                height: 100%;
                & .v-tabs{
                    height: 100%;
                    & .v-window.v-tabs-items{
                        height: 100%;
                        overflow: auto;
                        padding: 8px 16px 32px 16px;
                    }
                }
            }
        }
        & .v-card__actions{
            padding: 16px;
            justify-content: flex-end;
        }
    }
</style>
