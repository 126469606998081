<template>
    <v-navigation-drawer
        app
        clipped
        absolute
        bottom
        temporary
        light
        width="auto"
        v-model="fNavShow"
        class="jet-app-nav">
        <template v-slot:prepend>
            <v-icon class="pa-2">mdi-home</v-icon>
            <v-menu v-if="has('favs')"
                    open-on-hover
                    bottom
                    right>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" plain>избранное</v-btn>
                </template>
                <jet-favs v-on:open="openCollection($event)" />
            </v-menu>
            <v-divider light dark></v-divider>
        </template>
        <v-treeview
            :items="data"
            activatable
            hoverable
            item-key="id"
            open-on-click
            :active.sync="selected"
            return-object
            dense
            expand-icon="jet-tree-down"
            >
            <template v-slot:prepend="{ item, open }">
                <v-img v-if="item.hasChilds">
                    <jet-svg width="16" height="16"
                        :xref="(!!open) ? '#ico-folder-o' : '#ico-folder'"
                    />
                </v-img>
                <v-img v-else>
                    <jet-svg width="16" height="16" xref="#ico-file" />
                </v-img>
            </template>
            <template v-slot:label="{ item }">
                {{item.name}}
            </template>
        </v-treeview>
        <template v-slot:append>
            <v-divider light dark></v-divider>
            <div class="pa-2">
                <v-icon>fas fa-outdent</v-icon>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import JetSvg from '@/components/JetSvg';
import JetFavs from '@/components/dev/dashboard/JetFavs';


export default {
    name: 'JetNavBar',
    components: {
        JetSvg,
        JetFavs
    },
    data: function() {
        return {
            fNavShow: false,
            data: [],
            selected: []
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        has(q){
            switch(q){
                case "favs":
                    return this.$store.state.app.favs?.length > 0;
            }
            return false;
        },
        toggleNav(){
            this.fNavShow = ! this.fNavShow;
        },
        openCollection(ci){
          this.fNavShow = false;
          let col = ci;
          if ( !col ){
              col = this.selected.at(this.selected.length - 1);
          }
          if (col) {
            console.log('Opening:', col);
            if (/^report:/.test(col.uri)){
              jet.reports.open(col);
            } else {
              jet.collections.open(col);
            }
          }
        },
        normalize(data, l){
            var res = [],
                re  = /^(sin2:\/v:)+/;
            data.map( (item) => {
                var _item = {
                    id: item.id,
                    name: item.name,
                    uri: item.path,
                    isView: re.test(item.path),
                    lvl: l,
                    hasChilds: false
                };
                if ( item.childs ) {
                    _item.children = this.normalize(item.childs, l + 1);
                    _item.hasChilds = (!!_item.children)&&(_item.children.length>0);
                }
                res.push(_item);
            });

            return Object.freeze(res);
        },
        async refresh() {
            try {
                var data = await $http.post('/rpc?d=jsonRpc', {
                        type: 'tree-childs',
                        query: 'sin2:/v:fcfbd587-1b85-4bfb-aaf0-1258f4ff88dc'
                    });
                if (data.result){
                    var _hasView = function(item){
                        if (item.isView) {
                            return true;
                        }
                        var res = false;
                        if (item.children){
                            item.children.map((child)=>{
                                res = res || _hasView(child);
                            });
                        }
                        return res;
                    };
                    this.data = this.normalize(data.result, 0).filter( (item) => {
                        return _hasView(item);
                    } );
                } else {
                    throw data.error;
                }
            } catch(err) {
                jet.msg({text:'ВНИМАНИЕ! Ошибка получения данных<br /><small>Информация для технической поддержки: '
                         + err.message + '</small>', color:'warning'});
            }
        }
    },
    watch: {
      selected(val){
          if (val.length > 0){
            this.openCollection( val[0] );
          }
      }
    }
};
</script>

<style lang="scss">
    .jet-tree-down{
        width: 28px !important;
        height: 28px !important;
        &::before{
            font-size: 12px !important;
            font-family: 'Font Awesome 5 Free' !important;
            font-weight: 900 !important;
            content: "\f078";
        }
    }
</style>
