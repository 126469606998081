<template>
    <v-card :loading="pending"
            class="mt-routes__widget">
        <v-card-title>
            <div>
                <v-icon class="mt-routes__icon">mdi-highway</v-icon>
                МАРШРУТЫ
            </div>
            <v-spacer />
            <div>
                <v-btn class="mr-3"
                       small
                       tile
                       color="primary"
                       v-on:click="go('collection')">
                    <v-icon small>mdi-bus-multiple</v-icon>
                    к списку
                    </v-btn>
                <v-btn small
                       tile
                       color="primary"
                       v-on:click="go('map')">
                    <v-icon small>mdi-map-legend</v-icon>
                    карта
                </v-btn>
            </div>
        </v-card-title>
        <v-card-subtitle v-if="lastTime">
            <v-icon small>mdi-clock-outline</v-icon>&nbsp;последний утвержденный от {{ lastTime }}
        </v-card-subtitle>
        <v-card-text v-if="has('all')">
            <v-row dense class="align-center">
                <v-col cols="5" class="text-center">
                    <canvas id="routes-chart"></canvas>
                </v-col>
                <v-col cols="7" class="routes-table">
                    <h3>сводная информация по типам маршрутов</h3>
                    <v-data-table class="routes-table__table"
                              fixed-header
                              no-filter
                              hover
                              :headers="hdrs"
                              :items="all"
                              :items-per-page="-1"
                              item-value="id"
                              single-select
                              disable-pagination
                              hide-default-footer
                              no-data-text="..."
                              height="100%">
                    <template v-slot:item.name="{item}">
                        <template v-if="(0==item.id)">
                            <b>ИТОГО</b>
                        </template>
                        <v-badge v-else 
                                 dot 
                                 :color="colors.at(item.index)" inline left>
                            {{ item.name }}
                        </v-badge>
                    </template>
                    <template v-slot:item.dt="{item}">
                        {{ formatDate(item.dt, 'DD.MM.YYYY') }}
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { getMtInfo } from "@/services/MtInfo";
import { formatDate } from "@/utils/utils";
import Chart from 'chart.js/auto';    

const _HDRS = [
    {text: 'Тип маршрута',   value: 'name',   sortable: false},
    {text: 'Количество',     value: 'n',      sortable: false},
    {text: 'Графиков',       value: 'shedules',sortable: false},
    {text: 'Рейсов',         value: 'trips',  sortable: false},
    {text: 'ТС на рейсах',   value: 'vehicles', sortable: false},
    {text: 'Протяженность',  value: 'len',    sortable: false},
    {text: 'Вместимость',    value: 'places', sortable: false},
    {text: 'В проекте',      value: 'proj',   sortable: false},
    {text: 'Посл.утвержденный', value: 'dt',  sortable: false}
];

export default {
    name: 'MtRoutesWidget',
    inject: [
        "openMap"
    ],
    data(){
        return {
            pending: false,
            all: null,
            lastTime: null,
            chart:    null,
            hdrs: _HDRS
        };
    },
    created(){
        this.$fetch();
    },
    unmounted(){
        if (this.chart){
            this.chart.destroy();
            this.chart = null;
        }
    },
    computed: {
        colors(){
            let ds = this.chart?.config?.data?.datasets?.at(0);
            return ds ? ds.backgroundColor : [];
        }
    },
    methods: {
        formatDate,
        $fetch(){
            this.all = [];
            this.pending = true;
            getMtInfo(10).then( res => {
                if (res.error){
                    throw res.error;
                }
                let last = $moment([1900, 1, 1]),
                    total= {id: '0', name: 'ИТОГО'};
                
                res.forEach( (r, n) => {
                    r.index = n;
                    r.dt = $moment(r.dt);
                    if ( r.n ){
                        let details = JSON.parse(r.n);
                        r = Object.assign(r, details);
                    }
                    r.len = Math.round((r.forward||0) + (r.reverse||0));
                    if ( last.isBefore(r.dt) ){
                        last = $moment(r.dt);
                    }
                    Object.keys(r).forEach( k => {
                        if ( Number.isInteger(r[k]) ){
                            if ( !total[k] ){
                                total[k] = 0;
                            }
                            total[k] += r[k];
                        }
                    });
                });
                total.dt = $moment(last);
                res.push(total);
                this.lastTime = last.format("DD.MM.YYYY");
                this.all = res;
                this.$emit("total", total);
                this.$nextTick(()=>{ 
                    this.buildChart(0); 
                });
            }).finally(()=>{
                this.pending = false;
            });
        },
        buildChart(n){
            var n = n + 1;
            if ( n > 100 ){
                return;
            }
            let data = this.all;
            const node = $(this.$el).find("#routes-chart");
            if (
                    ( node.length < 1 ) || !this.has("all" )
               ){
                setTimeout(()=>{ this.buildChart( n ); }, 300);
                return;
            }
            
            const cfg = {
                        type: 'doughnut',
                        data: {
                                labels: data.filter(d => '0' !== d.id ).map( a => a.name ),
                                datasets: [{
                                        clip: {left: 8, top: 8, right: 8, bottom: 8},
                                        data: data.filter(d => '0' !== d.id ).map( a => a.n ),
                                        hoverOffset: 4,
                                        backgroundColor: ["#0d47a1", "#a0d5fa", "#afb0ad", "#ffcc00"],
                                        borderColor:     ["#eceff1", "#eceff1", "#eceff1", "#eceff1"]
                               }]
                        },
                        options: {
                            responsive: false,
                            plugins: {
                                legend: {
                                    display: true
                                },
                                title: {
                                    display: false
                                }
                            }
                        }
            };
            if ( this.chart ){
                this.chart.destroy();
            } 
            this.chart = new Chart(node.get(0), cfg);
        },
        has(q){
            switch(q){
                case "all":
                    return (this.all?.length > 0);
            }
            return false;
        },
        go(q){
            switch(q){
                case "collection":
                    jet.collections.open({
                        id: "9299022d-fb8b-4de1-9689-e6e3a8e28862",
                        uri:"sin2:/v:acd2f542-598c-4540-b46c-03375098d467",
                        name:"МАРШРУТЫ"
                    });
                    break;
                case "map":
                    this.openMap();
                    break;
            }
        }
    }
};
</script>
<style lang="scss">
    @import "@/styles/nav-head.scss";
    
    .mt-routes__widget {
        &.v-card{
            & .v-card__title{
                background-color: $light-blue;
                color: $blue;
                & .v-icon{
                    margin-right: 0.5rem;
                    color: $blue;
                }
                & .v-btn .v-icon{
                    color: #fff;
                }
            }
            & .v-card__subtitle{
                margin-top: 0 !important;
                & .v-icon {
                    color: $orange !important;
                }
            }
            & .v-card__text{
                & canvas{
                    width: 272px;
                    min-height: 272px;
                    margin: 1rem auto;
                }
            }
        }
        & .routes-table {
            background: $light-blue;
            padding: 0.5rem;
            & h3 {
                margin: 0.5rem 0;
                font-weight: 500;
                font-size: 0.9rem;
                color: $blue;
                text-transform: uppercase;
            }
            & .v-data-table {
                &__wrapper{
                    scrollbar-width: thin;
                    & > table > thead{
                        & > tr > th {
                            word-break: normal;
                            white-space: nowrap;
                            background: $blue !important;
                            color: #fff !important;
                            height: 32px;
                        }
                    }
                }
                & tbody{
                    & tr {
                        & td {
                            font-size: 0.85rem !important;
                            color: $blue;
                        }
                        & td:nth-child(2),
                        & td:nth-child(3),
                        & td:nth-child(4),
                        & td:nth-child(5),
                        & td:nth-child(6),
                        & td:nth-child(7),
                        & td:nth-child(8){
                            text-align: right !important;
                        }
                    }
                }
            }
        }
    }
</style>
