<script>
export default{
    name: 'evacReport8',
    props: {
        'owner': {
            type: Object,
            required: true
        }
    },
    mounted(){
        this.journalID = this.$store.getters['cache/getItem']('evacReport8_ID');
        var comp = this.owner.$refs["regNum"];
        comp.setDisabled(true);
        comp.setValue(this.journalID.evacoffensejournalRegnum);
        var comp = this.owner.$refs["regDt"];
        comp.setValue(new Date());
        var comp = this.owner.$refs["protocolDt"];
        comp.setValue(null);
        var comp = this.owner.$refs["journalID"];
        comp.setVisible(false);
        comp.setValue(this.journalID.evacoffensejournalId);
    },
    data: () => ({
        /*params*/
        journalID: null,
        regDt    : null
    }),
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>