<template>
    <v-menu v-model="menu">
        <template v-slot:activator="{ on }">
            <v-form v-on:submit.stop.prevent="search"
                    style="display:block;width:100%;">
                <v-text-field label="поиск" 
                              v-model="s" 
                              clearable
                              dense
                              full-width
                              :dark="dark"
                              :loading="pending"
                              :outlined="'outlined'===variant"
                              hint="найти: маршрут / перевозчика / остановку / ТС">
                    <template v-slot:progress>
                        <v-progress-linear 
                                :color="dark ? 'white' : undefined"
                                bottom
                                height="2"
                                absolute
                                indeterminate />
                    </template>
                </v-text-field>
            </v-form>
        </template>
        <v-list dense v-if="has('data')"
                class="mt-search__result">
            <v-list-item v-for="item in all" v-on:click="go(item)"
                         :key="item.id">
                <v-list-item-icon>
                    <v-icon small class="mr-3">
                        {{ ("route"==item.type) ? "mdi-highway" : "stop"==item.type ? "mdi-map-marker-radius-outline" : "carrier"==item.type ? "mdi-account-cowboy-hat-outline" : "mdi-bus"}}
                    </v-icon>
                </v-list-item-icon>
                <v-row>
                    <v-col cols="3" class="text-truncate">
                        {{ item.code }}
                    </v-col>
                    <v-col cols="9">
                        {{ item.name }}
                        <div class="text-muted">
                            {{ item.description }}
                        </div>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
        <v-alert v-else class="my-0">
            По запросу "{{ s }}" ничего не найдено - введите другое значение
        </v-alert>
    </v-menu>
</template>
<script>
import { isEmpty } from "@/utils";

const ROUTES_VIEW   = "sin2:/v:acd2f542-598c-4540-b46c-03375098d467";
const CARRIERS_VIEW = "sin2:/v:ad8fd1d7-e1b8-4edc-926c-dfd45c2da6ae";
const STOPS_VIEW    = "sin2:/v:f775655f-9081-4980-99f2-b0b8a69b6344";
const VEHICLES_VIEW = "sin2:/v:6857aebe-2248-4e72-a9ec-918d5e722cf6";

/**
 * Search route by name|code
 * @param { String } s - search
 * @return { Array } searched routes
 */ 
async function routes( s ) {
    let query = `${ ROUTES_VIEW }/?filter=and(
                        lte(field(".verStart"), var("util.date.truncToDay(dateEnd)")),
                                    or(
                                        isnull(field(".verEnd")),
                                        gte(field(".verEnd"), var("util.date.truncToDay(dateBegin)"))
                                    )`;
    if ( !isEmpty(s) ){
        query += `, or(like(str.lower(field(".routeName")),"%25${ s.toLowerCase() }%25", "|"),
                                      eq(field(".routeCode"),param("${ s }", "string"))
                    )`;
    }
    query += ')&sort=vcRoutes.routeCode';
    
    const re_num = /[^0-9]+/g;
    const normalize = route => {
        route.type = "route";
        route.code = route.routeCode;
        route.name = route.routeName;
        route.num  = Number(route.code?.replace(re_num, ''));
        route.description = route.shortName || '';
        if (route.version){
            route.description += ` (ver.№${route.version||'нет'} `;
            if (route.verStart){
                route.description += $moment(route.verStart).format('DD.MM.YYYY');
            }
            if (route.verEnd){
                route.description += ` - ${$moment(route.verEnd).format('DD.MM.YYYY')}`;
            }
            route.description += ', ' + route.verStatusName + ')';
        }
    };
    
    
    const data = await $http.post({
                        type: "core-read",
                        transform: true,
                        query
    });
    
    
    return (data?.map( d => {
        normalize( d );
        return d;
    }) || []).sort( (r1, r2) => (r1.num < r2.num) ? -1 : 1);
};

/**
 * Search carriers by name|all carriers
 * @param { String } s - search
 * @return { Array } searched carriers
 */ 
const carriers = async s => {
    let query = `${ CARRIERS_VIEW }/?filter=and(
                                    lte(field(".startDt"),var("util.date.truncToDay(dateEnd)")),
                                    or(
                                            isnull(field(".endDt")),gte(field(".endDt"),var("util.date.truncToDay(dateBegin)"))
                                    )`;
    if ( !isEmpty(s) ){
        query += `,like(str.lower(field(".orgID.ShortName")),"%25${ s.toLowerCase() }%25", "|")`;
    }
    query += ')&fields=vcautocarrier.id,vcautocarrier.orgID.OrgCode,vcautocarrier.orgID.ShortName';
    
    const data = await $http.post({
                        type: 'core-read',
                        transform: true,
                        query
    });
    
    return data?.map( d => {
        return {
                    id:   d.id,
                    type: "carrier", 
                    code: d.orgIDOrgCode,   
                    name: d.orgIDShortName
               };
    }) || [];
};  //carriers

const stops = async s => {
    let n = Number(s),
        query = `${ STOPS_VIEW }?filter=and(
                                field(".typeID.stop"),
                                lte(field(".startDt"), var("util.date.truncToDay(dateEnd)")),
                                or(
                                    isnull(field(".endDt")),
                                    gte(field(".endDt"), var("util.date.truncToDay(dateBegin)"))
                                )
                            `;
    if ( Number.isNaN(n) ){
        query += `,like(str.lower(field(".locName")),"%25${ s.toLowerCase() }%25", "|")`;
    } else {
        query += `,or(like(str.lower(field(".locName")),"%25${ s.toLowerCase() }%25", "|"),
                   eq(field(".Code"),param(${ n }, "integer")))`;
    }
    query += ")";
    const data = await $http.post({
                        type: 'core-read',
                        transform: true,
                        query
                    });
                    
    const normalize = s => {
        if ( !isEmpty(s.objectTypeIDName) ){
            let a = s.objectTypeIDName.split(/\s/);
            s.short = a.map( c => c.charAt(0) ).join('').toUpperCase();
        }

        s.code = s.Code;
        s.name = s.locName;
        s.description = s.routesList;
        s.lat = s.latitude;
        s.lon = s.longitude;
        s._type= s.type;
        s.type = "stop";
    };
                    
    data?.forEach( d => normalize(d) );
    
    return data || [];
};  //stops


const vehicles = async s => {
    const data = await $http.post({
                        type: 'core-read',
                        transform: true,
                        query: `${ VEHICLES_VIEW }/?filter=and(
                                    like(str.lower(field(".govNum")),"%25${ s.toLowerCase() }%25", "|"),
                                    lte(field(".startDt"), var("util.date.truncToDay(dateEnd)")),
                                    or(
                                           isnull(field(".endDt")), gte(field(".endDt"),var("util.date.truncToDay(dateBegin)"))
                                    )
                                    )&fields=vcVehicle.id,vcVehicle.govNum,vcVehicle.vcTypeID.vcTypeName,vcVehicle.crrID.orgID.ShortName`
    });
    return data?.map( d => {
        return {
            type: "vehicle",
            id:   d.id,
            code: d.govNum,
            name: d.vcTypeIDvcTypeName, 
            description: d.crrIDorgIDShortName
        };
    }) || [];
};  //vehicles

/**
 * Communication channel
 * @type BroadcastChannel
 */
let bc = null,
    w  = null;

export default {
    name: 'MtSearcher',
    props: {
        dark: {
            type: Boolean,
            required: false,
            default: false
        },
        variant: {
            type: String,
            required: false,
            default: 'outlined'
        }
    },
    data(){
        return {
            menu: false,
            s: null,
            all: [],
            pending: false
        };
    },
    methods: {
        has(q){
            switch(q){
                case "data":
                    return (this.all?.length > 0);
            }
            return false;
        },
        search(){
            if ( isEmpty(this.s) ){
                return;
            }
            this.pending = true;
            this.all = [];
            this.$forceUpdate();
            Promise.all([
                routes(this.s),
                carriers(this.s),
                stops(this.s),
                vehicles(this.s)
            ]).then( proms => {
                let res = [];
                proms.forEach( data => {
                    res = res.concat(data);
                });
                this.all = res;
                this.menu = true;
                console.log('all', this.all);
            }).catch( e => {
                console.log('ERR(all)', e);
                jet.msg({text:`Ошибка поиска: ${ e.message }`, color: 'warning'});
            }).finally(()=>{
                this.pending = false;
            });
        },
        go(item){
            let url = /^(local)+/.test(window.location.host)
                        ? 'http://localhost:3000'
                        : `${window.location.protocol}//${window.location.host}/tracked`;
            url += `?t=${ item.type }&id=${ item.id }`;
            if ( !w || w.closed ){
                w = window.open(url, '_blank');
            } else {
                w.location.href = url;
                //TODO: by w.postMessage
            }
            //console.log('win', w);
            w.focus();
            
            this.menu = false;
        }
    }
};
</script>
<style lang="scss">
    .mt-search{
        &__result{
            & .text-muted{
                font-size: 0.75rem;
                color: grey;
            }
            & .v-list-item:not(:last-child){
                
                border-bottom: 1px solid #ccc;
            } 
        }
    }
</style>