<template>
    <div class="jet-input jet-input-string" v-show="vis">
        <v-textarea v-if="rows > 1"
            v-model="value"
            :disabled="dis"
            :label="label"
            :rules="rules"
            :placeholder="placeholder"
            no-resize
            :rows="(rows) ? rows : 1">
            <template v-slot:append-outer>
                <jet-input-btn />
            </template>
        </v-textarea>
        <v-text-field v-else
          type="text"
          v-model="value"
          :disabled="dis"
          :rules="rules"
          :label="label">
            <template v-slot:append-outer>
                <jet-input-btn />
            </template>
        </v-text-field>
    </div>
</template>

<script>
import JetInputBase from '@/components/editors/JetInputBase';
import JetInputBtn from '@/components/editors/JetInputBtn';

export default {
    name: 'JetInputString',
    extends: JetInputBase,
    components: {
        JetInputBtn
    },
    data(){
          return {
              val: this.$attrs.value,
              old: this.$attrs.value
          };
    },
    methods: {
        setValue(val){
            this.value = val;
            $(this.$el).find("input").val(val);
            console.log('input', $(this.$el).find("input"));
        }
    },
    computed: {
        value: {
            get(){
                return this.val;
            },
            set(val){
                this.val = val;
                this.$emit('input', val);
                this.$emit('datachange', this.name);
            }
        }
    }
};
</script>
