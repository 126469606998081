<script>
import moment from 'moment';
const xl = require('excel4node');
export default{
    name: 'Rep1071',
    props: {
        'owner': {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            startDt: null,
            endDt  : null
        }
    },
    created(){
        console.log('10.71 created by', this.owner);
        //this.owner.$refs.year.setValue(Number(new Date().getFullYear()));
    },
    methods: {
        async doReportExt(params) {
            if ( !this._check(params) )
                return;
            try {
                const year = moment(String(this.startDt.year()));
                let first = year.clone().startOf('month').add(3, 'hours');
                let last  = year.clone().add(1, 'month').add(3, 'hours');
                let data = [];
                const req1 = {
                    type: 'query',
                    query: "de00c22a-0594-4c4a-bc9d-d4484b957355.evacStaticRequests",
                }
                for(let i = 1; i <= 12; i++) {
                    let startDt = last.clone(),
                        endDt   = first.clone();
                    if ( last.isAfter(this.startDt) && first.isBefore(this.endDt) ) {
                        startDt = this._greatest(this.startDt, first);
                        endDt = this._least(this.endDt, last);
                        console.log({startDt, endDt});
                    }
                    req1.params = {startDt, endDt};
                    let rs1 = await $http.post(req1);
                    if(rs1.result.data.length > 0) {
                        data.push({month: i, static: rs1.result.data});
                    } 
                    first = first.clone().add(1, 'months');
                    last = last.clone().add(1, 'months');
                }

                const static_data = this.calcDataRep_1071(data);
                this.printRep_1071(static_data, year.format('YYYY'));
                
            } catch (error) {
                console.error('ERR: doReport_1071()', error);
            }
        }, // doReportExt
        _greatest(first, last) {
            if ( first.isAfter(last) ) 
                return first;
            return last;
        },
        _least(first, last) {
            if ( first.isAfter(last) ) 
                return last;
            return first;
        },
        _check(params) {
            params.filter((p) => p.name.toLowerCase() == 'startdt').forEach((p) => {
                let v = p.value;
                v.setHours(0);
                v.setMinutes(0);
                v.setSeconds(0);
                this.startDt = moment(v);
            });
            params.filter((p) => p.name.toLowerCase() == 'enddt').forEach((p) => {
                let v = p.value;
                v.setHours(23);
                v.setMinutes(59);
                v.setSeconds(59);
                this.endDt = moment(v);
            });
            if ( this.endDt.year() !== this.startDt.year() ) {
                jet.msg({text: 'Выбранный период относится к разным календарным годам данный отчет выводит информацию только в рамках 1 года. Измените период запроса данных.', color: 'warning'});
                return false;
            }
            if ( this.startDt.isAfter(this.endDt) ) {
                jet.msg({text: 'Дата начала больше даты окончания. Измените период запроса данных.', color: 'warning'});
                return false;
            }
            return true;
        },
        calcDataRep_1071(data) {
            // Формируем массив данных для создания отчёта
            let static_data = [];
            const months = [1,2,3,4,5,6,7,8,9,10,11,12];
            const total_data = {
                city: 'ИТОГО',
                '1_request': 0,
                '2_request': 0,
                '3_request': 0,
                '4_request': 0,
                '5_request': 0,
                '6_request': 0,
                '7_request': 0,
                '8_request': 0,
                '9_request': 0,
                '10_request': 0,
                '11_request': 0,
                '12_request': 0,
                'total_of_yr': 0,
                isTotal: true
            }

            // Отбираем МО
            data[0].static.forEach(it => {
                static_data.push({cityid: it[0], city: it[1]});
            });


            data.forEach(st => {
                st.static.forEach(it => {
                    // Проходимся по МО и для каждого месяца заполняем кол-во заявок
                    const index = static_data.findIndex(data => data.cityid == it[0]);
                    static_data[index][st.month + '_request'] = it[2];
                });
            })
            
            // Дозаполяем параметр "Кол-во заявок" для каждого МО и считаем параметры "Итого за год"
            static_data.forEach((it,i) => {
                it.code = String(i + 1);

                let total_of_yr = 0;
                months.forEach(m => {
                    if(!it.hasOwnProperty(m + '_request')) 
                        it[m + '_request'] = 0;
                })

                for(let key in it) {
                    if(key.indexOf('_request') != -1) 
                        total_of_yr +=  it[key];
                }

                it.total_of_yr = total_of_yr;

                total_data['1_request'] += it['1_request'];
                total_data['2_request'] += it['2_request'];
                total_data['3_request'] += it['3_request'];
                total_data['4_request'] += it['4_request'];
                total_data['5_request'] += it['5_request'];
                total_data['6_request'] += it['6_request'];
                total_data['7_request'] += it['7_request'];
                total_data['8_request'] += it['8_request'];
                total_data['9_request'] += it['9_request'];
                total_data['10_request'] += it['10_request'];
                total_data['11_request'] += it['11_request'];
                total_data['12_request'] += it['12_request'];
                total_data.total_of_yr += it.total_of_yr;
            })

            static_data.push(total_data);

            return static_data;
        }, // calcDataRep_1071
        printRep_1071(data, yr) {
            const cols = [
                {title: '№ п/п', id: 'code', type: 'string'},
                {title: 'Муниципальное образование', id: 'city', type: 'string'},
                {title: 'Январь', id: '1_request', type: 'integer'},
                {title: 'Февраль', id: '2_request', type: 'integer'},
                {title: 'Март', id: '3_request', type: 'integer'},
                {title: 'Апрель', id: '4_request', type: 'integer'},
                {title: 'Май', id: '5_request', type: 'integer'},
                {title: 'Июнь', id: '6_request', type: 'integer'},
                {title: 'Июль', id: '7_request', type: 'integer'},
                {title: 'Август', id: '8_request', type: 'integer'},
                {title: 'Сентябрь', id: '9_request', type: 'integer'},
                {title: 'Октябрь', id: '10_request', type: 'integer'},
                {title: 'Ноябрь', id: '11_request', type: 'integer'},
                {title: 'Декабрь', id: '12_request', type: 'integer'},
                {title: 'Итого за ' + yr, id: 'total_of_yr', type: 'integer'},
            ];
            const rows = data;
            const name = `Статистика принятых заявок по задержанным транспортным средствам  на территории Краснодарского края с ${$moment(this.startDt).format('DD.MM.YYYY')} по ${$moment(this.endDt).format('DD.MM.YYYY')}`;

            const book = new xl.Workbook();
            const border = {
                left: {
                    style: 'thin',
                    color: '#000000'
                },
                right: {
                    style: 'thin',
                    color: '#000000'
                },
                top: {
                    style: 'thin',
                    color: '#000000'
                },
                bottom: {
                    style: 'thin',
                    color: '#000000'
                }
            }
            const caption = book.createStyle({
                font: {
                    color: '#000000',
                    size: 12,
                    bold: true
                }
            });
            const title = book.createStyle({
                font: {
                    color: '#000000',
                    size: 10,
                    bold: true
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                fill: {
                    type: 'gradient',
                    bgColor: '#C0C0C0',
                    patternType: 'solid'
                },
                border: border,
            });
            const int_cell = book.createStyle({
                font: {
                    size: 10,
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border,
                numberFormat: '#,##0'
            });
            const string_cell = book.createStyle({
                font: {
                    size: 10,
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                },
                border: border
            });
            const total_cell = book.createStyle({
                font: {
                    size: 10,
                    bold: true
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                },
                border: border
            });
            const def_cell = book.createStyle({
                font: {
                    size: 10,
                },
                border: border
            });
            var sheet = book.addWorksheet("Лист 1");

            sheet.cell(1, 1, 1, cols.length, true)
                .string(name)
                .style(caption);

            cols.map((col, c) => {
                sheet.cell(2, c + 1)
                    .string(col.title)
                    .style(title);
            });

            sheet.column(2).setWidth(25);
            sheet.row(2).setHeight(25);

            rows.map((row, r) => {
                if(row?.isTotal) {
                    cols.map((col, c) => {
                        switch ( col.type ) {
                            case 'integer':
                                sheet.cell(2 + r + 1, c + 1)
                                    .number(row[col.id])
                                    .style(total_cell);
                                break;
                            case 'string':
                                sheet.cell(2 + r + 1, c + 1)
                                    .string(row[col.id])
                                    .style(total_cell);
                                break; 
                            default:
                                sheet.cell(2 + r + 1, c + 1)
                                    .string(row[col.id])
                                    .style(def_cell);
                                break;
                        }
                    });
                } else {
                    cols.map((col, c) => {
                        switch ( col.type ) {
                            case 'integer':
                                sheet.cell(2 + r + 1, c + 1)
                                    .number(row[col.id])
                                    .style((col.id == 'total_of_yr') ? total_cell : int_cell);
                                break;
                            case 'string':
                                sheet.cell(2 + r + 1, c + 1)
                                    .string(row[col.id])
                                    .style(string_cell);
                                break; 
                            default:
                                sheet.cell(2 + r + 1, c + 1)
                                    .string(row[col.id])
                                    .style(def_cell);
                                break;
                        }
                    });
                }
            });
            const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            book.writeToBuffer().then(function(buffer) {
                const blob = new Blob([buffer], {type: mimeType});
                const url = URL.createObjectURL(blob);
                const ref = document.createElement('a');
                ref.download = "Статистика принятых заявок за год";
                ref.href = url;
                document.body.appendChild(ref);
                ref.click();
                document.body.removeChild(ref);
                window.URL.revokeObjectURL(url);
            });
        }
    },
    render(h){
        h('i',{class: "rep-stub"});
    }
}
</script>