<template>
    <v-dialog v-model="show">
        <v-toolbar color="primary"
                   dark
                   dense>
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon v-on:click="show = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>    
        </v-toolbar>
        <v-card flat
                rounded="0"
                :loading="pending">
            <v-card-text class="pt-1">
                <v-data-table class="jet-table"
                              fixed-header
                              no-filter
                              hover
                              :headers="hdrs"
                              :items="items"
                              :items-per-page="-1"
                              item-value="id"
                              single-select
                              disable-pagination
                              hide-default-footer
                              no-data-text="..."
                              return-object
                              min-height="640"
                              height="100%">
                    <template v-slot:item.dt="{item}">
                        {{ formatDate(item.dt, 'short') }}
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn small
                       prepend-icon="mdi-close"
                       variant="elevated"
                       v-on:click="show = false">закрыть</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { formatDate } from "@/utils/utils";
import { getMtInfoAudi } from "@/services/MtInfo";  

const AU_COLS = [
                    {text: 'Дата, время',  value: 'dt',          sortable: true, width: "auto"},
                    {text: '№',            value: 'code',        sortable: true},
                    {text: 'Наименование', value: 'name',        sortable: false},
                    {text: ' ',            value: 'description', sortable: false},
                    {text: 'Пользователь', value: 'username',    sortable: false}
];
    


export default {
    name: 'MtAuditLast',
    data(){
        return {
            hdrs: AU_COLS,
            show: false,
            item:  null,
            items: null,
            pending: false
        };
    },
    computed: {
        title(){
            let s = "Аудит последних изменений ";
            switch (this.item?.attr){
                case 1:
                    s += "списка маршрутов";
                    break;
                case 2:
                    s += "расписаний";
                    break;
            }
            return s;
        }
    },
    methods: {
        formatDate,
        $fetch(){
            this.items = [];
            this.pending = true;
            getMtInfoAudi(this.item.attr).then( res => {
                console.log('audit at', this.item, res);
                this.items = res;
            }).finally(()=>{
                this.pending = false;
            });
        },
        open(item){
            this.item = item;
            this.show = true;
            this.$fetch();
        }
    }
};
</script>