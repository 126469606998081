<template>
  <v-app>
    <v-system-bar app window v-show="sysbar">
      {{ sysmsg }}
    </v-system-bar>

    <v-main class="fill-height">
      <router-view/>
    </v-main>

    <v-snackbar :bottom="msg.bottom || true"
                :top="msg.top || false"
                :right="msg.right || false"
                :left="msg.left || false"
                :centered="msg.centered || false"
                v-model="msg.show"
                :color="msg.color"
                :timeout="msg.timeout"
                class="jet-app-snackbar">
      <div v-html="msg.text"/>
      <v-btn x-small absolute dark fab v-on:click="msg={show:false}" :color="msg.color">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <jet-confirm ref="confirm"/>
    <jet-audit-form ref="audit"/>
  </v-app>
</template>

<script>
import Worker from "worker-loader!./ws.js";
import JetConfirm from '@/components/JetConfirm';
import JetAuditForm from '@/components/JetAuditForm';

export default {
  name: 'App',
  components: {
    JetConfirm,
    JetAuditForm
  },
  data(){
        return {
            msg: {show: false},
            sysbar: false,
            sysmsg: null,
            h_ping_timer: false
        };
  },
  created: function(){
    $utils.moment = this.$moment;
    const self = this;
    window.jet = {
      /**
       * Показ сообщения
       *
       * @param {{text: String, color: String|*, timeout: Number|*} | false} message Сообщение
       */
      msg: message => {
        if (!!message) {
          //self.msg = Object.assign(message, {color: 'default', timeout: 6000});
          self.msg = Object.assign(message, {timeout: 6000});
          self.msg.show = (new Date()).getTime();
        } else {
          self.msg = {show: false}; //hide
        }
      },
      /**
       * Показ диалога для подтверждения
       *
       * @param {{
       *   msg: String|*,
       *   title: String|*,
       *   type: String|*,
       *   help: String|*,
       * }} message
       * @returns {void|Promise<any>|boolean}
       */
      confirm: message => {
        return this.$refs.confirm.confirm(message);
      },
      audit: (id, name) => {
        return this.$refs.audit.audit(id, name);
      },
      on: q => {
        switch (q) {
          case 'subject':
            this.checkAuth();
            break;
        case 'tenant':
            jet.collections?.refresh();
        }
      },
      http: $http,
      utils: $utils,
      xls: $xls,
      $store: this.$store,  // for backward's
      worker: new Worker()
    }; // global
    
    jet.worker.postMessage({ 
        type: "init", 
        env: JSON.stringify({
                                rpc:`${window.location.protocol}//${window.location.host}/rpc`, 
                                channel: "jet-main"
        })
    });
    
    //load local settings
    this.$store.dispatch("app/getSetts").then( ()=>{
        console.log('on-settings', this.$store.getters['app/settings']());
        if ( !this.$store.getters["profile/isAuthenticated"] ){
            this.$store.dispatch("profile/check");
        }
    });
    
    //load stored fav`s
    this.$store.dispatch("app/getFavs");
  },    //created
  computed: {
    msg_show: {
      get() {
        return (!!this.app_message) && !$utils.isEmpty(this.app_message.text);
      },
      set(val) {
        //console.log(val);
        this.app_message.text = '';
      },
    },
    subject() {
      return this.$store.state.profile.subject;
    },
  },
  methods: {
    checkAuth() {
        //TODO: to worker
      if (this.h_ping_timer) {
        clearInterval(this.h_ping_timer);
      }
      if (jet.$store.getters['profile/isAuthenticated']) {
        const self = this;
        this.h_ping_timer = setInterval(() => {
          const opts = {
            type: 'auth',
            basicAuth: 'Basic ' + btoa('ping:1'),
            timeout: 20000,
          };

          $http.post(opts).catch((err) => {
              console.log('ERR (on ping):', err);

              self.sysbar = (new Date()).getTime();
              self.sysmsg = 'Время сессии истекло или сервер недоступен. Попробуйте обновить страницу';

              clearInterval(self.h_ping_timer);
            });
        }, 10 * 60 * 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vue-datetime/dist/vue-datetime.css";

html, body {
  height: 100%;
  overflow: hidden;
}

.mapboxgl-ctrl-group {
  display: none;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl {
  display: none;
}

/* Анимации */

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.jet-app-snackbar {
  & .v-btn {
    position: absolute;
    top: -14px;
    right: -12px;
    color: #f2f2f2;
    background: #f2f2f2;
    border: 1px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  }
}
</style>
