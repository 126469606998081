<template>
    <v-card :loading="pending"
            class="mt-vehicles">
        <v-card-title>
            <div>
                <v-icon class="mt-vehicles__icon">mdi-bus-multiple</v-icon>
                ТРАНСПОРТ
            </div>
            <v-spacer />
            <v-btn icon 
                   v-on:click="$fetch">
                <v-icon color="grey">mdi-reload</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="lastTime">
            <v-icon small>mdi-clock-outline</v-icon>&nbsp;последнее событие от {{ lastTime }}
        </v-card-subtitle>
        <v-card-text v-if="has('all')">
            <v-list v-if="list" dense>
                <v-list-item v-for="(a, n) in all"
                            :key="'state-'+n">
                    <v-badge dot 
                             inline
                             left
                             color="colors.at(n)">
                        {{ labels.at(n) }}
                    </v-badge>
                    <div class="numof">
                        {{ a }}
                    </div>    
                </v-list-item>
                <v-list-item v-if="totals">
                    <v-badge dot 
                             inline
                             left
                             color="#3dadff">
                        всего 
                    </v-badge>    
                    <div class="numof">
                        {{ totals }}
                    </div>    
                </v-list-item>
            </v-list>
            <v-row v-else dense>
                <v-col cols="5" class="text-center">
                    <canvas id="vehicles-chart"></canvas>
                </v-col>
                <v-col cols="7" v-if="has('all')"
                       class="align-self-center">
                    <v-chip v-if="totals"
                            small
                            label
                            color="primary">
                        {{ totals }}&nbsp;всего 
                    </v-chip>
                    <br />
                    <v-chip v-for="(a, n) in all"
                            small
                            label
                            :key="'state-'+n"
                            :color="colors.at( n )">
                        {{ a }}&nbsp;{{ labels.at(n) }}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('collection')">
                <v-icon small>mdi-bus-multiple</v-icon>
                к списку
                </v-btn>
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('map')">
                <v-icon small>mdi-map-legend</v-icon>
                карта
            </v-btn>
            <v-btn small
                   tile
                   color="primary"
                   v-on:click="go('report')">
                <v-icon small>mdi-file-table-outline</v-icon>
                отчет по статусам
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import { getMtInfo } from "@/services/MtInfo";
import Chart from 'chart.js/auto';    

let w = null;

const colors = ['#45acf7', '#fdb45c','#B0BEC5', "#90A4AE", "#78909C","#546E7A"];
const borders= ['#ECEFF1','#ECEFF1','#ECEFF1','#ECEFF1','#ECEFF1', '#ECEFF1'];
const labels = ['на связи', '>2-х часов', '>24-х часов', '>72-х часов', 'нет данных', 'нет датчика'];

export default {
    name: 'MtVehicles',
    props: {
        list: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data(){ 
        return {
                    pending:  false,
                    totals:   null,
                    all:      null,
                    lastTime: null,
                    chart:    null,
                    colors,
                    labels
                    
        };
    },
    created(){
        this.$fetch();
    },
    mounted(){
        const _buildChart = n => {
            if ( n > 100 ){
                return;
            }
            let data = this.all;
            const node = $(this.$el).find("#vehicles-chart");
            if (
                    ( node.length < 1 )
                 ||!( data?.length )
               ){
                setTimeout(()=>{ _buildChart( n++ ); }, 300);
                return;
            }
            //const w = node.parent().innerWidth() - 48;
            //node.css({width: "128px", height: "128px"});
            const cfg = {
                        type: 'pie',
                        data: {
                                labels,
                                datasets: [{
                                        clip: {left: 8, top: 8, right: 8, bottom: 8},
                                        backgroundColor: colors,
                                        borderColor:     borders,
                                        data,
                                        hoverOffset: 4
                               }]
                        },
                        options: {
                            responsive: false,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                title: {
                                    display: false
                                }
                            }
                        }
            };
            if ( this.chart ){
                this.chart.config.data = cfg.data;
                this.chart.update();
            } else {
                this.chart = new Chart(node.get(0), cfg);
            }
        };  //_buildChart
        
        _buildChart(0);
    },
    unmounted(){
        if (this.chart){
            this.chart.destroy();
            this.chart = null;
        }
    },
    methods: {
        $fetch(){
            this.pending = true;
            getMtInfo(5).then( res => {
                var res = res.at(0);
                if ( res ){
                    let totals = 0,
                        all    = res.n.split(":");

                    all = all.map( r => {
                        let n = Number(r);
                        totals += n;
                        return n;
                    });

                    this.totals   = totals;
                    this.all      = all;
                    this.lastTime = res.dt2 ? $moment(res.dt2).format('DD.MM.YYYY HH:mm') : null;
                    this.$emit("totals", all);
                }
            }).finally(()=>{
                this.pending = false;
            });
        },
        go(q){
            switch(q){
                case "collection":
                    jet.collections.open({
                                id: "ad3ddb59-060d-47d1-b7d9-e000d3d9f28f",
                                uri: `sin2:/v:6857aebe-2248-4e72-a9ec-918d5e722cf6/?filter=and(
                                            lte(field(".startDt"),var("util.date.truncToDay(dateEnd)")),
                                            or(
                                                isnull(field(".endDt")),
                                                gte(field(".endDt"),var("util.date.truncToDay(dateBegin)"))
                                            )
                                       )`,
                                name:"Транспортные средства"
                    });
                    break;
                case "map":
                    let url = /^(local)+/.test(window.location.host)
                        ? 'http://localhost:3000'
                        : `${window.location.protocol}//${window.location.host}/tracked`;
                    if ( !w || w.closed ){
                        w = window.open(url, '_blank');
                    } else {
                        w.location.href = url;
                        //TODO: by w.postMessage
                    }
                    w.focus();
                    break;
                case "report":
                    jet.reports.open({
                        id: "bd1b9169-66e4-46d8-ab4f-eee327af5afc",
                        uri: "report:/bd1b9169-66e4-46d8-ab4f-eee327af5afc",
                        title: "10.70 Сводный отчет по ТС"
                    });
                    break;
            }
        },
        has(q){
            switch(q){
                case "all":
                    return this.all?.length > 0;
            }
            return false;
        }
    }
};
</script>
<style lang="scss" scoped>
    @import "@/styles/nav-head.scss";
    
    .mt-vehicles{
        &.v-card{
            & .v-card__title{
                background-color: $light-blue;
                color: $blue;
                & .v-icon{
                    margin-right: 0.5rem;
                    color: $blue;
                }
            }
            & .v-card__subtitle{
                margin-top: 0 !important;
                & .v-icon {
                    color: $orange !important;
                }
            }
            
            & .v-card__text{
                & canvas{
                    width: 272px;
                    height: 272px;
                    margin: 1rem auto;
                }
                & .v-chip{
                    margin-top: 0.5rem;
                    min-width: 8rem;
                    color: #fff;
                    &:not(:last-child){
                        margin-right: 0.5rem;
                    }
                }
                & .v-list{
                    &-item{
                        & .numof{
                            margin-left: auto;
                            text-align: right;
                            font-weight: bold;
                        }
                    }
                }
            }
            & .v-card__actions{
                & .v-btn{
                    & .v-icon{
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
</style>