var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"jet-app-nav",attrs:{"app":"","clipped":"","absolute":"","bottom":"","temporary":"","light":"","width":"auto"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"pa-2"},[_vm._v("mdi-home")]),(_vm.has('favs'))?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"plain":""}},on),[_vm._v("избранное")])]}}],null,false,1167186052)},[_c('jet-favs',{on:{"open":function($event){return _vm.openCollection($event)}}})],1):_vm._e(),_c('v-divider',{attrs:{"light":"","dark":""}})]},proxy:true},{key:"append",fn:function(){return [_c('v-divider',{attrs:{"light":"","dark":""}}),_c('div',{staticClass:"pa-2"},[_c('v-icon',[_vm._v("fas fa-outdent")])],1)]},proxy:true}]),model:{value:(_vm.fNavShow),callback:function ($$v) {_vm.fNavShow=$$v},expression:"fNavShow"}},[_c('v-treeview',{attrs:{"items":_vm.data,"activatable":"","hoverable":"","item-key":"id","open-on-click":"","active":_vm.selected,"return-object":"","dense":"","expand-icon":"jet-tree-down"},on:{"update:active":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.hasChilds)?_c('v-img',[_c('jet-svg',{attrs:{"width":"16","height":"16","xref":(!!open) ? '#ico-folder-o' : '#ico-folder'}})],1):_c('v-img',[_c('jet-svg',{attrs:{"width":"16","height":"16","xref":"#ico-file"}})],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }